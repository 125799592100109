import { useReactiveVar } from '@apollo/client';
import {
  DesignGuideOrderProvider,
  GuideModal,
  ModifierOrderProvider,
  OverpanelFilesContext,
  OverpanelSubmitContext,
  overpanelCasesData,
  withFormWrap,
} from '@fullcontour/common';
import { FieldArray, withFormik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { memo, useContext, useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { Case } from './Case';
import { CaseActions } from './CaseActions';
import CaseItem from './CaseItem/CaseItem';
import { ModifierModal } from './CaseItem/CaseItemFields/ModifierSelection';
import { withCaseUploader } from './CaseUploader';
import {
  assignTurnaroundTimes,
  initialValues,
  schema,
  withCreate,
} from './helpers';
import './OverpanelForm.css';
import OverpanelFormProgress from './OverpanelFormProgress';

function OverpanelForm({
  file,
  fileIndex,
  setFieldValue,
  labLocationGuideCategoryGroups = null,
  customerLocation: {
    priceList: { designTypes, priceListItems, turnaroundTimes },
  },
  values: { orderItems },
  errors,
  handleSubmit,
  customerLocation,
  parsedCase,
  signAndSubmit,
}) {
  const [forceDesignApproval, setForceDesignApproval] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showGuideModal, setShowGuideModal] = useState(false);
  const [showModifierModal, setShowModifierModal] = useState(false);
  const [modifierGroups, setModifierGroups] = useState(null);
  const [designGuideCategories, setDesignGuideCategories] = useState(null);
  const [orderItemIndex, setOrderItemIndex] = useState(null);
  const [currentOrderItem, setCurrentOrderItem] = useState(null);
  const [defaultTurnaroundTime, setDefaultTurnaroundTime] = useState({});
  const [filteredTurnaroundTimes, setFilteredTurnaroundTimes] = useState([]);

  const { updateGuides } = useContext(OverpanelFilesContext);
  const { setErrors } = useContext(OverpanelSubmitContext);

  const overpanelCasesDataVar = useReactiveVar(overpanelCasesData);
console.log(customerLocation)
  useEffect(() => {
    if (parsedCase && errors) {
      setErrors((prev) => ({
        ...prev,
        [fileIndex]: errors,
      }));
    }

    return () => {
      setErrors((prev) => ({
        ...prev,
        [fileIndex]: {},
      }));
    };
  }, [errors, fileIndex, parsedCase, setErrors]);

  useEffect(() => {
    if (
      labLocationGuideCategoryGroups &&
      Object.keys(overpanelCasesDataVar).length > 0
    ) {
      orderItems.forEach((orderItem, i) => {
        if (overpanelCasesDataVar[orderItem.designTypeId]) {
          setFieldValue(
            `orderItems[${i}].guideCodes`,
            overpanelCasesDataVar[orderItem.designTypeId],
          );
        }
      });

      overpanelCasesData({});
    }
  }, [overpanelCasesDataVar, labLocationGuideCategoryGroups]);

  useEffect(() => {
    if (fileIndex === 0) {
      updateGuides({ orderItems });
    }
  }, [orderItems]);

  const checkDesignTypesForApproval = () => {
    const designTypeIds = [
      ...new Set(orderItems.map((item) => item.designTypeId)),
    ];

    const activeDesignTypes = designTypes.filter((designType) =>
      designTypeIds.some((id) => id === designType.id),
    );

    return activeDesignTypes.some((designType) => designType.designApproval);
  };

  function filterTurnaroundTimes() {
    const turnaroundData = assignTurnaroundTimes(
      orderItems,
      priceListItems,
      turnaroundTimes,
      designTypes,
    );

    setDefaultTurnaroundTime(turnaroundData.defaultTurnaroundTime);
    setFilteredTurnaroundTimes(turnaroundData.filteredTurnaroundTimes);

    setFieldValue('turnaroundTimeId', turnaroundData.defaultTurnaroundTime.id);
  }

  function guideModalOpen(categories, itemIndex, orderItem) {
    setDesignGuideCategories(categories);
    setOrderItemIndex(itemIndex);
    setCurrentOrderItem(orderItem);
    setShowGuideModal(true);
  }

  function guideModalClose() {
    setShowGuideModal(false);
    setDesignGuideCategories(null);
    setOrderItemIndex(null);
    setCurrentOrderItem(null);
  }

  function modifierModalOpen(groups, itemIndex, orderItem) {
    setModifierGroups(groups);
    setOrderItemIndex(itemIndex);
    setCurrentOrderItem(orderItem);
    setShowModifierModal(true);
  }

  function modifierModalClose() {
    setShowModifierModal(false);
    setModifierGroups(null);
    setOrderItemIndex(null);
    setCurrentOrderItem(null);
  }

  const toggleForceDesignApproval = () => {
    const force = checkDesignTypesForApproval();

    setForceDesignApproval(force);

    filterTurnaroundTimes();
    setFieldValue('requiresDesignApproval', force);
  };
  return (
    <DesignGuideOrderProvider
      value={{
        guideModalClose,
        guideModalOpen,
        showGuideModal,
        designGuideCategories,
        orderItemIndex,
        currentOrderItem,
        customerLocation,
        designTypes,
        allowNested: customerLocation?.allowNested,
      }}
    >
      <ModifierOrderProvider
        value={{
          modifierModalClose,
          modifierModalOpen,
          showModifierModal,
          modifierGroups,
          orderItemIndex,
          currentOrderItem,
        }}
      >
        <form
          className="caseRowForm columns"
          onSubmit={handleSubmit}
          noValidate
          style={{
            backgroundColor:
              fileIndex % 2 === 0 ? '#fff' : 'rgba(251, 251, 251, 0.90)',
          }}
        >
          <OverpanelFormProgress submit={submit} />
          <GuideModal
            labLocationGuideCategoryGroups={labLocationGuideCategoryGroups}
          />

          <ModifierModal />
          <FieldArray
            name="orderItems"
            render={(arrayHelpers) => (
              <>
                <Case
                  parsedCase={parsedCase}
                  forceDesignApproval={forceDesignApproval}
                  toggleForceDesignApproval={toggleForceDesignApproval}
                  fileIndex={fileIndex}
                  turnaroundTimes={filteredTurnaroundTimes}
                  defaultTurnaroundTime={defaultTurnaroundTime}
                />

                <CaseItem
                  arrayHelpers={arrayHelpers}
                  labLocationGuideCategoryGroups={
                    labLocationGuideCategoryGroups
                  }
                  toggleForceDesignApproval={toggleForceDesignApproval}
                  customerLocation={customerLocation}
                  parsedCase={parsedCase}
                />

                <CaseActions
                  arrayHelpers={arrayHelpers}
                  file={file}
                  submit={submit}
                  triggerSubmit={() => setSubmit(true)}
                  cancelSubmit={() => setSubmit(false)}
                  parsedCase={parsedCase}
                  fileIndex={fileIndex}
                  signAndSubmit={signAndSubmit}
                />
              </>
            )}
          />
        </form>
      </ModifierOrderProvider>
    </DesignGuideOrderProvider>
  );
}

OverpanelForm.propTypes = {
  file: PropTypes.object.isRequired,
  labLocationGuideCategoryGroups: PropTypes.array,
  customerLocation: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired, // Formik
  fileIndex: PropTypes.number.isRequired,
  parsedCase: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired, // Formik
  errors: PropTypes.object.isRequired, // Formik
  setFieldValue: PropTypes.func.isRequired, // Formik
  signAndSubmit: PropTypes.func.isRequired, // CaseUploader
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withCreate,
  withFormik({
    mapPropsToValues: (props) => props.initialValues(props),
    validationSchema: (props) => props.schema(),
    validateOnBlur: false,
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
      const modifiedValues = { ...values };
      modifiedValues.selectedCodes = undefined;
      const variables = {
        variables: {
          input: { input: modifiedValues, clientMutationId: uuidv4() },
        },
      };
      props.setSubmitionIndicator(true);
      props.create(variables);

      setSubmitting(false);
    },
  }),
  withCaseUploader(),
)(memo(OverpanelForm));
