import { UPDATE_CUSTOMER_ORG } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  navigate,
}) {
  const result = await client.mutate({
    mutation: UPDATE_CUSTOMER_ORG,
    variables,
  });
  const { updateCustomerOrganization } = result.data;
  if (updateCustomerOrganization.errorMessages.length > 0) {
    setErrorMessages(updateCustomerOrganization.errorMessages);
  } else {
    clearErrorMessages();
    navigate('/organizations');
  }
}

export default update;
