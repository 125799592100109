import { useApolloClient } from '@apollo/client';
import { CREATE_VIEWER_LINK } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { v4 as uuidv4 } from 'uuid';

function ReviewModalBodyPatientLink(props) {
  const { docApproval, patientApproval, patientApprovalOff, redesign, order } =
    props;

  const [copied, setCopied] = useState(false);
  const [copiedText, setCopiedText] = useState(null);
  const [logId, setLogId] = useState(null);
  const [copiedToken, setCopiedToken] = useState(null);

  const client = useApolloClient();

  const createViewerLink = async (e) => {
    e.preventDefault();

    const { data } = await client.mutate({
      mutation: CREATE_VIEWER_LINK,
      variables: {
        input: {
          input: {
            orderId: order.id,
          },
          clientMutationId: uuidv4(),
        },
      },
    });

    const {
      createViewerLink: { viewerLink = {} } = {},
    } = data;

    setLogId(viewerLink.id);
    setCopiedText(`${viewerLink.link}`);
    setCopiedToken(`${viewerLink.accessToken}`);
  };

  return !(docApproval || redesign) && patientApproval ? (
    <>
      <h5 className="title is-5">
        Click &quot;Generate Link&quot; for a URL that you can forward as
        needed.
      </h5>
      <div style={{ minHeight: 65, marginTop: 20 }}>
        {logId && (
          <p id="doc-link">
            <span style={{ fontWeight: 700, fontSize: '.92857143em' }}>
              Viewer Link:
            </span>
            <br /> {copiedText}
          </p>
        )}
        {logId && order.location.fullViewer === true && (
          <p id="access-code">
            <span style={{ fontWeight: 700, fontSize: '.92857143em' }}>
              Access Token:
            </span>
            <br /> {copiedToken}
          </p>
        )}
        {copied && <h6 className="title is-6 has-text-danger">copied!</h6>}
      </div>
      <div className="is-flex mt-4">
        <button
          onClick={patientApprovalOff}
          type="button"
          className="button mr-auto"
        >
          Go Back
        </button>
        <div className="buttons">
          {logId && (
            <CopyToClipboard text={copiedText} onCopy={() => setCopied(true)}>
              <button type="button" className="button is-primary">
                Copy Link
              </button>
            </CopyToClipboard>
          )}
          {logId && order.location.fullViewer === true && (
            <CopyToClipboard text={copiedToken} onCopy={() => setCopied(true)}>
              <button type="button" className="button is-primary">
                Copy Access Token
              </button>
            </CopyToClipboard>
          )}
          <button
            type="button"
            className="button is-primary"
            onClick={createViewerLink}
          >
            Generate Link...
          </button>
        </div>
      </div>
    </>
  ) : null;
}

ReviewModalBodyPatientLink.propTypes = {
  docApproval: PropTypes.bool.isRequired,
  redesign: PropTypes.bool.isRequired,
  patientApprovalOff: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  patientApproval: PropTypes.bool.isRequired,
};

export default ReviewModalBodyPatientLink;
