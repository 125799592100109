import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../../../../transforms';

function HeaderInfoOrderMetadata({
  location,
  name = '',
  designSilo = null,
  labLocationTier = null,
}) {
  const appName = import.meta.env.VITE_APP_NAME;

  const tierTag =
    ['admin_portal', 'designer_portal'].includes(appName) && labLocationTier ? (
      <span>
        -
        <span className={`tag location-tier-color-${labLocationTier}`}>
          {capitalizeFirstLetter(labLocationTier)}
        </span>
      </span>
    ) : null;

  return (
    <p>
      <small>
        {appName === 'admin_portal' ? (
          <Link to={`/locations/customer/${location.slug}`}>
            {location.code}
            {tierTag}
          </Link>
        ) : (
          <span>
            {location.code}
            {tierTag}
          </span>
        )}

        {name &&
        (appName === 'admin_portal' || appName === 'customer_portal') ? (
          <span>|{name}</span>
        ) : null}
        {appName === 'admin_portal' && (
          <span>
            | Design Silo:
            {designSilo ? designSilo.name : 'Unassigned'}
          </span>
        )}
      </small>
    </p>
  );
}

HeaderInfoOrderMetadata.propTypes = {
  name: PropTypes.string,
  location: PropTypes.object.isRequired,
  labLocationTier: PropTypes.string,
  designSilo: PropTypes.object,
};

export default HeaderInfoOrderMetadata;
