function initialValues(props) {
  const {
    data: { customerOrganization = {} } = {},
  } = props;
  return {
    id: customerOrganization.id || null,
    name: customerOrganization.name || '',
    shortName: customerOrganization.shortName || '',
    administratorLocationId: customerOrganization.administratorLocationId || '',
    priceListId: customerOrganization.priceListId || '',
    paymentTermId: customerOrganization.paymentTermId || '',
    numberingSystem: customerOrganization.numberingSystem || 'uns',
    language: customerOrganization.language || 'en',
    website: customerOrganization.website || '',
    address: customerOrganization.address || {
      address: '',
      address2: '',
      city: '',
      region: '',
      postalCode: '',
      country: 'US',
    },
    phone: customerOrganization.phone || '',
    logoUrl:
      customerOrganization.logoUrl ||
      `https://s3-us-west-2.amazonaws.com/${
        import.meta.env.VITE_ASSET_BUCKET
      }/assets/images/l/default.svg`,
  };
}

export default initialValues;
