import { useAmplify } from '@fullcontour/auth';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function SignInAppSelect({ app = null }) {
  const { isSignedIn, currentUser } = useAmplify();

  const { t } = useTranslation('signin');

  return (app === 'customer_portal' || app === 'manuf_portal') &&
    !isSignedIn &&
    !currentUser ? (
    <div className={app === 'customer_portal' ? '' : 'mt-5'}>
      {app === 'customer_portal' && (
        <>
          {t('Manufacturers Sign In')}
          <a href={import.meta.env.VITE_MANUF_URL}>{t('here')}</a>
        </>
      )}

      {app === 'manuf_portal' && (
        <>
          {t('Customers Sign In')}
          <a href={import.meta.env.VITE_CUSTOMER_URL}>{t('here')}</a>
        </>
      )}
    </div>
  ) : null;
}

SignInAppSelect.propTypes = {
  app: PropTypes.string,
};

export default SignInAppSelect;
