import { UPDATE_CUSTOMER_LOC } from '@fullcontour/shared-api';

async function update({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  navigate,
  currentUser
}) {
  const result = await client.mutate({
    mutation: UPDATE_CUSTOMER_LOC,
    variables,
  });
  const { updateCustomerLocation } = result.data;
  if (updateCustomerLocation.errorMessages.length > 0) {
    setErrorMessages(updateCustomerLocation.errorMessages);
  } else {
    const url = currentUser?.scopeToLocation
      ? '/locations'
      : `/locations/${updateCustomerLocation.customerLocation.slug}`;
    clearErrorMessages();
    navigate(url);
  }
}

export default update;
