import { ChartSelectionNested } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useGuideCodeCardContext } from '../context/GuideCodeCardContext';

function ButtonNestedSelection({
  customNested = false,
  dataSources: { customerLocation, ...other } = {},
  allowNested = false,
  designTypes = {},
  values = {},
  ...rest
}) {
  const props = {
    customNested,
    dataSources: { customerLocation, ...other },
    allowNested,
    designTypes,
    values,
    ...rest,
  };
  const { data } = useGuideCodeCardContext();
  const { category, designType } = data;

  const { archChart, toothChart } = designTypes[values.businessUnitName].find(
    (item) => item.id === designType.designTypeId,
  );
  const shouldRender =
    allowNested &&
    ((archChart && category.allowArchSelection) ||
      (toothChart && category.allowToothSelection));

  return shouldRender ? (
    <ChartSelectionNested
      {...props}
      numberingSystem={customerLocation.numberingSystem}
      allowArchSelection={category.allowArchSelection}
      allowToothSelection={category.allowToothSelection}
      currentDesignType={designType}
      guideCode={data}
      customNested={customNested}
    />
  ) : null;
}

ButtonNestedSelection.propTypes = {
  customNested: PropTypes.bool,
  dataSources: PropTypes.object,
  allowNested: PropTypes.bool,
  designTypes: PropTypes.object,
  values: PropTypes.object,
};
export default ButtonNestedSelection;
