import { BoxIcon } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { memo } from 'react';
import '../GuideSelection/GuideSelectionPopup.css';

function ModifierSelectionPopup({
  modifierGroups = [],
  modifierModalOpen,
  currentOrderItem,
}) {
  return (
    <button
      type="button"
      id="modifier-btn"
      disabled={modifierGroups?.length === 0}
      className="button is-outlined is-primary mr-3 py-4"
      onClick={() => modifierModalOpen(modifierGroups)}
    >
      <BoxIcon
        className="icon is-flex is-align-items-center is-justify-content-center"
        name="bx-edit"
      />
      <span className="ml-1">Add Add-ons &nbsp;</span>
      <span className="ml-1">
        {currentOrderItem.orderItemModifiersAttributes.filter(
          (modifier) => modifier,
        ).length > 0
          ? `- selected (${
              currentOrderItem.orderItemModifiersAttributes.filter(
                (modifier) => modifier,
              ).length
            })`
          : ''}
      </span>
    </button>
  );
}

ModifierSelectionPopup.propTypes = {
  modifierGroups: PropTypes.array,
  currentOrderItem: PropTypes.object.isRequired,
  modifierModalOpen: PropTypes.func.isRequired,
};

export default memo(ModifierSelectionPopup);
