function TermsAndConditions() {
  return (
    <>
      <p>Last Modified: April 13th 2018</p>
      <h3>SmileSummary Terms & Conditions</h3>
      <p>
        If you would like to see past versions of the SmileSummary LLC Terms &
        Conditions, please contact us at
        <a href="mailto:info@smilesummary.com">info@smilesummary.com</a> and we
        would be happy to provide you with a copy of any prior version.
      </p>
      <h4>Article A – Generally Applicable Terms</h4>
      <h6>1. Applicability of these Terms and Conditions</h6>
      <p>
        SmileSummary, LLC, an Arizona limited liability company,
        (&quot;SmileSummary&quot;) provides professional design services for
        dental prosthetics in various CAD systems, including but not limited to
        crowns, abutments, surgical guides, clear aligners, orthodontic
        appliances, dentures, partial frames, copings, frameworks, and other
        related dental prosthetic devices (collectively, the &quot;Professional
        Services&quot;) and a website (www.fullcontour.com) and web-based
        platform for use in transmitting and communicating about such dental
        prosthetic designs (collectively, the website and web-based platform are
        referred to herein as the &quot;SmileSummary Platform&quot;). The
        Professional Services and use rights for the SmileSummary Platform are
        purchased from SmileSummary by a customer (the &quot;Customer&quot;) for
        use by that Customer&apos;s authorized users (each a &quot;User&quot;
        and collectively, the &quot;Users&quot;). Users may or may not be
        employees of the Customer. For example, Users may include employees of
        the Customer, as well as non-employee dental labs, manufacturers and
        clinicians who have a relationship with the Customer.
        <br />
        <br />
        The SmileSummary LLC Terms & Conditions (the &quot;Agreement&quot;) is a
        legally binding contract between SmileSummary and you, the prospective
        user or Customer. If you are a Customer, Articles A, B and C of this
        Agreement apply to you. If you are not a Customer, but a User, then only
        Articles A and B of this Agreement apply to you.
        <br />
        <br />
        For more information or if you have any questions about these terms of
        use, including what legal terms apply to you, please contact us at
        <a href="mailto:info@fullcontour.com">info@SmileSummary.com</a>.
      </p>
      <h6>2. Acceptance of Terms of Use</h6>
      <p>
        This Agreement is between you and SmileSummary.
        <br />
        <br />
        By accessing or using the Professional Services or SmileSummary
        Platform, or by clicking to accept or agree to the Agreement when this
        option is made available, you acknowledge that you have read,
        understand, and agree, without limitation or qualification, to be bound
        and abide by the Agreement, which include our Privacy Policy,
        incorporated herein by reference. If you do not wish to agree to the
        Agreement or the Privacy Policy, you must not access or use the
        Professional Services or SmileSummary Platform.
        <br />
        <br />
        The Professional Services and SmileSummary Platform are offered and
        available to users who are eighteen (18) years of age or older. By using
        the Professional Services or SmileSummary Platform you represent and
        warrant that you are of legal age to form a binding contract with
        SmileSummary.
        <br />
        <br />
        Also, please note that we may revise and update this Agreement from time
        to time in our sole discretion. All changes are effective immediately
        when posted and apply to all access to and use of the Professional
        Services or SmileSummary Platform thereafter. Your continued use of the
        Professional Services or SmileSummary Platform following the posting of
        changes means that you accept and agree to the changes. It is your
        responsibility to check this Agreement periodically for changes, as
        these changes are binding on you.
        <br />
        <br />
        The material provided through our Professional Services and SmileSummary
        Platform is protected by law, including, but not limited to, United
        States copyright law and international treaties. The Professional
        Services and SmileSummary Platform are controlled and operated by
        SmileSummary from its offices within the United States. SmileSummary
        makes no representation that materials in the Professional Services and
        SmileSummary Platform are appropriate or available for use in other
        locations, and access to them from territories where their contents are
        illegal is prohibited. Those who choose to access the Professional
        Services and SmileSummary Platform from other locations do so on their
        own initiative and are responsible for compliance with all applicable
        local laws.
        <br />
        <br />
        The SmileSummary Platform offers you an efficient means to bridge
        production gaps between laboratories, dental manufacturers and
        clinicians. Your use of the SmileSummary Platform and/or purchase of the
        Professional Services may include providing PHI, as such term is defined
        in the BAA. By using the SmileSummary Platform, you understand and
        acknowledge (i) SmileSummary is not providing medical care, dental care,
        or treatment-related advice to you, we are merely a professional design
        service; and (ii) that the Professional Services and use of the
        SmileSummary Platform are also governed by SmileSummary&apos;s Business
        Associate Agreement (&quot;BAA&quot;), as it may be modified over time.
      </p>
      <h6>3. Governing Law and Dispute Resolution</h6>
      <p>
        This Agreement and performance hereunder shall be governed by, and
        construed in accordance with, the laws of the state of Arizona (without
        giving effect to its conflict of laws principles). The parties agree to
        submit any claim, dispute, or disagreement to mediation before a
        mutually-agreeable mediator prior any other form of dispute resolution.
        All mediation or any other form of dispute resolution shall take place
        in Phoenix, Arizona and the parties irrevocably waive any objection to
        such venue.
      </p>
      <h6>4. Limitation of Liability</h6>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU EXPRESSLY UNDERSTAND AND
        AGREE THAT FULLCONTOUR SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
        SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED
        TO, DAMAGES FOR LOSS OF PROFITS, REVENUES, GOODWILL, USE, DATA, OR OTHER
        INTANGIBLE LOSSES (EVEN IF FULLCONTOUR HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE
        INABILITY TO USE THE PROFESSIONAL SERVICES OR FULLCONTOUR PLATFORM; (ii)
        THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICE RESULTING FROM
        ANY GOODS, DATA, INFORMATION, OR SERVICE PURCHASED OR OBTAINED OR
        TRANSACTIONS ENTERED INTO THROUGH OR FROM THE FULLCONTOUR PLATFORM;
        (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
        DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE FULLCONTOUR
        PLATFORM; OR (v) ANY OTHER MATTER RELATING TO THE PROFESSIONAL SERVICES
        OR FULLCONTOUR PLATFORM. TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU
        ALSO AGREE THAT FULLCONTOUR WILL NOT BE LIABLE FOR ANY INTERRUPTION OF
        BUSINESS, ACCESS DELAYS, OR ACCESS INTERRUPTIONS TO THE FULLCONTOUR
        PLATFORM OR THE WEB SITE(S) YOU ACCESS THROUGH THE FULLCONTOUR PLATFORM,
        DATA NON-DELIVERY, MIS-DELIVERY, CORRUPTION, DESTRUCTION OR OTHER
        MODIFICATION, AND ANY EVENTS BEYOND FULLCONTOUR’S REASONABLE CONTROL.
        <br />
        <br />
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU EXPRESSLY UNDERSTAND AND
        AGREE THAT FULLCONTOUR&apos;S MAXIMUM AGGREGATE LIABILITY (WHETHER IN
        CONTRACT OR IN TORT, INCLUDING NEGLIGENCE, OR UNDER ANY OTHER FORM OF
        LIABILITY) FOR DAMAGES OR LOSS, HOWSOEVER ARISING OR CAUSED, SHALL IN NO
        EVENT EXCEED THE AMOUNT OF FEES PAID TO FULLCONTOUR BY YOU IN THE TWELVE
        MONTH PERIOD IMMEDIATELY PRECEDING EVENTS FIRST GIVING RISE TO THE
        CLAIM.
      </p>
      <h6>5. Severability.</h6>
      <p>
        In the event that any provision of this Agreement is unenforceable or
        invalid under any applicable law, such unenforceability or invalidity
        shall not affect the other provisions of this Agreement and this
        Agreement shall be construed as if such unenforceable or invalid
        provision had never been contained herein.
      </p>
      <h6>6. Notices.</h6>
      <p>
        Notices to SmileSummary shall be sent to 17505 N. 79th Ave #412
        Glendale, AZ 85308
        <br />
        SmileSummary shall provide all notices to Users under this Agreement by
        email, although we may instead choose to provide notice via the
        SmileSummary Platform or your then-current postal address on file with
        SmileSummary. For purposes of messages and notices about the
        SmileSummary Platform applicable to all Users, SmileSummary may place a
        banner notice across the SmileSummary Platform to alert you. You also
        agree that SmileSummary may communicate with you through other means
        including email, mobile number, telephone, or delivery services
        including the postal service, about your account or services. You
        acknowledge and agree that we shall have no liability associated with or
        arising from your failure to maintain accurate contact or other
        information, including, but not limited to, your failure to receive
        critical information about the SmileSummary Platform.
      </p>
      <h6>7. Entire Agreement.</h6>
      <p>
        You agree that this Agreement, including the documents incorporated
        herein by reference, constitutes the entire, complete and exclusive
        agreement between you and us regarding the Professional Services and
        SmileSummary Platform and supersedes all prior agreements and
        understandings, whether written or oral, or whether established by
        custom, practice, policy or precedent, with respect to the subject
        matter of this Agreement. Except as set forth otherwise in this
        Agreement, this Agreement cannot be modified or amended except in a
        writing executed by both parties that specifically references this
        Agreement and states that it is to amend the Agreement.
      </p>

      <h6>8. No informal waivers, agreements or representations.</h6>
      <p>
        Our failure to act with respect to a breach of this Agreement by you or
        others does not waive our right to act with respect to that breach or
        subsequent similar or other breaches. No representations, statements,
        consents, waivers or other acts or omissions by SmileSummary shall be
        deemed legally binding on SmileSummary, unless documented in a physical
        writing hand signed by a duly appointed officer of SmileSummary.
      </p>

      <h4>Article B – Terms for Use of the SmileSummary Platform.</h4>
      <h6>9. Customer Users and Non-Customer Users.</h6>
      <p>
        The terms set forth in this Article B apply to all Users of the
        SmileSummary Platform, whether such User is a Customer of SmileSummary
        or not. Each User agrees to the terms in this Article B. In addition,
        each Customer is responsible for ensuring that all of such
        Customer&apos;s authorized Users comply with the terms of this Article B
        when using the SmileSummary Platform.
        <br />
        <br />
        If you are a User that has been invited to use the SmileSummary Platform
        by one or more Customers, your user of the SmileSummary Platform is
        controlled by each Customer as to that Customer&apos;s accounts. The
        Customer who invited you to use the SmileSummary Platform has separately
        agreed to this Agreement, which permits that Customer to create and
        configure User accounts so that you and others may join as authorized
        Users. When a User, such as you, submits any content or information to
        the SmileSummary Platform (&quot;Customer Data&quot;), you acknowledge
        and agree that the Customer Data is owned by the Customer and the
        Customer has choices and control over that Customer Data. These choices
        and controls may result in the access, use, disclosure, modification or
        deletion of Customer Data submitted by you.
        <br />
        <br />
        AS BETWEEN FULLCONTOUR AND CUSTOMER, YOU AGREE THAT CUSTOMER IS SOLELY
        RESPONSIBLE TO (A) INFORM USERS OF ANY RELEVANT CUSTOMER POLICIES AND
        PRACTICES AND ANY FULLCONTOUR PLATFORM SETTINGS THAT MAY IMPACT ON THE
        HANDLING OF CUSTOMER DATA; (B) OBTAIN ANY RIGHTS, PERMISSIONS, OR
        CONSENTS FROM USERS THAT ARE NECESSARY FOR THE LAWFUL USE OF CUSTOMER
        DATA AND THE OPERATION OF THE FULLCONTOUR PLATFORM, AND (C) RESPOND TO
        AN RESOLVE ANY DISPUTE WITH A USER RELATED TO OR BASED ON CUSTOMER DATA,
        THE FULLCONTOUR PLATFORM OR CUSTOMER&apos;S FAILURE TO FULFILL THESE
        OBLIGATIONS.
      </p>
      <h6>10. Ownership and Proprietary Information</h6>
      <p>
        Other than Customer Data, the content and information on the
        SmileSummary Platform (&quot;SmileSummary Content&quot;) is proprietary
        to SmileSummary or its licensors, suppliers, publishers, rights holders,
        or other content providers (collectively, and individually,
        &quot;SmileSummary Content Providers&quot;). You agree not to modify,
        copy, distribute, transmit, share, display, perform, reproduce, publish,
        license, create derivative works from, transfer, or sell or re-sell any
        SmileSummary Content obtained from or through SmileSummary.
        <br />
        <br />
        Subject to your compliance with this Agreement, as applicable,
        SmileSummary Content Providers grant you a limited, non-exclusive,
        non-transferable, non-sublicensable license to access and make personal
        and non-commercial use of the SmileSummary Content. This license does
        not include any resale or commercial use of any SmileSummary Content;
        any collection and use of any product listings, descriptions, or prices;
        any derivative use of any SmileSummary Content; any downloading or
        copying of account information for the benefit of another party; or any
        use of data mining, robots, or similar data gathering and extraction
        tools. All rights not expressly granted to you in this Agreement are
        reserved and retained by SmileSummary Content Providers.
      </p>
      <h6>11. Registration, Accounts, and Passwords</h6>
      <p>
        If you establish a personal account with us, you agree to provide true
        and accurate data about yourself on our account registration form, and
        to update and keep such data current. You will receive a password and
        account upon completing the registration process. You are solely
        responsible for maintaining the confidentiality of your password and
        account, and you are solely responsible for all use of your password or
        account, whether authorized by you or not. SmileSummary cannot and will
        not be liable for any loss or damage arising from your failure to comply
        with these terms. You agree to (a) immediately notify us of any
        unauthorized use of your password or account or any other breach of
        security; and (b) ensure that you exit from your account each time you
        use the SmileSummary Platform. Access and use of password-protected
        and/or secure areas of the SmileSummary Platform is restricted to users
        who have been given a valid password by SmileSummary. We may terminate
        your membership and access to our SmileSummary Platform and to the
        Services if we learn that you have provided us with false or misleading
        registration data.
      </p>
      <h6>12. Mobile Terms</h6>
      <p>
        If you are receiving special offers from SmileSummary via email, SMS
        text message, or otherwise, you can unsubscribe via the links provided
        in the email at any time or by contacting us at info@SmileSummary.com or
        text instructions regarding opting out.
        <br />
        <br />
        By opting to receive special offers from us, you consent to receiving,
        from time to time, text messages or emails which may include alerts,
        promotions, offers, polls, and giveaways. Message frequency varies based
        on user and other factors. Message and data rates may apply and, if so,
        are billed by and payable to your mobile service provider. We will not
        be liable for any delays in the receipt of any SMS messages as delivery
        is subject to effective transmission from your network operator. Data
        obtained from you in connection with this SMS service may include your
        mobile phone number, your carrier’s name and the date, time and content
        of your messages. We may use this information to contact you and to
        provide the services you request from us and for other business
        purposes.
      </p>
      <h6>13. Payment</h6>
      <p>
        The SmileSummary Platform is provided to Users free of charge for as
        long as the Customer authorizing such User access is in good-standing
        with SmileSummary, including but not limited to that such Customer is
        current on all amounts owed SmileSummary for Professional Services
        purchased by the Customer.
      </p>
      <h6>14. Use Restrictions and Intellectual Property</h6>
      <p>
        The SmileSummary Platform may contain proprietary and copyright
        information, the terms of which must be observed and followed. Any
        unauthorized use of any SmileSummary Content contained on the
        SmileSummary Platform may violate copyright laws, trademark laws, the
        laws of privacy and publicity, and communications regulations and
        statutes. You may not modify, publish, transmit, participate in the
        transfer or sale, create derivative works, or in any way exploit any of
        the content in whole or in part.
        <br />
        <br />
        You will not upload, post or otherwise make available on the
        SmileSummary Platform any material protected by copyright, trademark or
        other proprietary right without the express permission of the owner of
        the copyright, trademark or other proprietary right. You will indemnify
        SmileSummary for any claims by third parties of infringement of
        copyrights, trademarks, proprietary rights or any other harm resulting
        from such a submission of material by you or (in the case of a Customer)
        any of your Users.
        <br />
        <br />
        The SmileSummary Content may not be reproduced, duplicated, copied,
        sold, resold, or otherwise exploited for any commercial purpose without
        express written consent of SmileSummary. You may not frame or utilize
        framing techniques to enclose any trademark, logo, or other proprietary
        information (including images, text, page layout, or form) of
        SmileSummary without express written consent. You may not use any meta
        tags or any other &quot;hidden text&quot; utilizing SmileSummary’s name
        or trademarks without the express written consent of SmileSummary. You
        may not misuse SmileSummary Content. You may use SmileSummary Content
        only as permitted by law. The licenses granted herein terminate if you
        do not comply with this Agreement.
      </p>
      <h6>15. U.S. Government Restricted Rights</h6>
      <p>
        The materials on SmileSummary are provided with &quot;RESTRICTED
        RIGHTS.&quot; Use, duplication, or disclosure by the Government is
        subject to restrictions as set forth in applicable laws and regulations.
        Use of the materials by the Government constitutes acknowledgment of
        SmileSummary’s proprietary rights in them.
      </p>
      <h6>16. Changes</h6>
      <p>
        Information on the SmileSummary Platform may be changed or updated
        without notice. SmileSummary may also make improvements and/or changes
        in the products and/or the programs described in this information at any
        time without notice.
      </p>
      <h6>17. Availability</h6>
      <p>
        Information that SmileSummary publishes on the SmileSummary Platform may
        contain references or cross references to products, programs and
        services that are not announced or available in your country. Such
        references do not imply that SmileSummary intends to announce such
        products, programs or services in your country. Consult SmileSummary for
        information regarding the products, programs and services which may be
        available to you.
      </p>
      <h6>18. Disclaimer of warranties as to SmileSummary Platform</h6>
      <p>
        THE FULLCONTOUR PLATFORM (INCLUDING ALL FULLCONTOUR CONTENT) MAY CONTAIN
        TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. ALL INFORMATION IS
        PROVIDED BY FULLCONTOUR ON AN &quot;AS IS&quot; BASIS ONLY. TO THE
        MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, FULLCONTOUR PROVIDES NO
        REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE
        IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY
        AND NONINFRINGEMENT. USE OF THE FULLCONTOUR PLATFORM (INCLUDING ALL
        FULLCONTOUR CONTENT) IS SOLELY AT YOUR RISK.. FULLCONTOUR MAKES NO
        WARRANTY THAT (i) THE FULLCONTOUR PLATFORM WILL MEET YOUR REQUIREMENTS;
        (ii) THE FULLCONTOUR PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
        ERROR FREE; (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
        FULLCONTOUR PLATFORM WILL BE ACCURATE OR RELIABLE; AND (iv) THE QUALITY
        OF ANY PRODUCTS, WEBSITE, INFORMATION, OR OTHER MATERIAL PURCHASED OR
        OBTAINED BY YOU THROUGH THE FULLCONTOUR PLATFORM WILL MEET YOUR
        EXPECTATIONS. FULLCONTOUR EXPRESSLY DISCLAIMS ANY REPRESENTATIONS OR
        WARRANTIES THAT YOUR USE OF THE FULLCONTOUR PLATFORM WILL SATISFY ANY
        STATUTORY OR REGULATORY OBLIGATIONS, OR WILL ASSIST WITH, GUARANTEE, OR
        OTHERWISE ENSURE COMPLIANCE WITH ANY APPLICABLE LAWS OR REGULATIONS,
        INCLUDING BUT NOT LIMITED TO THE HEALTH INSURANCE PORTABILITY AND
        ACCOUNTABILITY ACT OF 1996 (&quot;HIPAA&quot;) AND AS MODIFIED AND
        AMENDED, THE GRAMM-LEACH-BLILEY ACT OF 1999 AND AS MODIFIED AND AMENDED,
        THE SARBANES-OXLEY ACT OF 2002 AND AS MODIFIED AND AMENDED, OR OTHER
        FEDERAL OR STATE STATUTES OR REGULATIONS.
        <br />
        <br />
        YOU ARE SOLELY RESPONSIBLE FOR ENSURING THAT YOUR USE OF THE FULLCONTOUR
        PLATFORM, RELATED SERVICES, OR CONTENT IS IN ACCORDANCE WITH APPLICABLE
        LAW. FULLCONTOUR DOES NOT GUARANTEE, IMPLICITLY OR EXPLICITLY, ANY
        RESULTS. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
        OF THE FULLCONTOUR PLATFORM IS DONE AT YOUR OWN DISCRETION AND RISK AND
        YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
        LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO
        ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
        FULLCONTOUR OR THROUGH OR FROM THE FULLCONTOUR PLATFORM SHALL CREATE ANY
        WARRANTY.
      </p>
      <h6>19. Transmissions</h6>
      <p>
        Any material, information, or idea you transmit to or post on the
        SmileSummary Platform by any means will be treated as non-confidential
        and non-proprietary, and may be disseminated or used by SmileSummary or
        its affiliates for any purpose whatsoever, including, but not limited
        to, developing, manufacturing and marketing its products.
        Notwithstanding the foregoing, all personal data provided to
        SmileSummary will be handled in accordance with SmileSummary’s Privacy
        Policy, available online or by contacting SmileSummary at the email
        address provided at the top of this Agreement. You are prohibited from
        posting or transmitting to or from the SmileSummary Platform any
        unlawful, threatening, libelous, defamatory, obscene, scandalous,
        inflammatory, pornographic, or profane material, or any other material
        that could give rise to any civil or criminal liability under the law.
      </p>
      <h6>20. User Communications and Acceptable Uses</h6>
      <p>
        SmileSummary, at any time now or in the future, may allow you and other
        Users to use the SmileSummary Platform to communicate with each other.
        Such communication rights, if included by SmileSummary in the
        SmileSummary Platform, will be set and controlled by the Customer that
        authorized the Users&apos; access.
        <br />
        <br />
        To help ensure a safe and productive environment, all Users must comply
        with the following acceptable use standards when using the SmileSummary
        Platform, as well as promptly report any inappropriate behavior or
        content to the appropriate Customer and SmileSummary.
        <br />
        <br />
        i. Acceptable Uses. You agree to undertake the following:
        <br />
        <br />
        a. Comply with all applicable laws and government regulations,
        including, without limitation, privacy laws, intellectual property laws,
        export control laws, labor and employment laws, tax laws, and regulatory
        requirements;
        <br />
        b. Upload and distribute only Customer Data to which Customer owns all
        required rights under law and under contractual and fiduciary
        relationships and do so only in compliance with all applicable laws and
        governmental regulations.
        <br />
        c. Provide accurate information and update it as necessary;
        <br />
        d. Use commercially reasonable efforts to prevent unauthorized access to
        our use of the SmileSummary Platform;
        <br />
        e. Protect passwords and access to sensitive personal information and
        other information that is confidential in nature;
        <br />
        f. Monitor and control activities conducted through your account;
        <br />
        g. Promptly notify Customer and SmileSummary of you are aware of or
        suspect any illegal or unauthorized activity or security breach,
        including any loss, theft or unauthorized disclosure or use of a user
        name or password;
        <br />
        h. Regularly review and comply with this Agreement
        <br />
        i. Regularly review and comply with the SmileSummary Privacy Policy;
        <br />
        j. Review and comply with notices sent by SmileSummary concerning the
        SmileSummary Platform; and
        <br />
        k. Use the SmileSummary Platform in a professional manner.
        <br />
        <br />
        ii. Unacceptable Uses. You agree not to do the following:
        <br />
        <br />
        a. Publish or input inaccurate information;
        <br />
        b. Use or attempt to use another&apos;s account without authorization
        from SmileSummary, or create a false identity on SmileSummary;
        <br />
        c. Upload, post, transmit or otherwise make available any content that:
        (a) is unlawful, libelous, abusive, obscene, discriminatory or otherwise
        objectionable; (b) includes information that you do not have the right
        to disclose or make available under any law or under contractual or
        fiduciary relationships; (c) infringes upon patents, trademarks, trade
        secrets, copyrights or other proprietary rights; or (d) contains
        software viruses, worms, or any other computer code, files or programs
        that interrupt, destroy or limit the functionality of any computer
        software or hardware or telecommunications equipment of SmileSummary or
        any User.
        <br />
        d. Reverse engineer, decompile, disassemble, decipher or otherwise
        attempt to derive the source code for any underlying intellectual
        property used to provide the SmileSummary Platform, or any part thereof.
        <br />
        e. Utilize or copy information, content or any data you view on and/or
        obtain from SmileSummary to provide any service that is competitive, in
        SmileSummary’s sole discretion, with SmileSummary;
        <br />
        f. Adapt, modify or create derivative works based on SmileSummary
        intellectual property or technology underlying the SmileSummary
        Platform;
        <br />
        g. Rent, lease, loan, trade, sell/re-sell access to the SmileSummary
        Platform or any information therein, or the equivalent, in whole or
        part;
        <br />
        h. Remove any copyright, trademark or other proprietary rights notices
        contained in or on the SmileSummary Platform;
        <br />
        i. Infringe or use SmileSummary’s brand, logos and/or trademarks,
        including, without limitation, using the words &quot;Full Contour&quot;
        in any business name, email, or URL, except as expressly permitted by
        SmileSummary;
        <br />
        j. Access the SmileSummary Platform for purposes of monitoring
        SmileSummary’s availability, performance or functionality for any
        competitive purpose, including for purposes of building a similar or
        competitive product or service or to copy any ideas, features, functions
        or components of the SmileSummary Platform;
        <br />
        k. Engage in &quot;framing,&quot; &quot;mirroring,&quot; or otherwise
        simulating the appearance or function of the SmileSummary Platform;
        <br />
        l. Attempt to or actually access the SmileSummary Platform by any means
        other than through the interfaces provided by SmileSummary;
        <br />
        m. Attempt to or actually override any security component included in or
        underlying the SmileSummary Platform;
        <br />
        n. use the SmileSummary Platform in violation of any law, including
        without limitation, HIPAA, 45 C.F.R. Part 2 (the &quot;Part 2
        Regulations&quot;).
        <br />
        o. Use the SmileSummary Platform in a manner that may harm minors or
        that interacts with or targets people who are under the age of eighteen;
        <br />
        p. Engage in any action that directly or indirectly interferes with the
        proper working of or places an unreasonable load on SmileSummary’s
        infrastructure, including, but not limited to, attempting to gain
        unauthorized access to the SmileSummary Platform, or transmitting or
        activating computer viruses through or on the SmileSummary Platform;
        <br />
        q. Interfere with or disrupt SmileSummary or the SmileSummary Platform,
        including, but not limited to, any servers or networks connected to
        SmileSummary.
        <br />
        <br />
        By posting any materials or other information on or through the
        SmileSummary Platform, except to the extent prohibit by law, including
        HIPAA, you grant SmileSummary a royalty-free, perpetual, irrevocable,
        non-exclusive license to use, reproduce, modify, publish, edit,
        translate, distribute, perform, and display such materials or other
        information alone or as part of other works in any form, media, or
        technology whether now known or hereafter developed without territorial
        or time limitations, and to sublicense such rights. SmileSummary shall
        act in good faith to maintain all information provided by you through
        the FulContour Platform in accordance with SmileSummary&apos;s
        then-current data retention practices, but reserves the right to delete
        any information that SmileSummary believes in good faith to be creating
        an immediate risk of injury or harm to the SmileSummary Platform,
        SmileSummary, its Customers or other Users.
        <br />
        <br />
        The SmileSummary Platform may contain links maintained by other third
        parties. These links are provided solely as a convenience to you and not
        because we endorse or have an opinion about the contents. We expressly
        disclaim any representations regarding the content or accuracy of
        materials or the privacy practices related to such third parties.
      </p>
      <h6>21. Termination and Suspension</h6>
      <p>
        In its sole and absolute discretion, with or without notice to you,
        SmileSummary may suspend or terminate your use of and access to the
        SmileSummary Platform, terminate your account without prior notice and
        for any reason, including, but not limited to: (i) concurrent access of
        the SmileSummary Platform with identical user identification, (ii)
        permitting another person or entity to use your user identification to
        access SmileSummary, (iii) any unauthorized access or use of
        SmileSummary, (iv) any suspected violation of this Agreement, (v)
        tampering with or alteration of any of the software and/or data files
        contained in, or accessed through, the SmileSummary Platform, or (vi)
        failure to use the SmileSummary Platform on a regular basis. You may
        terminate your account for any reason by emailing SmileSummary at
        info@SmileSummary.com. SmileSummary shall not be liable to you or any
        third party for any claims or damages arising out of any termination or
        suspension of the Service. Termination, suspension, or cancellation of
        the Services or your access rights shall not affect any right or relief
        to which SmileSummary may be entitled, at law or in equity, and all
        rights granted to you will automatically terminate and immediately
        revert to SmileSummary.
      </p>
      <h6>22. Digital Millennium Copyright Act (&quot;DMCA&quot;) Notice.</h6>
      <p>
        In the event materials are made available to the SmileSummary Platform
        by third parties not within our control, we are under no obligation to,
        and do not, scan such content for the inclusion of illegal or
        impermissible content. However, we respect the copyright interests of
        others. It is our policy not to permit materials known by us to infringe
        another party’s copyright to remain on the SmileSummary Platform. If you
        believe any materials on the SmileSummary Platform infringe a copyright,
        you should provide us with written notice that at a minimum contains:
        <br />
        <br />
        i. A physical or electronic signature of a person authorized to act on
        behalf of the owner of an exclusive right that is allegedly infringed;
        <br />
        ii. Identification of the copyrighted work claimed to have been
        infringed, or, if multiple copyrighted works at a single online site are
        covered by a single notification, a representative list of such works at
        that site;
        <br />
        iii. Identification of the material that is claimed to be infringing or
        to be the subject of infringing activity and that is to be removed or
        access to which is to be disabled, and information reasonably sufficient
        to permit us to locate the material;
        <br />
        iv. Information reasonably sufficient to permit us to contact the
        complaining party, such as an address, telephone number, and, if
        available, an electronic mail address at which the complaining party may
        be contacted;
        <br />
        v. A statement that the complaining party has a good faith belief that
        use of the material in the manner complained of is not authorized by the
        copyright owner, its agent, or the law; and
        <br />
        vi. A statement that the information in the notification is accurate,
        and under penalty of perjury, that the complaining party is authorized
        to act on behalf of the owner of an exclusive right that is allegedly
        infringed.
        <br />
        <br />
        All DMCA notices should be sent to our designated agent as follows:
        <br />
        Attn: Chief Technical Officer, SmileSummary LLC 17505 N. 79th Ave #412
        Glendale, AZ 85308
        <br />
        It is our policy to terminate relationships regarding content with third
        parties who repeatedly infringe the copyrights of others.
      </p>
      <h4>
        Article C – Terms for Use of the SmileSummary Professional Services
      </h4>
      <h6>23. Information.</h6>
      <p>
        Customer is responsible for providing SmileSummary with all information,
        including digital scans & design instructions, as is necessary for
        SmileSummary to complete all Professional Services, including the design
        of such dental prosthetics. SmileSummary is not responsible for the
        accuracy or inaccuracy of any information received from Customer and its
        Users and transmitted to the laboratory, manufacturer or clinician
        (including if transmitted via the SmileSummary Platform). All dental
        prosthetics and any other Professional Services shall be completed from
        the information that Customer and its Users provide and Customer shall
        be solely responsible for the final quality control of both the design
        and manufactured dental prosthetics upon completion.
      </p>
      <h6>24. Payment.</h6>
      <p>
        Payment of the Professional Services requested by Customer and provided
        by SmileSummary shall be paid via an authorized credit card provided by
        Customer and maintained on file by SmileSummary. Accrued charges for
        Professional Services shall be paid weekly.
      </p>
      <h6>25. Pricing.</h6>
      <p>
        The pricing of SmileSummary Professional Services is established in the
        Customer account wand is subject to change from time-to-time in
        SmileSummary&apos;s discretion. All pricing for Professional Services
        provided by SmileSummary is subject to federal, state or local taxes as
        maybe applicable.
      </p>
      <h6>
        26. Customer Responsibility for Finished Designs, Images and Products;
        Disclaimer of Warranties as to Professional Services
      </h6>
      <p>
        Customer is fully responsible for all Customer Data provided to
        SmileSummary, as well as for all finished designs, images or other
        information provided which SmileSummary will provide to the Customer
        and/or laboratory(ies), manufacturers or clinicians of the dental
        products. For the avoidance of any doubt, (a) if Customer is a
        prescribing clinician, Customer is responsible for viewing and approving
        the resulting designs from SmileSummary&apos;s Professional Services and
        (b) if Customer is not the prescribing clinician, Customer is
        responsible for ensuring that the resulting designs from
        SmileSummary&apos;s Professional Services meets the requirements of its
        clinician customer. SmileSummary makes no warranties or representations
        to Customer, its Users or any third party(ies) regarding the accuracy of
        any information provided to SmileSummary and the resulting designs
        produced by SmileSummary as part of its Professional Services, nor does
        SmileSummary make any warranties or representation as to the dental
        products produced therefrom. SmileSummary uses the SmileSummary Platform
        to transmit and deliver the finished designs to the integrated
        manufacturers, laboratories, and clinicians as a file transfer system.
        All files that are submitted to SmileSummary without selecting
        &quot;Requires Design Approval&quot; will be sent to the manufacture
        directly and the Customer is solely and fully responsible and liable for
        the final product(s) produced therefrom. The Customer has the option to
        select &quot;Requires Design Approval&quot; in order to approve the
        final designs prior to the case getting sent to the manufacture to avoid
        any mistakes. &quot;Requires Design Approval&quot; is highly
        recommending by SmileSummary. On certain dental prosthetic designs,
        SmileSummary may automatically suggest that Customer engage in the
        Design Approval process. In the event that Customer bypasses the Design
        Approval Option, then Customer accept and assume full responsibility for
        all dental products produced and/or any and all liability to any third
        party(ies) arising therefrom.
        <br />
        <br />
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, FULLCONOUR MAKES NO
        WARRANTY OF ANY KIND AS TO THE PROFESSIONAL SERVICES OR ANY DESIGNS
        RESULTING THEREFROM, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
        AND SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED
        WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
        NON-INFRINGEMENT.
      </p>
      <h6>27. Indemnification.</h6>
      <p>
        SmileSummary does NOT agree to indemnify, defend or to hold Customer
        harmless against or from any claims, causes of action and/or damages to
        any third party(ies) resulting from or arising out of the use of
        SmileSummary’s Professional Services, the SmileSummary Platform or from
        any defect in any dental product manufactured or produced by any
        laboratory, manufacturer or clinician. Customer agrees to fully
        indemnify, defend and hold SmileSummary harmless from any and all
        claims, causes of actions or damages arising out of or otherwise
        resulting from any defect in any crowns, abutments, surgical guides,
        clear aligners, orthodontic appliances, dentures, partial frames,
        copings, frameworks, and other related dental prosthetic devices
        produced or manufactured using SmileSummary’s Professional Services
        and/or the SmileSummary Platform, including but not limited to any
        claims of bodily injury, property damage or other form of injury
        resulting from said defect whether made by Customer&apos;s patients or
        otherwise.
      </p>
    </>
  );
}

export default TermsAndConditions;
