import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { CaseItemFields } from './CaseItemFields';

function CaseItem({
  customerLocation,
  arrayHelpers,
  toggleForceDesignApproval,
  parsedCase,
  labLocationGuideCategoryGroups = null,
}) {
  const {
    values: { orderItems, source },
  } = useFormikContext();

  return (
    <div className="column " style={{ borderRight: '1px solid #ddd9d9' }}>
      <div className="orderItemGrid">
        {orderItems.map((_orderItem, index) => (
          <div className="columns is-justify-content-space-between" key={index.toString()}>
            <CaseItemFields
              index={index}
              labLocationGuideCategoryGroups={labLocationGuideCategoryGroups}
              customerLocation={customerLocation}
              arrayHelpers={arrayHelpers}
              toggleForceDesignApproval={toggleForceDesignApproval}
              parsedCase={parsedCase}
            />
          </div>
        ))}
        {source && (
          <div>
            <span className="tag">{source}</span>
          </div>
        )}
      </div>
    </div>
  );
}

CaseItem.propTypes = {
  customerLocation: PropTypes.object.isRequired,
  labLocationGuideCategoryGroups: PropTypes.array,
  arrayHelpers: PropTypes.object.isRequired,
  toggleForceDesignApproval: PropTypes.func.isRequired,
  parsedCase: PropTypes.object.isRequired,
};

export default CaseItem;
