import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_ORG, GET_ROLES } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Dropdown from './Dropdown';

function RoleSelect({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  modelscope,
  disabled = false,
  setrole = null,
  hideLabel = false,
  style = {},
}) {
  const [rolesData, setRolesData] = useState(null);
  const [restricted, setRestricted] = useState(false);

  const handleChange = (e) => {
    const roleObj = rolesData.find((element) => element.id === e.target.value);

    if (setrole) {
      setrole(roleObj);
    }
    setFieldValue('roleId', e.target.value);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const OId = params.get('o_id');

  const { loading: orgLoading } = useQuery(GET_CUSTOMER_ORG, {
    variables: { id: OId },
    skip: !OId,
    onCompleted(d) {
      setRestricted(d.customerOrganization.restricted);
    },
  });

  const { error, loading, data } = useQuery(GET_ROLES, {
    variables: { modelScope: modelscope },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      const filteredRoles = data.roles.filter(
        (role) => !(restricted && role.name.includes('Organization')),
      );
      setRolesData(filteredRoles);
    }
  }, [data, restricted]);

  if (error) {
    return <p>Error :(</p>;
  }

  return (
    <Dropdown
      name={name}
      label="Role"
      hideLabel={hideLabel}
      error={error}
      containerStyle={style}
      errors={!!(errors.roleId && touched.roleId)}
      loading={loading || orgLoading}
      options={rolesData}
      onChange={handleChange}
      placeholder="Select a Role..."
      value={value}
      disabled={disabled || loading}
      required
    />
  );
}

RoleSelect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  }).isRequired,
  modelscope: PropTypes.string.isRequired,
  setrole: PropTypes.func,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  style: PropTypes.object,
};

export default RoleSelect;
