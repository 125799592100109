function extractObject(element) {
  const elementObj = {};
  for (const property of element) {
    elementObj[property.name] = property.value;

  }
  return elementObj;
}

export default extractObject;
