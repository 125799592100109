function addressValueGetter(params) {
  const data = params.data || {};

  const address = data?.attributes?.address || data?.address || null;

  if (!(address && (address.city || address.region || address.country))) {
    return '';
  }

  return `${address.city}${address.city ? ', ' : ''}${address.region} ${address.country}`;
}

export default addressValueGetter;
