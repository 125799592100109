import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Component, createRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { BeforeAfterButtons, PerspectiveChange, Slider } from '../Controls';
import './ThreeObj.css';

class ThreeObj extends Component {
  constructor(props) {
    super(props);
    this.threeRef = createRef();

    this.state = {
      objRotationY: 0,
      objRotationX: 0,
      viewType: null,
      showUpper: true,
      showLower: true,
      disabled: true,
      baDisabled: true,
      defaultRotation: true,
      triggerIncrement: false,
    };
  }

  componentDidMount() {
    const { theme } = this.props;

    this.mounted = true;
    const width = this.threeRef.current ? this.threeRef.current.clientWidth : 0;
    const height = this.threeRef.current
      ? this.threeRef.current.clientHeight
      : 0;
    const distance = 1000;

    // Lights
    const dLightA = new THREE.DirectionalLight(0xfff1e0, 0.05);
    const dLightB = new THREE.DirectionalLight(0xfff1e0, 0.1);
    const aLight = new THREE.AmbientLight(0xfffaf4, 0.25);
    const pLight = new THREE.PointLight(0xfffaf4, 0.85);

    dLightA.position.set(1, -250, 1);
    dLightB.position.set(250, 1, 250);

    // Camera
    this.camera = new THREE.PerspectiveCamera(35, width / height, 1, distance);
    this.camera.position.z = width && width > 399 ? 150 : 245;
    this.camera.position.y = 0;
    this.camera.add(pLight);

    // Renderer
    this.renderer = new THREE.WebGLRenderer({
      preserveDrawingBuffer: true,
      antialias: true,
    });
    this.renderer.setSize(width, height);
    this.renderer.domElement.id = 'fcThreeRenderer';
    // this.renderer.outputEncoding = THREE.GammaEncoding;
    this.renderer.shadowMap.enabled = true;
    this.threeRef.current.appendChild(this.renderer.domElement);

    // Scene / Environment
    this.scene = new THREE.Scene();

    if (theme === 'light') {
      this.scene.background = new THREE.Color(0xfdfdfd);
    }

    if (theme === 'dark') {
      this.scene.background = new THREE.Color(0x121212);
    }

    this.scene.add(dLightA);
    this.scene.add(dLightB);
    this.scene.add(aLight);
    this.scene.add(this.camera);

    // Orbit Controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableKeys = false;
    this.controls.screenSpacePanning = false;
    this.controls.maxDistance = 500.0;
    this.controls.minDistance = 50.0;
    this.controls.addEventListener('change', this.orbitRender);

    this.mainGroup = new THREE.Object3D();

    this.box = new THREE.Box3();
    this.helper = new THREE.Box3Helper(this.box, 0xfdfdfd);
    this.helper.visible = false;

    // const axesHelper = new THREE.AxesHelper(50);
    // this.scene.add(axesHelper);

    this.scene.add(this.helper);
    this.scene.add(this.mainGroup);

    this.loadInitialFiles();
  }

  componentWillUnmount() {
    this.controls.removeEventListener('change', this.orbitRender);
    this.controls.dispose();

    if (this.renderer?.domElement && this.threeRef.current) {
      this.threeRef.current.removeChild(this.renderer.domElement);
    }

    this.mounted = false;
  }

  promisifyLoader = (loader, onProgress) => {
    function promiseLoader(url) {
      return new Promise((resolve, reject) => {
        loader.load(url, resolve, onProgress, reject);
      });
    }

    return {
      originalLoader: loader,
      load: promiseLoader,
    };
  };

  renderScene = () => {
    const { loading, setLoading } = this.props;

    if (loading) {
      setLoading(false);
    }

    const width = this.threeRef.current ? this.threeRef.current.clientWidth : 0;
    const height = this.threeRef.current
      ? this.threeRef.current.clientHeight
      : 0;

    this.renderer.setSize(width, height);
    this.camera.updateProjectionMatrix();
    this.camera.lookAt(this.scene.position);
    this.renderer.render(this.scene, this.camera);
  };

  loadInitialFiles = async () => {
    const { stages } = this.props;

    const totalPromises = [];

    const lastStage = stages[stages.length - 1];

    const filteredStages = stages.filter(
      (stage) => stage.index === 0 || stage.index === lastStage.index,
    );
    for (const stage of filteredStages) {
      if (stage.upper?.obj) {
        totalPromises.push(this.loadObj(stage.upper.obj));
      }

      if (stage.lower?.obj) {
        totalPromises.push(this.loadObj(stage.lower.obj));
      }
    }

    Promise.all(totalPromises).then(() => {
      const vec = new THREE.Vector3();
      this.box.setFromObject(this.mainGroup);

      this.mainGroup.position.set(
        -1 * this.helper.getWorldPosition(vec).x,
        -1 * this.helper.getWorldPosition(vec).y,
        -1 * this.helper.getWorldPosition(vec).z,
      );

      if (stages.length < 3) {
        this.setState({ baDisabled: false, disabled: false });
      } else {
        this.setState({ baDisabled: false });
      }

      this.renderScene();
      this.loadFiles();
    });
  };

  loadFiles = () => {
    const { stages } = this.props;

    const totalPromises = [];

    const lastStage = stages[stages.length - 1];

    const filteredStages = stages.filter(
      (stage) => stage.index !== 0 && stage.index !== lastStage.index,
    );
    for (const stage of filteredStages) {
      if (stage.upper?.obj) {
        totalPromises.push(this.loadObj(stage.upper.obj));
      }

      if (stage.lower?.obj) {
        totalPromises.push(this.loadObj(stage.lower.obj));
      }
    }

    if (totalPromises.length > 0) {
      Promise.all(totalPromises).then(() => {
        //  this.box.setFromObject(this.mainGroup);

        const vec = new THREE.Vector3();

        this.mainGroup.position.set(
          -1 * this.helper.getWorldPosition(vec).x,
          -1 * this.helper.getWorldPosition(vec).y,
          -1 * this.helper.getWorldPosition(vec).z,
        );

        this.setState({ disabled: false });
        this.renderScene();
      });
    }
  };

  loadObj = async (obj) => {
    const objLoader = this.promisifyLoader(new OBJLoader());
    // objLoader.originalLoader.setLogging(false, false);

    await objLoader
      .load(obj.signedUrl)
      .then((evt) => this.onLoadObj(evt, obj))
      .catch(this.onError);
  };

  onLoadObj = (evt, obj) => {
    const { defaultRotation } = this.state;

    const gumMaterial = new THREE.MeshPhongMaterial({
      color: new THREE.Color(0.87, 0.27, 0.2),
    });
    const toothMaterial = new THREE.MeshPhongMaterial({
      color: new THREE.Color(0.78, 0.78, 0.78),
    });

    if (this.mounted) {
      const { sliderVal } = this.props;
      const { objRotationY, objRotationX } = this.state;

      evt.traverse((child) => {
        if (child instanceof THREE.Mesh && child.name.includes('Tooth')) {
          child.material = toothMaterial;
          child.material.shininess = 30;
        }

        if (child instanceof THREE.Mesh && !child.name.includes('Tooth')) {
          child.material = gumMaterial;
          child.material.shininess = 15;
        }
      });

      const geo = evt.children[0].geometry;

      geo.computeBoundingBox();

      let baseR = 0;

      if (
        geo.boundingBox.max.z - geo.boundingBox.min.z <
        geo.boundingBox.max.y - geo.boundingBox.min.y
      ) {
        baseR = -90;
        if (defaultRotation) {
          this.setState({ defaultRotation: false });
        }
      }

      evt.position.y = 0;
      evt.rotation.y = (objRotationY * Math.PI) / 180;
      evt.rotation.x = ((objRotationX + baseR) * Math.PI) / 180;
      evt.name = `obj-${obj.category}-${obj.index}`;
      evt.visible = obj.index === sliderVal;
      evt.receiveShadow = true;
      evt.castShadow = true;

      this.mainGroup.add(evt);
    }
  };

  orbitRender = () => {
    this.renderScene();
  };

  changeSlider = (event) => {
    const { stages, setSliderVal } = this.props;

    const { showLower, showUpper } = this.state;

    const val = event.target.value;

    setSliderVal(Number(val));
    for (const stage of stages) {
      const objUpper = this.scene.getObjectByName(`obj-upper-${stage.index}`);
      const objLower = this.scene.getObjectByName(`obj-lower-${stage.index}`);

      if (stage.index.toString() === val.toString() && objUpper) {
        objUpper.visible = showUpper;
      }

      if (stage.index.toString() !== val.toString() && objUpper) {
        objUpper.visible = false;
      }

      if (stage.index.toString() === val.toString() && objLower) {
        objLower.visible = showLower;
      }

      if (stage.index.toString() !== val.toString() && objLower) {
        objLower.visible = false;
      }
    }

    this.renderScene();
  };

  setTriggerIncrementOff = () => {
    this.setState({ triggerIncrement: false });
  };

  setTriggerIncrementOn = (e) => {
    this.setState({ triggerIncrement: true }, () => {
      this.changeSlider(e);
    });
  };

  changePerspective = (view) => {
    const { defaultRotation } = this.state;
    let rotationY = 0;
    let rotationX = 0;
    let hideUpper = true;
    let hideLower = true;

    const vec = new THREE.Vector3();
    let newX = -1 * this.helper.getWorldPosition(vec).x;
    let newY = -1 * this.helper.getWorldPosition(vec).y;
    let newZ = -1 * this.helper.getWorldPosition(vec).z;

    if (view === 'left') {
      rotationY = -90;
      hideUpper = true;
      hideLower = true;

      if (defaultRotation) {
        newX = Math.abs(this.helper.getWorldPosition(vec).z / 2);
        newZ = Math.abs(this.helper.getWorldPosition(vec).x);
      } else {
        newX = Math.abs(this.helper.getWorldPosition(vec).z / 2) * -1;
        newZ = this.helper.getWorldPosition(vec).x;
      }
    }

    if (view === 'right') {
      rotationY = 90;
      hideUpper = true;
      hideLower = true;

      if (defaultRotation) {
        newX = Math.abs(this.helper.getWorldPosition(vec).z / 2) * -1;
        newZ = Math.abs(this.helper.getWorldPosition(vec).x) * -1;
      } else {
        newX = Math.abs(this.helper.getWorldPosition(vec).z / 2);
        newZ = this.helper.getWorldPosition(vec).x;
      }
    }

    if (view === 'down') {
      rotationX = 90;
      hideUpper = false;
      hideLower = true;

      if (defaultRotation) {
        newY = this.helper.getWorldPosition(vec).z;
        newZ = this.helper.getWorldPosition(vec).y * -1;
      } else {
        newY = this.helper.getWorldPosition(vec).z;
        newZ = this.helper.getWorldPosition(vec).y;
      }
    }

    if (view === 'top') {
      rotationX = -90;
      hideUpper = true;
      hideLower = false;

      if (defaultRotation) {
        newY = this.helper.getWorldPosition(vec).z * -1;
        newZ = this.helper.getWorldPosition(vec).y;
      } else {
        newY = this.helper.getWorldPosition(vec).z;
        newZ = this.helper.getWorldPosition(vec).y;
      }
    }

    this.mainGroup.position.set(newX, newY, newZ);

    this.setState(
      {
        objRotationY: rotationY,
        objRotationX: rotationX,
        viewType: view,
        showUpper: hideUpper,
        showLower: hideLower,
      },
      () => {
        const { objRotationX, objRotationY } = this.state;

        this.mainGroup.rotation.y = (objRotationY * Math.PI) / 180;
        this.mainGroup.rotation.x = (objRotationX * Math.PI) / 180;

        this.controls.reset();
        this.setArchVisibility();

        this.renderScene();
      },
    );
  };

  setArchVisibility = () => {
    const { stages, sliderVal } = this.props;

    const { showUpper, showLower } = this.state;
    for (const stage of stages) {
      const objUpper = this.scene.getObjectByName(`obj-upper-${stage.index}`);
      const objLower = this.scene.getObjectByName(`obj-lower-${stage.index}`);

      if (stage.index.toString() === sliderVal.toString() && objUpper) {
        objUpper.visible = showUpper;
      }

      if (stage.index.toString() !== sliderVal.toString() && objUpper) {
        objUpper.visible = false;
      }

      if (stage.index.toString() === sliderVal.toString() && objLower) {
        objLower.visible = showLower;
      }

      if (stage.index.toString() !== sliderVal.toString() && objLower) {
        objLower.visible = false;
      }
    }

    this.renderScene();
  };

  onError = (err) => {
    console.error(`An error happened ${err}`);
  };

  render() {
    const { loading, sliderVal, stages, setPlaying, playing, logoUrl } =
      this.props;

    const { viewType, disabled, baDisabled, triggerIncrement } = this.state;

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          backgroundColor: '#121212',
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          ref={this.threeRef}
        />
        {!loading && (
          <>
            {logoUrl && (
              <div
                className="locationLogo"
                style={{ backgroundImage: `url("${logoUrl}")` }}
              />
            )}
            <div className="lowerObjControls">
              <Slider
                sliderVal={sliderVal}
                stages={stages}
                changeSlider={this.changeSlider}
                disabled={disabled}
                triggerIncrement={triggerIncrement}
                setTriggerIncrementOff={this.setTriggerIncrementOff}
                setTriggerIncrementOn={this.setTriggerIncrementOn}
                autoplay={playing ? 1250 : 0}
              />
              <BeforeAfterButtons
                stages={stages}
                sliderVal={sliderVal}
                baDisabled={baDisabled}
                setPlaying={setPlaying}
                playing={playing}
                disabled={disabled}
                setTriggerIncrementOn={this.setTriggerIncrementOn}
              />
            </div>
            <PerspectiveChange
              changePerspective={this.changePerspective}
              viewType={viewType}
              disabled={disabled}
            />
          </>
        )}
      </div>
    );
  }
}

ThreeObj.propTypes = exact({
  stages: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  sliderVal: PropTypes.number.isRequired,
  setSliderVal: PropTypes.func.isRequired,
  setPlaying: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
});

ThreeObj.defaultProps = {
  logoUrl: null,
};

export default ThreeObj;
