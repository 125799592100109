import { BoxIcon } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function CaseActionsButtonCancel({ cancelSubmit, submit }) {
  const { t } = useTranslation('orders');
  return submit ? (
    <button
      type="button"
      className="button is-small has-tooltip-arrow has-text-danger has-tooltip-left px-5"
      onClick={cancelSubmit}
      data-tooltip={t('Cancel Submission')}
    >
      <BoxIcon name="bx-block" />
    </button>
  ) : null;
}

CaseActionsButtonCancel.propTypes = {
  cancelSubmit: PropTypes.func.isRequired,
  submit: PropTypes.bool.isRequired,
};

export default CaseActionsButtonCancel;
