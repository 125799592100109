import { BoxIcon, ModifierOrderConsumer } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { memo } from 'react';
import '../GuideSelection/GuideSelectionPopup.css';

function ModifierSelectionPopup({
  modifierGroups = [],
  index,
  currentOrderItem,
}) {
  return (
    <ModifierOrderConsumer>
      {({ modifierModalOpen }) => (
        <button
          type="button"
          id="modifier-btn"
          disabled={modifierGroups?.length === 0}
          className="button is-outlined is-primary mr-3 py-4"
          onClick={() =>
            modifierModalOpen(modifierGroups, index, currentOrderItem)
          }
        >
          <BoxIcon
            className="icon is-flex is-align-items-center is-justify-content-center"
            name="bx-edit"
          />
        </button>
      )}
    </ModifierOrderConsumer>
  );
}

ModifierSelectionPopup.propTypes = {
  modifierGroups: PropTypes.array,
  index: PropTypes.number.isRequired,
  currentOrderItem: PropTypes.object.isRequired,
};

export default memo(ModifierSelectionPopup);
