import { TempOrderActionsButtons } from './TempOrderActionButtons';

function TempOrderActions(props) {
  return (
    <div className="column is-narrow is-relative pt-4">
      <TempOrderActionsButtons {...props} />
    </div>
  );
}

export default TempOrderActions;
