import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import GuideCodeCard from '../GuideCodeCard/GuideCodeCard';
import useHandleCardsActions from './hooks/useHandleCardsActions';
import { findCurrentCode } from './utils';

function GuideBodyCodeCard({
  guideCategory = null,
  guideCategoryGroupId = null,
  filteValue = '',
  designType = null,
  hideCreateOtherButton = false,
  values = {},
  form: { setFieldValue, ...otherForm } = {},
  orderItemIndex = null,
  dataSources: {
    customerLocation: { numberingSystem, ...otherCustomerLoc },
    ...otherDataSources
  } = {},
  ...rest
}) {
  const props = {
    guideCategory,
    guideCategoryGroupId,
    filteValue,
    designType,
    hideCreateOtherButton,
    values,
    form: { setFieldValue, ...otherForm },
    orderItemIndex,
    dataSources: {
      customerLocation: { numberingSystem, ...otherCustomerLoc },
      ...otherDataSources,
    },
    ...rest,
  };
  const { guideCodes } = values.orderItems[orderItemIndex];
  const [guideCode, setGuideCode] = useState(null);

  useEffect(() => {
    setGuideCode((prev = {}) => {
      const updatedCode = guideCategory.guideCodes.find(
        (item) => item.id === guideCode?.id,
      );
      setTimeout(() => {
        if (
          updatedCode &&
          updatedCode.default !== prev.default &&
          !updatedCode.default &&
          isSelected
        ) {
          setFieldValue(
            `orderItems[${orderItemIndex}].guideCodes`,
            guideCodes.filter((item) => item.codeId !== updatedCode.id),
          );
        }
      }, 150);
      return {
        ...updatedCode,
        ...findCurrentCode(guideCategory, guideCodes),
      };
    });
  }, [guideCategory]);

  const { handleOtherValue, handleDropdown, handleChange } =
    useHandleCardsActions({
      setGuideCode,
      guideCodes,
      orderItemIndex,
      setFieldValue,
    });

  const isSelected = guideCodes?.some((item) => item.codeId === guideCode?.id);

  if (!guideCode) {
    return null;
  }

  const guideCodeFullData = {
    ...guideCode,
    briefDescription: guideCategory.showDropdown
      ? guideCode?.description || guideCategory.short_description
      : guideCategory.short_description,
    fullDescription: guideCategory.description,
    imageUrl: guideCode.imageUrl || guideCategory.imageUrl,
    category: guideCategory,
    categoryAdditionalInfo: {
      guideCategoryImages: guideCategory.guideCategoryImages,
      fullDescription: guideCategory.description,
    },
    guideCategoryGroupId,
    designType,
    searchValue: filteValue,
    numberingSystem,
    handleChange,
    isSelected,
  };

  return (
    <GuideCodeCard
      {...props}
      image={<GuideCodeCard.Image />}
      actionButtons={
        <GuideCodeCard.ActionButtons {...props}>
          <GuideCodeCard.ButtonNestedSelection {...props} /> {/* //x */}
          <GuideCodeCard.ButtonFavourites {...props} />
          <GuideCodeCard.ButtonInformation {...props} />
          <GuideCodeCard.ButtonDefaults
            helpers={{
              orderItemIndex,
              setFieldValue,
              guideCodes,
              setGuideCode,
              handleDropdown,
            }}
            {...props}
          />
        </GuideCodeCard.ActionButtons>
      }
      cardDropdown={
        <GuideCodeCard.Dropdown handleDropdown={handleDropdown}>
          <GuideCodeCard.OtherInput
            hideCreateOtherButton={hideCreateOtherButton}
            handleOtherValue={handleOtherValue}
          />
        </GuideCodeCard.Dropdown>
      }
      nestedLabels={<GuideCodeCard.NestedLabels {...props} />}
      footer={<GuideCodeCard.CardFooter />}
      data={guideCodeFullData}
      handleChange={handleChange}
      isSelected={isSelected}
    />
  );
}

GuideBodyCodeCard.propTypes = {
  guideCategory: PropTypes.object,
  guideCategoryGroupId: PropTypes.string,
  filteValue: PropTypes.string,
  designType: PropTypes.object,
  hideCreateOtherButton: PropTypes.bool,
  values: PropTypes.object,
  form: PropTypes.object,
  orderItemIndex: PropTypes.number,
  dataSources: PropTypes.object,
};

export default GuideBodyCodeCard;
