export default function X1Svg() {
  return (
    <svg
      height="42"
      viewBox="0 0 35 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>svg</title>
      <path
        d="M17.8634 6.81006C17.7038 6.81006 17.5508 6.74666 17.4379 6.63381C17.3251 6.52097 17.2617 6.36791 17.2617 6.20831C17.2309 5.54139 16.9444 4.91195 16.4615 4.45085C15.9787 3.98976 15.3367 3.7325 14.6691 3.7325C14.0015 3.7325 13.3595 3.98976 12.8767 4.45085C12.3938 4.91195 12.1073 5.54139 12.0765 6.20831C12.0765 6.36791 12.0131 6.52097 11.9003 6.63381C11.7874 6.74666 11.6344 6.81006 11.4748 6.81006C11.3152 6.81006 11.1621 6.74666 11.0493 6.63381C10.9364 6.52097 10.873 6.36791 10.873 6.20831C10.9061 5.2236 11.3206 4.29027 12.0288 3.60538C12.7371 2.92048 13.6838 2.5376 14.6691 2.5376C15.6544 2.5376 16.6011 2.92048 17.3094 3.60538C18.0176 4.29027 18.4321 5.2236 18.4652 6.20831C18.4652 6.36791 18.4018 6.52097 18.2889 6.63381C18.1761 6.74666 18.023 6.81006 17.8634 6.81006V6.81006Z"
        fill="#4C4D52"
      />
      <path
        d="M11.7134 25.0727C11.194 25.0726 10.6812 24.9571 10.2119 24.7346C9.74269 24.5121 9.32875 24.1881 9.00009 23.786C8.67142 23.3838 8.43623 22.9136 8.31153 22.4095C8.18682 21.9054 8.17571 21.3798 8.27902 20.8708C8.30383 20.7456 8.37139 20.633 8.47012 20.5522C8.56884 20.4713 8.6926 20.4272 8.82022 20.4275H14.6034C14.7312 20.427 14.8551 20.471 14.9539 20.5519C15.0528 20.6328 15.1204 20.7455 15.1452 20.8708C15.2486 21.3796 15.2377 21.905 15.1131 22.4091C14.9886 22.9131 14.7537 23.3831 14.4252 23.7852C14.0968 24.1873 13.6831 24.5114 13.2141 24.7341C12.7451 24.9567 12.2325 25.0724 11.7134 25.0727ZM9.31436 21.5331V21.5698C9.31436 22.2061 9.56709 22.8163 10.017 23.2662C10.4669 23.7161 11.0771 23.9688 11.7134 23.9688C12.3496 23.9688 12.9598 23.7161 13.4097 23.2662C13.8596 22.8163 14.1124 22.2061 14.1124 21.5698V21.5331H9.31436Z"
        fill="#C2003E"
      />
      <path
        d="M6.25653 13.4458C6.09689 13.4458 5.94376 13.5091 5.83081 13.622C5.71787 13.7348 5.65435 13.8879 5.65419 14.0475V28.6795C5.65403 28.8011 5.60565 28.9176 5.51967 29.0036C5.4337 29.0896 5.31714 29.138 5.19555 29.1381H1.78323C1.66164 29.138 1.54508 29.0896 1.4591 29.0036C1.37313 28.9176 1.32475 28.8011 1.32459 28.6795V17.2807C1.32495 17.1782 1.3595 17.0788 1.42275 16.9981C1.486 16.9175 1.57431 16.8602 1.67376 16.8355L3.79453 16.2429C3.92112 16.2075 4.03267 16.1318 4.11217 16.0271C4.19166 15.9224 4.23472 15.7946 4.23481 15.6632V14.0481C4.23481 13.8886 4.17142 13.7355 4.05857 13.6226C3.94572 13.5098 3.7927 13.4464 3.63311 13.4464C3.47351 13.4464 3.32045 13.5098 3.20761 13.6226C3.09476 13.7355 3.03136 13.8886 3.03136 14.0481V15.2063L1.35885 15.6736C1.00419 15.7674 0.690502 15.9759 0.466644 16.2666C0.242786 16.5572 0.121289 16.9138 0.121094 17.2807V28.6795C0.121741 29.1201 0.297058 29.5425 0.608626 29.854C0.920195 30.1656 1.3426 30.3409 1.78323 30.3416H5.19555C5.63622 30.3411 6.0587 30.1659 6.3703 29.8543C6.6819 29.5426 6.85716 29.1202 6.85765 28.6795V14.0487C6.85781 13.8891 6.7946 13.7359 6.68188 13.6229C6.56917 13.5098 6.41618 13.4461 6.25653 13.4458V13.4458Z"
        fill="#4C4D52"
      />
      <path
        d="M34.2268 13.6194L32.3274 12.3463C32.262 12.301 32.1884 12.2691 32.1106 12.2526C32.0329 12.2361 31.9526 12.2352 31.8745 12.25C31.7964 12.2648 31.722 12.295 31.6557 12.3388C31.5894 12.3826 31.5324 12.4392 31.4882 12.5052C31.4439 12.5712 31.4133 12.6454 31.3979 12.7234C31.3826 12.8014 31.383 12.8817 31.399 12.9595C31.415 13.0374 31.4463 13.1113 31.4912 13.1769C31.536 13.2425 31.5935 13.2986 31.6602 13.3418L33.5755 14.626C33.5865 14.6333 33.5981 14.6407 33.6104 14.6474C33.668 14.6804 33.7159 14.7279 33.7494 14.7852C33.7829 14.8425 33.8007 14.9076 33.8012 14.974V28.6189C33.7999 28.7204 33.7584 28.8171 33.6858 28.888C33.6132 28.9588 33.5155 28.998 33.4141 28.9968H32.0993C31.9403 28.9968 31.7879 29.06 31.6755 29.1724C31.5631 29.2848 31.5 29.4372 31.5 29.5962C31.5 29.7551 31.5631 29.9075 31.6755 30.0199C31.7879 30.1323 31.9403 30.1955 32.0993 30.1955H33.4141C33.8332 30.1961 34.2355 30.0305 34.5328 29.7349C34.83 29.4394 34.9979 29.0381 34.9997 28.6189V14.9727C34.999 14.6996 34.9273 14.4313 34.7919 14.1941C34.6564 13.9569 34.4617 13.7589 34.2268 13.6194Z"
        fill="#4C4D52"
      />
      <path
        d="M30.5432 0.851424C26.9018 -0.307933 22.9865 -0.282718 19.3603 0.923534C19.2403 0.963253 19.136 1.03976 19.062 1.14218C18.988 1.2446 18.9481 1.36772 18.9481 1.49408V6.03468C18.9481 6.19435 19.0115 6.3475 19.1244 6.4604C19.2373 6.57331 19.3905 6.63672 19.5502 6.63672C19.7098 6.63672 19.8629 6.57331 19.9759 6.4604C20.0888 6.3475 20.1522 6.19435 20.1522 6.03468V1.93383C23.2809 0.982591 26.6187 0.961285 29.7592 1.87262V7.48339H0.982717C0.853613 7.48339 0.725792 7.50884 0.606523 7.55826C0.487254 7.60769 0.378885 7.68014 0.287623 7.77146C0.196361 7.86278 0.124 7.97114 0.0746492 8.09044C0.0252989 8.20974 -8.01485e-05 8.33764 1.90141e-07 8.46674V11.9059C-8.01485e-05 12.035 0.0252989 12.1629 0.0746492 12.2822C0.124 12.4015 0.196361 12.5099 0.287623 12.6012C0.378885 12.6925 0.487254 12.765 0.606523 12.8144C0.725792 12.8638 0.853613 12.8892 0.982717 12.8892H29.758V39.6325C26.6174 40.5432 23.2798 40.522 20.151 39.5713V39.5713V31.2014C20.151 31.0417 20.0875 30.8886 19.9746 30.7757C19.8617 30.6628 19.7086 30.5994 19.5489 30.5994C19.3893 30.5994 19.2361 30.6628 19.1232 30.7757C19.0103 30.8886 18.9469 31.0417 18.9469 31.2014V39.5713C18.9469 39.7334 18.9536 39.9003 18.9677 40.066C18.9771 40.1836 19.021 40.2958 19.0939 40.3886C19.1668 40.4814 19.2654 40.5507 19.3774 40.5877C22.9902 41.7845 26.8885 41.8105 30.5169 40.6623C30.6296 40.6268 30.7294 40.5589 30.8036 40.467C30.8778 40.375 30.9232 40.2632 30.934 40.1455C30.9523 39.9566 30.9615 39.7634 30.9615 39.5713V1.48124C30.9615 1.45861 30.9615 1.43541 30.9615 1.41278C30.9592 1.28723 30.9177 1.16555 30.8426 1.06485C30.7676 0.964145 30.6629 0.889512 30.5432 0.851424ZM29.6492 11.684H1.20349V8.68748H29.6486L29.6492 11.684Z"
        fill="#4C4D52"
      />
      <path
        d="M19.549 25.3621C19.7088 25.3621 19.862 25.2986 19.975 25.1857C20.0879 25.0727 20.1514 24.9195 20.1514 24.7597V14.4788C20.1514 14.3191 20.0879 14.1659 19.975 14.053C19.8621 13.9401 19.709 13.8767 19.5493 13.8767C19.3896 13.8767 19.2365 13.9401 19.1236 14.053C19.0107 14.1659 18.9473 14.3191 18.9473 14.4788V24.7603C18.9474 24.9199 19.0109 25.0728 19.1237 25.1857C19.2365 25.2985 19.3895 25.3619 19.549 25.3621V25.3621Z"
        fill="#4C4D52"
      />
      <path
        d="M13.1328 40.9999H10.239C10.0871 40.9999 9.94132 40.9396 9.83386 40.8321C9.72641 40.7247 9.66602 40.5789 9.66602 40.4269V32.0735C9.66602 31.9215 9.72641 31.7758 9.83386 31.6684C9.94132 31.5609 10.0871 31.5005 10.239 31.5005H13.1328C13.2079 31.5006 13.2823 31.5155 13.3517 31.5443C13.4212 31.5731 13.4842 31.6154 13.5373 31.6686C13.5904 31.7218 13.6325 31.7849 13.6612 31.8544C13.6899 31.9239 13.7046 31.9983 13.7045 32.0735V40.4269C13.7045 40.5787 13.6443 40.7243 13.5371 40.8317C13.4299 40.9391 13.2845 40.9996 13.1328 40.9999V40.9999ZM10.809 39.8551H12.5579V32.6453H10.809V39.8551Z"
        fill="#4C4D52"
      />
      <path
        d="M24.8176 30.1244H9.09223C8.93361 30.1244 8.78146 30.0614 8.6693 29.9492C8.55714 29.8371 8.49414 29.6849 8.49414 29.5263V26.7004C8.49414 26.6219 8.50961 26.5442 8.53968 26.4716C8.56974 26.3991 8.6138 26.3333 8.66934 26.2778C8.72489 26.2223 8.79083 26.1783 8.86339 26.1483C8.93595 26.1183 9.01372 26.1029 9.09223 26.103H24.8176C24.8961 26.1029 24.9739 26.1183 25.0465 26.1483C25.1191 26.1782 25.185 26.2222 25.2406 26.2777C25.2962 26.3332 25.3404 26.3991 25.3705 26.4716C25.4006 26.5442 25.4162 26.6219 25.4163 26.7004V29.5263C25.4163 29.6049 25.4008 29.6827 25.3707 29.7553C25.3406 29.8279 25.2965 29.8938 25.2409 29.9494C25.1853 30.0049 25.1193 30.049 25.0466 30.0791C24.974 30.1091 24.8962 30.1245 24.8176 30.1244ZM9.69028 28.9283H24.2195V27.2991H9.69028V28.9283Z"
        fill="#4C4D52"
      />
    </svg>
  );
}
