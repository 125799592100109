import PropTypes from 'prop-types';
import TempOrderActionsButtonRemoveItem from './TempOrderActionsButtonRemoveItem';
import TempOrderActionsButtonSubmit from './TempOrderActionsButtonSubmit';

function TempOrderActionsButtons(props) {
  const {
    removeTempOrder,
    tempOrder,
    submitAll,
    submitForm,
    values,
    setSingleCase,
  } = props;

  return (
    <div className="buttons is-centered is-flex-direction-column">
      <TempOrderActionsButtonSubmit
        submitAll={submitAll}
        setSingleCase={setSingleCase}
        submitForm={submitForm}
        tempOrder={tempOrder}
        values={values}
      />
      <TempOrderActionsButtonRemoveItem
        removeTempOrder={removeTempOrder}
        tempOrder={tempOrder}
      />
    </div>
  );
}

TempOrderActionsButtons.propTypes = {
  removeTempOrder: PropTypes.func.isRequired,
  tempOrder: PropTypes.object.isRequired,
  submitAll: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  setSingleCase: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default TempOrderActionsButtons;
