import { useQuery } from '@apollo/client';
import { BaseLoader, Billing } from '@fullcontour/common';
import { GET_CUSTOMER_ORG_BILLING } from '@fullcontour/shared-api';
import { useParams } from 'react-router';

function OrganizationCustomerBilling(props) {
  const { slug } = useParams();

  const variables = slug ? { slug } : {};

  const { error, loading, data } = useQuery(GET_CUSTOMER_ORG_BILLING, {
    variables,
  });

  if (error) {
    return <p>Error :(</p>;
  }
  if (loading) {
    return <BaseLoader title="Billing Data" loading />;
  }

  const customer = data.customerOrganization;

  return <Billing customer={customer} type="organization" {...props} />;
}

export default OrganizationCustomerBilling;
