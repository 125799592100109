import { CurrentUserContext } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  BaseDefinition,
  FileTypeLab,
  FileTypeZip,
} from './DataDefinitionFiles/Customer';
import { fileTypeFinder } from './fileTypeFinder';

class ParsingEngine extends Component {
  state = {
    acceptedTypes: [
      { extension: 'zip', definitionFile: FileTypeZip },
      { extension: 'lab', definitionFile: FileTypeLab },
    ],
    otherType: {
      definitionFile: BaseDefinition,
    },
    parsedCase: null,
  };

  async componentDidMount() {
    this.setState({ parsedCase: await this.parseCase() });
  }

  parseCase = () => {
    const { file, softwareNodes, customerLocation } = this.props;
    const { currentUser } = this.context;
    const { acceptedTypes, otherType } = this.state;

    const fileType = fileTypeFinder(acceptedTypes, otherType, file);

    return new fileType.definitionFile(file, currentUser).buildCase({
      softwareNodes,
      customerLocation,
    });
  };

  render() {
    const { parsedCase } = this.state;
    const { render: renderProp, softwareNodes, ...rest } = this.props;

    return renderProp({
      parsedCase,
      ...rest,
    });
  }
}

ParsingEngine.propTypes = {
  render: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  customerLocation: PropTypes.object.isRequired,
  softwareNodes: PropTypes.array.isRequired,
};

ParsingEngine.contextType = CurrentUserContext;

export default ParsingEngine;
