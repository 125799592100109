import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LegalModal } from '../Legal';
import Styles from './SignInLegal.module.css';

function SignInLegal({ authState = null }) {
  const { t } = useTranslation('signin');

  const [openTerms, setOpenTerms] = useState(false);
  const [termsTitle, setTermsTitle] = useState(
    '3Shape Design Service Terms and Conditions',
  );

  function handleOpenTerms(e, title) {
    e.preventDefault();
    setOpenTerms(true);
    setTermsTitle(title);
  }

  function handleCloseTerms() {
    setOpenTerms(false);
  }

  return authState === 'signIn' ? (
    <div className={Styles.signInLegalContainer}>
      <p className={Styles.signInLegalText}>
        {t('By clicking')}
        <i>
          <b>{t('Sign In')}</b>
        </i>
        , {t('you are agreeing to the 3Shape Design Service')}
        <a
          href="/legal/terms"
          onClick={(e) =>
            handleOpenTerms(e, '3Shape Design Service Terms and Conditions')
          }
        >
          Terms and Conditions
        </a>
        ,
        <a
          href="legal/privacy"
          onClick={(e) =>
            handleOpenTerms(e, '3Shape Design Service Privacy Policy')
          }
        >
          Privacy Policy
        </a>
        {t('and')}
        <a
          href="/legal/baa"
          onClick={(e) =>
            handleOpenTerms(
              e,
              '3Shape Design Service Business Associate Agreement',
            )
          }
        >
          BAA
        </a>
        .
      </p>
      <LegalModal
        modalOpen={openTerms}
        handleModalClose={handleCloseTerms}
        title={termsTitle}
      />
    </div>
  ) : null;
}

SignInLegal.propTypes = {
  authState: PropTypes.string,
};

export default SignInLegal;
