const toggleFavorite = (guideCategory, favorite) => ({
  ...guideCategory,
  favorite: !favorite,
});

const addGuideCode = (guideCategory, guideCodeId, name) => ({
  ...guideCategory,
  guideCodes: [
    ...guideCategory.guideCodes,
    { id: guideCodeId, name },
  ],
});

const updateGuideCodes = (guideCategory, guideCodeId, defaulted) => ({
  ...guideCategory,
  guideCodes: guideCategory.guideCodes.map((guideCode) => ({
    ...guideCode,
    default: guideCode.id === guideCodeId ? !defaulted : guideCode.default,
  })),
});

const updateGuideCategory = ({
  guideCategory,
  guideCategoryId,
  favorite,
  guideCodeId,
  name,
  defaulted,
}) => {
  if (guideCategory.id !== guideCategoryId) { return guideCategory; }

  if (favorite === false || favorite) { return toggleFavorite(guideCategory, favorite); }
  if (name) { return addGuideCode(guideCategory, guideCodeId, name); }

  return updateGuideCodes(guideCategory, guideCodeId, defaulted);
};

const updateGuideCategoryGroups = ({
  guideCategoryGroups,
  guideCategoryGroupId,
  guideCategoryId,
  favorite,
  defaulted,
  guideCodeId,
  name,
}) =>
  guideCategoryGroups.map((guideCategoryGroup) => {
    if (guideCategoryGroup.id !== guideCategoryGroupId) { return guideCategoryGroup; }

    return {
      ...guideCategoryGroup,
      guideCategories: guideCategoryGroup.guideCategories.map((guideCategory) =>
        updateGuideCategory({
          guideCategory,
          guideCategoryId,
          favorite,
          guideCodeId,
          name,
          defaulted,
        }),
      ),
    };
  });

const updateCache = ({
  existingLabLocationGuideCategoryGroupsRefs,
  designTypeId,
  guideCategoryGroupId,
  guideCategoryId,
  favorite,
  defaulted,
  guideCodeId,
  name,
}) =>
  existingLabLocationGuideCategoryGroupsRefs.map((item) => {
    if (item.designTypeId !== designTypeId) { return item; }

    return {
      ...item,
      guideCategoryGroups: updateGuideCategoryGroups({
        guideCategoryGroups: item.guideCategoryGroups,
        guideCategoryGroupId,
        guideCategoryId,
        favorite,
        defaulted,
        guideCodeId,
        name,
      }),
    };
  });

export default updateCache;
