import { graphql } from '@apollo/client/react/hoc';
import { sendingTempOrders, UpdateCache } from '@fullcontour/common';
import {
  CREATE_CUSTOMER_ORDER_BY_TEMP,
  GET_CUSTOMER_ORDERS,
  GET_ORDERS_COUNT_BY_STATES,
  GET_TEMP_ORDERS_COUNT,
} from '@fullcontour/shared-api';

const cacheUpdate = new UpdateCache();

const withCreate = graphql(CREATE_CUSTOMER_ORDER_BY_TEMP, {
  name: 'create',
  options: (props) => ({
    onCompleted: (data) => {
      const { createCustomerOrderByTemp } = data;
      if (createCustomerOrderByTemp.errorMessages.length) {
        props.setErrorMessages(createCustomerOrderByTemp.errorMessages);
      } else {
        props.clearErrorMessages();
      }
    },
    refetchQueries: [
      {
        query: GET_ORDERS_COUNT_BY_STATES,
        fetchPolicy: 'network-only',
      },
    ],
    update: (cache, { data: { createCustomerOrderByTemp } }) => {
      if (!createCustomerOrderByTemp.errorMessages.length) {
        cacheUpdate.addToCacheHandler({
          removeSubmitAll: props.removeSubmitAll,
          order: createCustomerOrderByTemp.order,
          total: props.tempOrders.length,
          toggleOverpanel: props.toggleOverpanel,
          cache,
          progressInfo: sendingTempOrders,
          singleClick: props.singleCase,
          pending: GET_TEMP_ORDERS_COUNT,
          query: GET_CUSTOMER_ORDERS,
        });

        UpdateCache.filterElementFromCache({
          cache,
          id: props.tempOrder.id,
          field: 'tempOrders',
        });
      }
    },
  }),
});

export default withCreate;
