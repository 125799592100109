import OrganizationCustomerBilling from '../../views/Organizations/OrganizationCustomerBilling/OrganizationCustomerBilling';
import OrganizationFormCustomer from '../../views/Organizations/OrganizationForms/OrganizationFormCustomer';
import OrganizationShowInner from '../../views/Organizations/OrganizationShow/OrganizationShowInner';

const routes = [
  {
    path: '/:root(organizations)/:action(edit)',
    component: OrganizationFormCustomer,
    sl: false,
    so: true,
  },
  {
    path: '/:root(organizations)/billing',
    component: OrganizationCustomerBilling,
    sl: false,
    so: true,
  },
  {
    path: '/:root(organizations)',
    component: OrganizationShowInner,
    sl: false,
    so: true,
  },
];

export default routes;
