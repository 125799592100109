import { useReactiveVar } from '@apollo/client';
import {
  OverpanelFilesContext,
  getHelpers,
  guideNav,
  guidedFlow,
  sendingOrders,
} from '@fullcontour/common';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';
import { guidedStepsNav, guidedStepsOverpanel } from './helpers/guidedSteps';

const activeTooltip = 'active-tooltip';
const activeTooltipText = 'active-tooltip-text';
const activeTooltipPseudo = 'active-tooltip-pseudo';

const joyrideStyles = {
  tooltipContainer: { textAlign: 'left' },
  buttonNext: { outline: 'none' },
  tooltipContent: { padding: '14px' },
  options: {
    zIndex: 100,
    arrowColor: '#03b5e3',
    backgroundColor: '#03b5e3',
    primaryColor: '#1b77c8',
    textColor: 'white',
    width: 300,
  },
};

function GuidedFlow() {
  const { t } = useTranslation('profile');
  const isGuidedFlow = useReactiveVar(guidedFlow);
  const submittingOrders = useReactiveVar(sendingOrders);
  const isGuideNav = useReactiveVar(guideNav);
  const { overpanelOpen, files, parsedCase } = useContext(
    OverpanelFilesContext,
  );

  const [guide, setGuide] = useState(false);
  const [steps, setSteps] = useState([]);
  const [runGuide, setRunGuide] = useState(false);

  const guidedStepsOverpanelTargets = useMemo(() => {
    const overpanelTargets = guidedStepsOverpanel(t).map((step) => step.target);
    const navTargets = guidedStepsNav(t).map((step) => step.target);
    return [...overpanelTargets, ...navTargets];
  }, [t]);

  const setGuideSteps = useCallback(() => {
    setSteps(overpanelOpen ? guidedStepsOverpanel(t) : guidedStepsNav(t));
    setRunGuide(files.length > 0);
  }, [overpanelOpen, t, files]);

  const clearGuidedClasses = useCallback(() => {
    for (const selector of guidedStepsOverpanelTargets) {
      const element = document.querySelector(selector);
      element?.classList.remove(
        activeTooltip,
        activeTooltipText,
        activeTooltipPseudo,
      );
    }
  }, [guidedStepsOverpanelTargets]);

  useEffect(() => {
    if (!overpanelOpen) {
      setGuide(false);
    } else {
      setTimeout(() => setGuide(true), 600);
    }
  }, [overpanelOpen]);

  useEffect(() => {
    setGuideSteps();
  }, [setGuideSteps]);

  useEffect(() => {
    if (!isGuidedFlow) {
      clearGuidedClasses();
    }
  }, [isGuidedFlow, clearGuidedClasses]);

  const handleJoyrideCallback = useCallback(
    ({ step, status, lifecycle, action, type }) => {
      const targetElement = step && document.querySelector(step.target);

      const addTooltipClass = (element) => {
        const isLabel = element.tagName === 'LABEL';
        const isParagraph = element.tagName === 'P';
        const classList = element.classList;

        if (isParagraph) {
          classList.add(activeTooltipText);
        } else if (isLabel) {
          classList.add(activeTooltipPseudo);
        } else if (!classList.contains(activeTooltip)) {
          classList.add(activeTooltip);
        }
      };

      if (status === 'finished' && isGuideNav) {
        guideNav(false);
      }

      if (['complete', 'ready'].includes(lifecycle)) {
        clearGuidedClasses();
      }

      if (
        lifecycle === 'tooltip' &&
        targetElement &&
        type !== 'error:target_not_found'
      ) {
        addTooltipClass(targetElement);
      }

      if (
        lifecycle === 'complete' &&
        action === 'next' &&
        step.target === 'designTypeId0'
      ) {
        document.querySelector('#teetharch')?.classList.add(activeTooltip);
      }
    },
    [clearGuidedClasses, isGuideNav],
  );

  const getHelpersHandler = (helpFuncs) => getHelpers({ helpFuncs });

  const run = overpanelOpen
    ? guide && isGuidedFlow && !submittingOrders.totalCases && parsedCase
    : isGuidedFlow && isGuideNav;

  return runGuide ? (
    <Joyride
      callback={handleJoyrideCallback}
      getHelpers={getHelpersHandler}
      continuous
      run={run}
      scrollToFirstStep={false}
      disableScrollParentFix
      disableScrolling
      hideBackButton
      disableOverlay
      steps={steps}
      locale={{ last: 'Finish' }}
      styles={joyrideStyles}
    />
  ) : null;
}

export default GuidedFlow;
