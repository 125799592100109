import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { OrthoObjViewer, PaginationContainer, Tabs } from '../../../../shared';

function ReviewModalTabs({ orderFiles, order }) {
  const [{ panes }, setState] = useState({ panes: [] });
  const { t } = useTranslation('orderactions');

  function filterType(type) {
    if (orderFiles?.length === 0) {
      return [];
    }

    return orderFiles.filter((file) => {
      if (
        type === 'obj_files' ||
        type === 'converted_treatment_review_files' ||
        type === 'treatment_review_files'
      ) {
        return file.fileType === type;
      }

      if (type === 'stl_files') {
        return (
          file.fileType === type && file.redesignCount === order.redesignCount
        );
      }

      if (type === 'quality_control_images') {
        return file.fileType === type && file.ver === file.latestVer;
      }

      if (type === 'pdfs') {
        return (
          file.fileType === 'attachments' &&
          file.ver === file.latestVer &&
          file.s3Key.endsWith('.pdf')
        );
      }

      if (type === 'movies') {
        return (
          file.fileType === 'attachments' &&
          file.ver === file.latestVer &&
          (file.s3Key.endsWith('.mov') || file.s3Key.endsWith('.mp4'))
        );
      }

      return null;
    });
  }

  function createPane(title, items, type, order, orderFiles) {
    return {
      menuItem: title,
      render: (idx) => (
        <Tabs.TabPanel tabId={title} idx={idx} key={`tabsTabPanel-${uuid()}`}>
          {type === 'movies' &&
            items.map((item) => (
              <video
                id={`item-${uuid()}`}
                key={`item-${uuid()}`}
                src={item.downloadUrl}
                preload="true"
                controls
                width="100%"
              >
                <track kind="captions" />
              </video>
            ))}

          {type === 'converted_treatment_review_files' && (
            <OrthoObjViewer
              key={`orthoObjViewer-${uuid()}`}
              order={{
                ...order,
                objFiles: filterType('obj_files'),
                convertedTreatmentReviewFiles: items,
                treatmentReviewFiles: filterType('treatment_review_files'),
                orderFiles,
              }}
            />
          )}

          {type === 'stl_files' && (
            <PaginationContainer
              key={`pagination-${uuid()}`}
              itemsPerPage={4}
              data={items}
              name={order?.name}
              hexOrderCode={order?.hexOrderCode}
            />
          )}

          {type !== 'movies' &&
            type !== 'obj_files' &&
            type !== 'converted_treatment_review_files' &&
            type !== 'stl_files' && (
              <div
                key={`qualityCtrlImages-${uuid()}`}
                style={
                  type === 'quality_control_images'
                    ? { display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }
                    : {}
                }
              >
                {items.map((item) => (
                  <div className="column" key={`item-${uuid()}`}>
                    {type === 'quality_control_images' && (
                      <div className="image">
                        <img
                          src={item.downloadUrl}
                          alt={item.originalFileName}
                        />
                      </div>
                    )}
                    {type === 'pdfs' && (
                      <a
                        href={item.downloadUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            zIndex: 1,
                            background: 'rgba(0,0,0,0.05)',
                          }}
                        />
                        <iframe
                          src={`${item.signedUrl}#toolbar=0`}
                          title={item.id}
                          height="100%"
                          width="100%"
                          style={{ zIndex: 2 }}
                        />
                      </a>
                    )}

                    <p style={{ lineHeight: '1.0em', wordBreak: 'break-all' }}>
                      <small style={{ fontSize: '65%' }}>
                        {item.originalFileName}
                      </small>
                    </p>
                  </div>
                ))}
              </div>
            )}
        </Tabs.TabPanel>
      ),
    };
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    let panes = [];
    const qcImages = filterType('quality_control_images');
    const stlFiles = filterType('stl_files');
    const movies = filterType('movies');
    const pdfs = filterType('pdfs');
    const objs = filterType('obj_files');
    const convertedTreatmentReviewFiles = filterType(
      'converted_treatment_review_files',
    );
    if (movies.length > 0) {
      panes = [...panes, createPane('Design Overview', movies, 'movies')];
    }

    if (objs.length > 0 || convertedTreatmentReviewFiles.length > 0) {
      panes = [
        ...panes,
        createPane(
          'Smile Summary',
          convertedTreatmentReviewFiles,
          'converted_treatment_review_files',
          order,
          orderFiles,
        ),
      ];
    }

    if (qcImages.length > 0) {
      panes = [
        ...panes,
        createPane(
          'Quality Control Images',
          qcImages,
          'quality_control_images',
        ),
      ];
    }

    if (stlFiles.length > 0) {
      panes = [...panes, createPane('STL Files', stlFiles, 'stl_files', order)];
    }

    if (pdfs.length > 0) {
      panes = [...panes, createPane('PDFs', pdfs, 'pdfs', order)];
    }

    setState((state) => ({ ...state, panes }));
  }, []);

  return panes.length > 0 ? (
    <Tabs key={`tab-${uuid()}`}>
      <Tabs.TabList key={`tabList-${uuid()}`}>
        {panes.map((pane, index) => (
          <Tabs.Tab idx={index} key={`tabsTab-${uuid()}`} tabId={pane.menuItem}>
            {t(pane.menuItem)}
          </Tabs.Tab>
        ))}
      </Tabs.TabList>

      {panes.map((pane, idx) => pane.render(idx))}
    </Tabs>
  ) : null;
}

ReviewModalTabs.propTypes = {
  orderFiles: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
};

export default memo(ReviewModalTabs);
