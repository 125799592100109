import { useApolloClient, useQuery } from '@apollo/client';
import {
  AddressFields,
  DynamicDropdown,
  FieldContainer,
  FileDropzone,
  FormGridWrap,
  FormSection,
  LanguageDropdown,
  LoadingOrError,
  PhoneField,
  WebsiteField,
  withCurrentUser,
  withFormWrap,
} from '@fullcontour/common';
import {
  GET_CUSTOMER_LOC,
  GET_TURNAROUND_DROPDOWN,
} from '@fullcontour/shared-api';
import { Field, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { initialValues, schema, update } from './helpers/Customer';

function LocationFormCustomer({
  initialValues = () => ({}),
  schema = () => ({}),
  getVariables = () => ({}),
  skipQuery = () => ({}),
  match = {},
  currentUser,
  ...rest
}) {
  const client = useApolloClient();
  const { data, loading, error } = useQuery(GET_CUSTOMER_LOC, {
    skip: skipQuery ? skipQuery() : false,
    variables: getVariables ? getVariables() : {},
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Location" />;
  }

  return (
    <Formik
      initialValues={initialValues({ data })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        const variables = {
          variables: {
            input: { input: values, customerLocationId: values.id },
          },
        };

        if (match.params.action && match.params.action !== 'new') {
          update({ variables, ...rest, client, currentUser });
        }
        setSubmitting(false);
      }}
    >
      <FormGridWrap match={match}>
        <FormSection title="Basic Info">
          <FieldContainer required name="name">
            <Field
              id="name"
              name="name"
              className="input"
              required
              placeholder="Organization Name"
            />
          </FieldContainer>
          <Field name="language" required component={LanguageDropdown} />
          <div className="columns">
            <div className="column">
              <WebsiteField />
            </div>
            <div className="column">
              <PhoneField />
            </div>
          </div>
          <Field
            name="logoUrl"
            component={FileDropzone}
            labelText="Upload A Logo"
          />
        </FormSection>
        <AddressFields />
        <FormSection title="Design Profile">
          <Field
            name="turnaroundTimeId"
            required
            component={DynamicDropdown}
            placeholder="Select a default Turnaround Time..."
            dynamicQuery={GET_TURNAROUND_DROPDOWN}
            dataName="turnaroundTimes"
            labelText="Default Turnaround Time"
          />
        </FormSection>
      </FormGridWrap>
    </Formik>
  );
}
LocationFormCustomer.propTypes = {
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  getVariables: PropTypes.func,
  skipQuery: PropTypes.func,
  match: PropTypes.object,
  update: PropTypes.func,
  create: PropTypes.func,
};
export default flowRight(
  withCurrentUser({}),
  withFormWrap({ initialValues, schema }),
)(LocationFormCustomer);
