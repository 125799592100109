import OrderShow from '../../views/Orders/OrderShow/OrderShow';
const routes = [
  {
    path: '/:root(orders)/:type(details)/:id',
    component: OrderShow,
    exact: false,
  },
];

export default routes;
