import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  GET_CUSTOMER_LOC,
  GET_CUSTOMER_LOC_LOGO,
  GET_ORDER_MESSAGES,
  REQUEST_S3_SIGN,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'yet-another-react-lightbox';
import { useParams } from 'react-router';
import { timestampFormatter } from '../../../../../transforms';
import { CurrentUserContext, LoadingOrError } from '../../../../shared';
import { FilesShow } from '../OrderShowFiles/FilesShow';
import OrderMessageImage from './OrderMessageImage';
import OrderMessageInner from './OrderMessageInner';
import OrderShowMessageFormCustomer from './OrderShowMessageFormCustomer';
import OrderShowMessageUserImg from './OrderShowMessageUserImg';

function OrderShowMessagesCustomer({
  locationId = null,
  noWrapper = false,
  showFrom = false,
}) {
  const { id } = useParams();
  const client = useApolloClient();
  const { currentUser } = useContext(CurrentUserContext);

  const { data: cData } = useQuery(
    import.meta.env.VITE_APP_NAME !== 'manuf_portal'
      ? GET_CUSTOMER_LOC
      : GET_CUSTOMER_LOC_LOGO,
    {
      variables: {
        id: currentUser.locationId || locationId,
      },
    },
  );

  const { t } = useTranslation('caseActivity');

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [sendDisable, setSendDisable] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentMessagePhotoModal, setCurrentMessagePhotoModal] = useState('');
  const [messageImages, setMessageImages] = useState({});
  const isCustomerPortal = import.meta.env.VITE_APP_NAME === 'customer_portal';

  const { loading, error, data, refetch } = useQuery(GET_ORDER_MESSAGES, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const [signS3] = useMutation(REQUEST_S3_SIGN);

  const messagesWrapper = useRef();

  const scrollToMyRef = () => {
    if (messagesWrapper.current) {
      messagesWrapper.current.scrollTo(0, messagesWrapper.current.scrollHeight);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToMyRef();
    }, 1000);
  }, [data]);

  const imagesSection = useCallback((message) => {
    const imagesArray = message.images.map((item) => (
      <OrderMessageImage
        message={item}
        messageId={message.id}
        setPreview={setPreviewImage}
        multipleFiles
        setCurrentMessagePhotoModal={setCurrentMessagePhotoModal}
        setIsOpen={setIsOpen}
        setPhotoIndex={setPhotoIndex}
        setMessageImages={setMessageImages}
        key={item.id}
      />
    ));

    if (message.imageUrl && message.images.length > 0) {
      return (
        <div>
          <OrderMessageImage message={message} setPreview={setPreviewImage} />
          {imagesArray}
        </div>
      );
    }

    if (message.imageUrl) {
      return (
        <OrderMessageImage message={message} setPreview={setPreviewImage} />
      );
    }
    return imagesArray;
  }, []);

  const innerMessager = (
    <>
      <div>
        <div
          ref={messagesWrapper}
          style={{
            height: '65vh',
            position: 'relative',
            overflowY: 'auto',
            overflowX: 'hidden',
            ...(noWrapper
              ? { border: 'none', marginBottom: 0, boxShadow: 'none' }
              : {}),
          }}
        >
          {loading || error ? (
            <LoadingOrError
              error={error}
              loading={loading}
              title="Case Messages"
            />
          ) : data.orderMessages.length > 0 ? (
            [...data.orderMessages]
              .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
              .map((message, idx, arrayOrigin) => {
                const systemMessage =
                  message.systemMessage ||
                  message.orderMessageableType !== 'User';
                if (
                  idx &&
                  !(
                    arrayOrigin[idx - 1].systemMessage !== message.systemMessage
                  ) &&
                  (new Date(message.createdAt).getTime() -
                    new Date(arrayOrigin[idx - 1].createdAt).getTime()) /
                    1000 <
                    60 * 15
                ) {
                  return (
                    <div
                      key={message.id}
                      style={{
                        display: 'flex',
                        margin: '0 80px',
                        flexDirection: systemMessage ? 'row' : 'row-reverse',
                      }}
                    >
                      <OrderMessageInner
                        single
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: systemMessage ? 'flex-start' : 'flex-end',
                        }}
                        key={message.id}
                        message={message}
                        imagesSection={imagesSection}
                      />
                    </div>
                  );
                }

                return (
                  <Fragment key={message.id}>
                    <div
                      className="panel-block"
                      style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div style={{ alignSelf: 'center' }}>
                        <p className="is-italic has-text-grey-dark is-size-7 has-text-centered">
                          {timestampFormatter(message.createdAt)}
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: systemMessage ? 'row' : 'row-reverse',
                        }}
                      >
                        <div
                          style={{
                            width: '48px',
                            height: '48px',
                            display: 'flex',
                            margin: systemMessage
                              ? '0 20px 0 0 '
                              : '0 0 0 20px',
                          }}
                        >
                          {cData && (
                            <OrderShowMessageUserImg
                              systemMessage={message.systemMessage}
                              messageableType={message.orderMessageableType}
                              logoUrl={cData.customerLocation.logoUrl}
                              alt={cData.customerLocation.name}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: systemMessage
                              ? 'flex-start'
                              : 'flex-end',
                          }}
                        >
                          <div>{message.userName}</div>
                          <OrderMessageInner
                            style={
                              !systemMessage
                                ? {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                  }
                                : {}
                            }
                            message={message}
                            imagesSection={imagesSection}
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })
          ) : (
            <h6 className="title is-6 has-text-centered">
              {t('No Messages have been posted yet')}
            </h6>
          )}
        </div>
      </div>
      {(isCustomerPortal || showFrom) && data && data.order.id && (
        <div
          className="panel-block"
          style={{
            padding: 0,
            ...(noWrapper
              ? {
                  border: 'none',
                  margin: '0px -25px 0px',
                  borderTop: '1px solid rgb(221, 221, 221)',
                }
              : {}),
          }}
        >
          <OrderShowMessageFormCustomer
            orderId={data?.order.id}
            order={data?.orde}
            id={id}
            client={client}
            orderState={data?.order.state}
            signS3={signS3}
            setUploadPercentage={setUploadPercentage}
            uploadPercentage={uploadPercentage}
            setSendDisable={setSendDisable}
            sendDisable={sendDisable}
            refetch={refetch}
          />
        </div>
      )}
      <Lightbox
        styles={{ container: { backgroundColor: 'rgba(80, 80, 80, 0.87)' } }}
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={
          messageImages[currentMessagePhotoModal] && [
            ...messageImages[currentMessagePhotoModal].map((img) => ({
              src: img,
            })),
          ]
        }
        index={photoIndex}
      />
    </>
  );

  return noWrapper ? (
    innerMessager
  ) : (
    <div className="columns">
      <div className="column is-6">
        <div className="panel">
          <p className="panel-heading">{t('Case Activity and Messages')}</p>
          {innerMessager}
        </div>
      </div>
      <div className="column" style={{ verticalAlign: 'top' }}>
        {previewImage && (
          <FilesShow
            selectedFile={previewImage}
            loadingFileView={false}
            order={data.order}
          />
        )}
      </div>
    </div>
  );
}

OrderShowMessagesCustomer.propTypes = {
  locationId: PropTypes.string,
  noWrapper: PropTypes.bool,
  showFrom: PropTypes.bool,
};

export default OrderShowMessagesCustomer;
