export default function DocumentSvg() {
  return (
    <svg height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>doc svg</title>
      <path
        d="M2.69478 39.363H1.95059C1.43484 39.3638 0.939838 39.16 0.574151 38.7963C0.208464 38.4326 0.00198174 37.9387 0 37.4229V1.93879C0.00214665 1.4232 0.208692 0.929472 0.574379 0.566007C0.940067 0.202542 1.435 -0.000992036 1.95059 3.63593e-06H28.8364C29.352 -0.000992036 29.8469 0.202542 30.2126 0.566007C30.5783 0.929472 30.7848 1.4232 30.787 1.93879V6.37894C30.787 6.54174 30.7223 6.69787 30.6072 6.81298C30.4921 6.9281 30.336 6.99277 30.1732 6.99277C30.0104 6.99277 29.8542 6.9281 29.7391 6.81298C29.624 6.69787 29.5593 6.54174 29.5593 6.37894V1.93879C29.5577 1.74875 29.4807 1.56711 29.3453 1.43378C29.2099 1.30045 29.027 1.22632 28.837 1.22765H1.95128C1.76123 1.22632 1.57843 1.30045 1.44299 1.43378C1.30756 1.56711 1.23051 1.74875 1.22887 1.93879V37.4229C1.23035 37.6131 1.30725 37.795 1.44269 37.9285C1.57812 38.0621 1.76107 38.1365 1.95128 38.1354H2.69546C2.85834 38.1354 3.01448 38.2 3.12966 38.3152C3.24483 38.4304 3.30959 38.5866 3.30959 38.7495C3.30959 38.9124 3.24483 39.0686 3.12966 39.1838C3.01448 39.2989 2.85834 39.3636 2.69546 39.3636L2.69478 39.363Z"
        fill="#4C4D52"
      />
      <path
        d="M34.7671 13.649L25.306 4.4791C24.9469 4.12996 24.4656 3.935 23.9648 3.93579H6.62322C6.10968 3.93679 5.61752 4.14157 5.25492 4.50522C4.89232 4.86888 4.68896 5.36165 4.68945 5.87519V41.1722C4.68896 41.6858 4.89227 42.1786 5.25485 42.5424C5.61743 42.9061 6.10962 43.1111 6.62322 43.1122H33.4247C33.9383 43.1109 34.4303 42.9059 34.7929 42.5422C35.1554 42.1785 35.3588 41.6858 35.3585 41.1722V15.045C35.358 14.7846 35.3055 14.5269 35.2039 14.2872C35.1023 14.0474 34.9538 13.8305 34.7671 13.649V13.649ZM33.2158 13.8548H25.7595C25.5708 13.8547 25.3898 13.7796 25.2564 13.6462C25.123 13.5127 25.0479 13.3318 25.0478 13.1431V5.93756L33.2158 13.8548ZM33.4247 41.8834H6.62322C6.43543 41.8821 6.25579 41.8063 6.12377 41.6727C5.99174 41.5392 5.9181 41.3588 5.91893 41.171V5.87519C5.91826 5.68751 5.99199 5.50724 6.12399 5.37384C6.256 5.24043 6.43554 5.16476 6.62322 5.16344H23.8226V13.1443C23.8231 13.6587 24.0276 14.1518 24.3914 14.5156C24.7551 14.8793 25.2483 15.0838 25.7626 15.0843H34.1296V41.1728C34.13 41.3608 34.0556 41.5413 33.923 41.6745C33.7903 41.8077 33.6102 41.8829 33.4222 41.8834H33.4247Z"
        fill="#4C4D52"
      />
      <path
        d="M18.907 13.5495H10.8407C10.6778 13.5495 10.5216 13.4849 10.4064 13.3697C10.2913 13.2545 10.2266 13.0983 10.2266 12.9354C10.2266 12.7725 10.2913 12.6163 10.4064 12.5011C10.5216 12.386 10.6778 12.3213 10.8407 12.3213H18.907C19.0699 12.3213 19.2261 12.386 19.3412 12.5011C19.4564 12.6163 19.5211 12.7725 19.5211 12.9354C19.5211 13.0983 19.4564 13.2545 19.3412 13.3697C19.2261 13.4849 19.0699 13.5495 18.907 13.5495V13.5495Z"
        fill="#C2003E"
      />
      <path
        d="M29.182 19.296H10.8423C10.6795 19.296 10.5234 19.2313 10.4083 19.1162C10.2932 19.0011 10.2285 18.845 10.2285 18.6822C10.2285 18.5194 10.2932 18.3633 10.4083 18.2481C10.5234 18.133 10.6795 18.0684 10.8423 18.0684H29.182C29.3448 18.0684 29.5009 18.133 29.616 18.2481C29.7311 18.3633 29.7958 18.5194 29.7958 18.6822C29.7958 18.845 29.7311 19.0011 29.616 19.1162C29.5009 19.2313 29.3448 19.296 29.182 19.296V19.296Z"
        fill="#C2003E"
      />
      <path
        d="M29.237 25.0442H10.8973C10.7345 25.0442 10.5782 24.9795 10.4631 24.8643C10.3479 24.7491 10.2832 24.5929 10.2832 24.43C10.2832 24.2672 10.3479 24.111 10.4631 23.9959C10.5782 23.8807 10.7345 23.8159 10.8973 23.8159H29.237C29.3999 23.8159 29.5561 23.8807 29.6712 23.9959C29.7864 24.111 29.8512 24.2672 29.8512 24.43C29.8512 24.5929 29.7864 24.7491 29.6712 24.8643C29.5561 24.9795 29.3999 25.0442 29.237 25.0442V25.0442Z"
        fill="#C2003E"
      />
      <path
        d="M29.2368 30.79H10.8971C10.8149 30.7926 10.7329 30.7788 10.6562 30.7491C10.5795 30.7195 10.5095 30.6747 10.4504 30.6175C10.3913 30.5602 10.3443 30.4917 10.3123 30.416C10.2802 30.3402 10.2637 30.2588 10.2637 30.1766C10.2637 30.0943 10.2802 30.0129 10.3123 29.9372C10.3443 29.8614 10.3913 29.7929 10.4504 29.7357C10.5095 29.6785 10.5795 29.6337 10.6562 29.604C10.7329 29.5744 10.8149 29.5604 10.8971 29.5631H29.2368C29.319 29.5604 29.4008 29.5744 29.4776 29.604C29.5543 29.6337 29.6243 29.6785 29.6834 29.7357C29.7425 29.7929 29.7894 29.8614 29.8215 29.9372C29.8536 30.0129 29.8701 30.0943 29.8701 30.1766C29.8701 30.2588 29.8536 30.3402 29.8215 30.416C29.7894 30.4917 29.7425 30.5602 29.6834 30.6175C29.6243 30.6747 29.5543 30.7195 29.4776 30.7491C29.4008 30.7788 29.319 30.7926 29.2368 30.79V30.79Z"
        fill="#C2003E"
      />
      <path
        d="M29.2368 36.5364H10.8971C10.8149 36.5391 10.7329 36.5251 10.6562 36.4955C10.5795 36.4658 10.5095 36.421 10.4504 36.3638C10.3913 36.3066 10.3443 36.2381 10.3123 36.1623C10.2802 36.0866 10.2637 36.0052 10.2637 35.9229C10.2637 35.8407 10.2802 35.7592 10.3123 35.6835C10.3443 35.6078 10.3913 35.5392 10.4504 35.482C10.5095 35.4248 10.5795 35.38 10.6562 35.3504C10.7329 35.3207 10.8149 35.3068 10.8971 35.3094H29.2368C29.319 35.3068 29.4008 35.3207 29.4776 35.3504C29.5543 35.38 29.6243 35.4248 29.6834 35.482C29.7425 35.5392 29.7894 35.6078 29.8215 35.6835C29.8536 35.7592 29.8701 35.8407 29.8701 35.9229C29.8701 36.0052 29.8536 36.0866 29.8215 36.1623C29.7894 36.2381 29.7425 36.3066 29.6834 36.3638C29.6243 36.421 29.5543 36.4658 29.4776 36.4955C29.4008 36.5251 29.319 36.5391 29.2368 36.5364V36.5364Z"
        fill="#C2003E"
      />
    </svg>
  );
}
