import {
  FormGridWrap,
  FormSection,
  LocationDependantDropdown,
  MultiSelectById,
  RoleSelect,
  UserFormCommonFields,
} from '@fullcontour/common';
import {
  GET_CUSTOMER_ORG_CUSTOMER_LOCS,
  GET_CUSTOMER_ORG_LOCS,
  GET_DESIGN_TYPES_BY_PRICE_LISTS_DROPDOWN,
} from '@fullcontour/shared-api';
import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';

function UserFormCustomerInner({
  data: { customerUser } = {},
  setFieldValue = () => ({}),
  action = null,
  currentUser = {},
  values = {},
  match = {},
}) {
  const [selectedRole, setSelectedRole] = useState(customerUser?.role || null);

  const changeSelectedRole = (obj) => {
    setSelectedRole(obj);
    if (obj?.scopeToLocation === false) {
      setFieldValue('locationId', '');
    }
  };
  return (
    <FormGridWrap match={match}>
      <UserFormCommonFields />
      <FormSection title="Assignment">
        <Field
          name="roleId"
          required
          disabled={action === 'edit'}
          component={RoleSelect}
          setrole={changeSelectedRole}
          modelscope="User"
        />
        {selectedRole?.scopeToLocation &&
          currentUser?.scopeToLocation === false && (
            <Field
              name="locationId"
              required
              component={LocationDependantDropdown}
              placeholder="Select a Location"
              parentOrgId={values.organizationId}
              orgQuery={GET_CUSTOMER_ORG_LOCS}
              type="customer"
              disabled={action === 'edit'}
              labelText="Location"
            />
          )}
        {selectedRole?.scopeToLocation &&
          currentUser?.scopeToLocation === true &&
          values.organizationId &&
          selectedRole?.assignmentLocations === true && (
            <FieldArray name="customerLocationIds" required>
              {({ ...fieldArrayProps }) => (
                <MultiSelectById
                  {...fieldArrayProps}
                  query={GET_CUSTOMER_ORG_CUSTOMER_LOCS}
                  variables={{ id: values.organizationId }}
                  label="Assigned Customer Locations"
                  cellLabel="Locations"
                  dataName="customerOrganization"
                  nestedDataName="locations"
                />
              )}
            </FieldArray>
          )}
        {selectedRole?.assignmentDesignTypes === true &&
          values.organizationId &&
          ((selectedRole?.scopeToLocation && values.locationId) ||
            (!selectedRole?.scopeToLocation &&
              values.customerLocationIds.length > 0)) && (
            <FieldArray name="designTypeIds" required>
              {({ ...fieldArrayProps }) => (
                <MultiSelectById
                  {...fieldArrayProps}
                  query={GET_DESIGN_TYPES_BY_PRICE_LISTS_DROPDOWN}
                  variables={{
                    customerLocationIds: selectedRole?.scopeToLocation
                      ? [values.locationId]
                      : values.customerLocationIds,
                  }}
                  label="Assigned Design Types"
                  cellLabel="Design Types"
                  dataName="designTypesByPriceLists"
                />
              )}
            </FieldArray>
          )}
      </FormSection>
    </FormGridWrap>
  );
}
UserFormCustomerInner.propTypes = {
  data: PropTypes.object,
  setFieldValue: PropTypes.func,
  action: PropTypes.string,
  match: PropTypes.object,
  currentUser: PropTypes.object,
  values: PropTypes.object,
};

export default UserFormCustomerInner;
