import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { FileDropzoneOrderFile } from '../../../../../shared';

function FilesUpload({
  selectedFile = null,
  selectedFolder = null,
  order,
  refetch,
}) {
  const app = import.meta.env.VITE_APP_NAME;

  const getFileType = useCallback(() => {
    if (selectedFile) {
      return selectedFile.fileType;
    }

    return selectedFolder.fileType;
  }, [selectedFile, selectedFolder]);

  const [fileType, setFileType] = useState(getFileType());
  const [uploading, setUploading] = useState(false);

  const [uploadPercentage, setUploadPercentage] = useState(0);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const updatedFileType = getFileType();
    setFileType(updatedFileType);
  }, [getFileType, selectedFile, selectedFolder]);

  const setAcceptTypes = () => {
    if (fileType === 'quality_control_images') {
      return ['image/jpg', 'image/jpeg', 'image/png'];
    }

    if (fileType === 'stl_files' || fileType === 'visualization_output') {
      return '.stl';
    }

    if (fileType === 'attachments') {
      return [
        'application/pdf',
        'video/quicktime',
        'video/mp4',
        'image/jpg',
        'image/jpeg',
        'image/png',
        '.zip',
        '.cam5zip',
        '.json',
      ];
    }

    if (fileType === 'converted_treatment_review_files') {
      return ['.obj'];
    }

    if (fileType === 'obj_files') {
      return ['.obj', '.mtl'];
    }

    return ['.zip', '.order', '.lab'];
  };

  return (app === 'customer_portal' &&
    fileType !== 'stl_files' &&
    fileType !== 'visualization_output') ||
    app === 'designer_portal' ||
    app === 'admin_portal' ||
    app === 'manuf_portal' ? (
    <div style={{ height: '100%' }}>
      <FileDropzoneOrderFile
        updateUploadProgress={setUploadPercentage}
        setUploading={() => setUploading(true)}
        setUploadingOff={() => setUploading(false)}
        uploading={uploading}
        orderFileType={fileType}
        accept={setAcceptTypes()}
        order={order}
        multiple={
          fileType === 'quality_control_images' ||
          fileType === 'stl_files' ||
          fileType === 'attachments' ||
          fileType === 'obj_files' ||
          fileType === 'converted_treatment_review_files'
        }
        refetch={refetch}
      />
      {uploading && (
        <progress
          value={uploadPercentage}
          max="100"
          className="progress is-small is-success"
        >
          Uploading
        </progress>
      )}
    </div>
  ) : null;
}

FilesUpload.propTypes = {
  selectedFile: PropTypes.object,
  selectedFolder: PropTypes.object,
  order: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default FilesUpload;
