import { useApolloClient, useQuery } from '@apollo/client';
import {
  LoadingOrError,
  withCurrentUser,
  withFormWrap,
} from '@fullcontour/common';
import { GET_CUSTOMER_USER } from '@fullcontour/shared-api';
import { Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import UserFormCustomerInner from './UserFormCustomerInner';
import { create, initialValues, schema, update } from './helpers/Customer';
import { getVariables, skipQuery } from './helpers/queryOptions';
function UserFormCustomer({
  initialValues = () => ({}),
  schema = () => ({}),
  match = {},
  separateId = null,
  currentUser = {},
  ...rest
}) {
  const client = useApolloClient();
  const { action } = useParams();
  const { data, loading, error } = useQuery(GET_CUSTOMER_USER, {
    skip: skipQuery ? skipQuery({ match }) : false,
    variables: getVariables ? getVariables({ match }) : {},
    fetchPolicy: 'cache-and-network',
  });

  function handleSubmit(values) {
    const variables = {
      variables: { input: { input: values, clientMutationId: uuidv4() } },
    };

    if (separateId && match.params.action !== 'new') {
      variables.variables.input.id = values.id;
    }

    if (separateId) {
      variables.variables.input.input.id = undefined;
    }

    if (match.params.action && match.params.action === 'new') {
      create({ variables, ...rest, client });
    } else {
      update({ variables, ...rest, client });
    }
  }
  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="User" />;
  }
  return (
    <Formik
      initialValues={initialValues({ data, currentUser })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <UserFormCustomerInner
          {...{ values, currentUser, action, data, setFieldValue, match }}
        />
      )}
    </Formik>
  );
}

UserFormCustomer.propTypes = {
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  match: PropTypes.object,
  update: PropTypes.func,
  create: PropTypes.func,
  separateId: PropTypes.string,
  currentUser: PropTypes.object,
};

export default flowRight(
  withCurrentUser({}),
  withFormWrap({ initialValues, schema }),
)(UserFormCustomer);
