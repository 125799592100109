import { useMutation } from '@apollo/client';
import { CurrentUserContext, handleTryCatch } from '@fullcontour/common';
import {
  CREATE_GUIDE_CATEGORY_USER,
  DESTROY_GUIDE_CATEGORY_USER,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useGuideCodeCardContext } from '../context/GuideCodeCardContext';
import { actionButtonsPermissions } from '../helpers';
import updateCache from '../helpers/updateCache';

function ButtonFavourites({ form: { setValues } }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { data } = useGuideCodeCardContext();
  const { guideCategoryGroupId, designType, category } = data;
  const { favorite } = category;
  const [addOrRemoveFavourites, { loading }] = useMutation(
    favorite ? DESTROY_GUIDE_CATEGORY_USER : CREATE_GUIDE_CATEGORY_USER,
    {
      update: async (cache) => {
        cache.modify({
          fields: {
            labLocationGuideCategoryGroups(
              existingLabLocationGuideCategoryGroupsRefs = [],
            ) {
              return updateCache({
                guideCategoryGroupId,
                guideCategoryId: category.id,
                existingLabLocationGuideCategoryGroupsRefs,
                favorite,
                designTypeId: designType?.designTypeId,
              });
            },
          },
        });
        setValues((state) => ({ ...state }));
      },
    },
  );
  const addOrRemoveFavouritesHandler = handleTryCatch(
    async () => {
      const variables = {
        input: {
          input: {
            guideCategoryId: category.id,
          },
        },
      };

      await addOrRemoveFavourites({ variables });
    },
    {
      successText: `Category was successfully ${
        !favorite ? 'added to' : 'removed from'
      } favorites!`,
    },
  );

  return actionButtonsPermissions(currentUser.roleLevel, {
    customer_portal: [40, 50],
  }) ? (
    <button
      type="button"
      className={`button is-rounded has-background-white mr-1 p-0 ${
        loading && 'is-loading'
      }`}
      onClick={() => addOrRemoveFavouritesHandler()}
    >
      <span className="icon">
        <i
          className={`bx ${favorite ? 'bxs-star has-text-primary' : 'bx-star'}`}
        />
      </span>
    </button>
  ) : null;
}
ButtonFavourites.propTypes = {
  form: PropTypes.object.isRequired,
};
export default ButtonFavourites;
