import { useQuery } from '@apollo/client';
import { CurrentUserContext } from '@fullcontour/common';
import { GET_SHOW_HELP_CENTER } from '@fullcontour/shared-api';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function HelpCenter() {
  const { currentUser } = useContext(CurrentUserContext);
  const { t } = useTranslation('navigation');

  const { data } = useQuery(GET_SHOW_HELP_CENTER, {
    variables: { id: currentUser.locationId },
    skip: !currentUser?.scopeToLocation,
  });

  return data?.showHelpCenter
    ? currentUser?.scopeToLocation && (
        <NavLink to="/getting-started" className="navbar-item">
          {t('Getting Started')}
        </NavLink>
      )
    : null;
}

export default HelpCenter;
