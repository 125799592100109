import { useQuery } from '@apollo/client';
import { LoadingOrError, ShowBodySection } from '@fullcontour/common';
import { GET_CUSTOMER_ORG } from '@fullcontour/shared-api';
import {
  ShowContainer,
  ShowHeaderLeftContent,
} from '../../../shared/ShowElements';
import LocationsListDataTable from '../../Locations/LocationsList/LocationsListDataTable';
import UsersListDataTable from '../../Users/UsersList/UsersListDataTable';
import TotalLocations from './TotalLocations';

function OrganizationShowInner() {
  const { error, loading, data } = useQuery(GET_CUSTOMER_ORG, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error) {
    return (
      <LoadingOrError error={error} loading={loading} title="Organization" />
    );
  }

  const { customerOrganization: organization } = data;
  let address = {};
  if (organization) {
    address = organization.address;
  }

  return organization ? (
    <ShowContainer
      logoUrl={organization.logoUrl}
      title={organization.name}
      editLink="/organizations/edit"
      leftContent={
        <ShowHeaderLeftContent
          address={address}
          phone={organization.phone}
          website={organization.website}
          geoData={organization.geoData}
        />
      }
      createdAt={organization.createdAt}
      updatedAt={organization.updatedAt}
      subHeader={<TotalLocations type="customer" organization={organization} />}
    >
      <>
        <ShowBodySection title="Locations">
          <LocationsListDataTable
            shortContainer
            organizationId={organization.id}
          />
        </ShowBodySection>
        <ShowBodySection title="Users">
          <UsersListDataTable shortContainer organizationId={organization.id} />
        </ShowBodySection>
      </>
    </ShowContainer>
  ) : null;
}

export default OrganizationShowInner;
