import { useQuery } from '@apollo/client';
import { GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS } from '@fullcontour/shared-api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { BaseLoader, Tabs } from '../../../shared';

import LocationDesignGuide from './LocationDesignGuide/LocationDesignGuide';
import LocationDesignGuideFavorites from './LocationDesignGuideFavorites/LocationDesignGuideFavorites';
import LocationDesignGuideGroups from './LocationDesignGuideGroups/LocationDesignGuideGroups';
import LocationDesignProfilePreferences from './LocationDesignProfilePreferences/LocationDesignProfilePreferences';

import './LocatinDesignProfile.scss';

function LocationDesignProfile() {
  const { slug } = useParams();

  const { t } = useTranslation('designProfile');

  const { data, loading } = useQuery(GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS, {
    fetchPolicy: 'network-only',
    variables: { slug },
  });

  if (loading) {
    return <BaseLoader loading />;
  }

  const showFavorites = import.meta.env.VITE_APP_NAME === 'customer_portal';

  return (
    <Tabs defaultTabId="designGuide">
      <Tabs.TabList>
        <Tabs.Tab tabId="designGuide">{t('Design Guide Codes')}</Tabs.Tab>
        {showFavorites && (
          <Tabs.Tab tabId="favorites">{t('Favorites')}</Tabs.Tab>
        )}
        <Tabs.Tab tabId="designGuideGroups">
          {t('Design Guide Groups')}
        </Tabs.Tab>
        <Tabs.Tab tabId="preferences">{t('Design Preferences')}</Tabs.Tab>
      </Tabs.TabList>

      <LocationDesignGuide />
      {showFavorites && <LocationDesignGuideFavorites data={data} />}
      <LocationDesignGuideGroups />
      <LocationDesignProfilePreferences />
    </Tabs>
  );
}

export default LocationDesignProfile;
