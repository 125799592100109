import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BoxIcon from '../../Icons/BoxIcon';

function SubmitButton({
  title = null,
  fileUploading = false,
  className = null,
  match = {},
}) {
  const { isSubmitting } = useFormikContext();
  const {
    params: { action = null } = {},
  } = match;
  const { t } = useTranslation('common');

  const [payload] = useState(() => {
    if (title) {
      return { title, icon: null };
    }

    if (action === 'edit') {
      return { title: 'Update' };
    }

    if (action === 'new') {
      return { title: 'Create' };
    }

    return { title: 'Submit' };
  });

  return (
    <button
      type="submit"
      id="submitBut"
      className={`button is-primary mb-0 ml-3 ${className || ''}${
        isSubmitting || fileUploading ? 'is-loading' : ''
      }`}
      disabled={isSubmitting || fileUploading}
    >
      {action === 'new' ? (
        <BoxIcon name="bx-plus" className="FormSubmitButtonIcon" />
      ) : null}
      {action === 'edit' ? (
        <BoxIcon name="bx-edit" className="FormSubmitButtonIcon" />
      ) : null}
      {payload.title && <span>{t(payload.title)}</span>}
    </button>
  );
}

SubmitButton.propTypes = {
  fileUploading: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.any,
  match: PropTypes.object,
};

export default memo(SubmitButton);
