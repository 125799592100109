import './OverpanelInformation.css';

const panels = {
  shapeExocadSirona: (designType, t) => [
    ...([
      'abutment',
      'allOnXHybrid',
      'allOnXPreppedHybrid',
      'bridge',
      'bridgeWithGingiva',
      'coping',
      'crown',
      'customizedImpressionTray',
      'denture',
      'implantBridgeWithGingiva',
      'inlay',
      'lucid',
      'model',
      'onlay',
      'postCoreWithCrown',
      'postAndCore',
      'screwRetainedFrame',
      'veneer',
      'temporaryPontic',
      'temporaryCrown',
      'denture',
      'implantBridgeWithGingiva',
      'lucid',
      'nightguard',
      'veneer',
      'temporaryPontic',
      'splint',
      'RPD',
    ].includes(designType)
      ? [
          {
            key: '3shapeSoftware',
            title: '3Shape',
            content: (
              <div>
                <ol className="is-size-7 mb-2 ml-2">
                  <li>
                    {t(
                      'Right click the case(s) in Dental Manager, select “Advanced” in the context menu, then select “Export”',
                    )}
                  </li>
                  <div className="mb-3" />
                  <li>
                    {t(
                      `In the "Select export option" menu choose "Export complete 3shape case" and choose the "File" option`,
                    )}
                  </li>
                  <div className="mb-3" />
                  <li>
                    {t(
                      `Upload the zipped file by drag'n'dropping on the area to the right or click browse and upload the file`,
                    )}
                  </li>
                </ol>
              </div>
            ),
          },
        ]
      : []),
    ...([
      'abutment',
      'bridge',
      'bridgeWithGingiva',
      'coping',
      'crown',
      'implantBridgeWithGingiva',
      'inlay',
      'lucid',
      'model',
      'onlay',
      'screwRetainedFrame',
      'veneer',
      'temporaryPontic',
      'temporaryCrown',
      'implantBridgeWithGingiva',
      'lucid',
      'veneer',
      'temporaryPontic',
    ].includes(designType)
      ? [
          {
            key: 'exocad',
            title: 'Exocad',
            content: (
              <div>
                <ol className="is-size-7 mb-2 ml-2">
                  <li>{t('Open the Cad-Data folder')}</li>
                  <div className="mb-3" />
                  <li>
                    {t(`Right click the case(s), select “Send to” in the context
                    menu, then select “Compressed (zipped) folder”`)}
                  </li>
                  <div className="mb-3" />
                  <li>
                    {t(`Upload the files by drag'n'dropping on the area to the right
                    or click browse and upload the files`)}
                  </li>
                </ol>
              </div>
            ),
          },
        ]
      : []),

    ...([
      'bridge',
      'bridgeWithGingiva',
      'coping',
      'crown',
      'inlay',
      'model',
      'onlay',
      'veneer',
      'temporaryPontic',
      'temporaryCrown',
      'veneer',
      'temporaryPontic',
      'sironaSTLConversion',
    ].includes(designType)
      ? [
          {
            key: 'sirona',
            title: 'Sirona',
            content: (
              <div>
                <ol className="is-size-7 mb-5 ml-5">
                  <li>{t('Click export and select the .lab file type')}</li>
                  <div className="mb-3" />
                  <li>
                    {t(`Upload the files by drag'n'dropping on the area to
                    the right or click browse and upload the files`)}
                  </li>
                </ol>
              </div>
            ),
          },
        ]
      : []),
  ],
};

export default panels;
