import JsPDF from 'jspdf';
import 'jspdf-autotable';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fdiMapping,
  palmerNotationDisplay,
} from '../../../../../../transforms';
import imageTeeth from '../assets/TeethChartImage';
import {
  getBase64ImageUrl,
  headerIPR,
  pdfControlImagesNamings,
} from '../helpers';

import bodyTableDataGenerate from '../../../utils/bodyTableDataGenerate';
import headerTableDataGenerate from '../../../utils/headerTableDataGenerate';
import '../assets/NotoSans-Regular-normal';

const findFinalStl = (item, position) => {
  const itemName = item.originalFileName;
  return (
    !(
      itemName.includes('OC_') ||
      itemName.includes('Subsetup') ||
      itemName.includes('template') ||
      itemName.includes('attachments')
    ) &&
    (itemName.includes(position[0]) || itemName.includes(position[1]))
  );
};

const drawPalmer = (tooth, doc, data) => {
  const xPoisitveWidth = data.cell.x + data.cell.width / 2 + 8;
  const xNegativeWidth = data.cell.x + data.cell.width / 2 - 8;
  const YMaxHeight = data.cell.y + 14;
  const YMinHeight = data.cell.y + 3;

  if (tooth === 'br') {
    doc.setDrawColor(0, 0, 0);

    doc.line(xPoisitveWidth, YMinHeight, xPoisitveWidth, YMaxHeight);

    doc.line(xPoisitveWidth, YMaxHeight, xNegativeWidth, YMaxHeight);
  }

  if (tooth === 'bl') {
    doc.setDrawColor(0, 0, 0);

    doc.line(xNegativeWidth, YMinHeight, xNegativeWidth, YMaxHeight);

    doc.line(xPoisitveWidth, YMaxHeight, xNegativeWidth, YMaxHeight);
  }
  if (tooth === 'tl') {
    doc.setDrawColor(0, 0, 0);

    doc.line(xNegativeWidth, YMinHeight, xNegativeWidth, YMaxHeight);

    doc.line(xNegativeWidth, YMinHeight, xPoisitveWidth, YMinHeight);
  }
  if (tooth === 'tr') {
    doc.setDrawColor(0, 0, 0);

    doc.line(xNegativeWidth, YMinHeight, xPoisitveWidth, YMinHeight);

    doc.line(xPoisitveWidth, YMinHeight, xPoisitveWidth, YMaxHeight);
  }
};

const allTeeth = new Array(32).fill(null);
const arrOfKeys = [...new Array(32).keys()];
const TEETH_HEADER = arrOfKeys.splice(0, 16).concat(arrOfKeys.reverse());

const MOVEMENT_NAMINGS = {
  Angulation: 'Angulation (deg)',
  Inclination: 'Inclination (deg)',
  Rotation: 'Rotation (deg)',
  ForwardBackward: 'Forward/Backward (mm)',
  IntrusionExtrusion: 'Intrusion/Extrusion (mm)',
  LeftRight: 'Left/Right (mm)',
};

function TeethChartPDF({
  stages,
  caseId,
  total: totalData,
  location,
  patientName = null,
  dentalNotation = null,
  stl = [],
  designerMessagesCurrent = [],
  treatmentReviewFiles = [],
}) {
  const { t } = useTranslation('pdfReport');

  const [startLoading, setStartLoading] = useState(false);

  const qualityControlImages = useMemo(
    () =>
      pdfControlImagesNamings
        .map((name) =>
          treatmentReviewFiles.find(
            (item) =>
              item.originalFileName === `${name}.jpg` ||
              item.originalFileName === `${name}.png`,
          ),
        )
        .filter((item) => item),
    [treatmentReviewFiles],
  );

  const { numberingSystem: locationNotation, pdfAllMovementPerStage } =
    location;
  const numberingSystem = dentalNotation || locationNotation;
  const headerBasedOnNotation = (item) => {
    if (numberingSystem === 'uns') {
      return item;
    }

    if (numberingSystem === 'palmer') {
      const res = palmerNotationDisplay(item, true);
      return res?.[0];
    }

    if (numberingSystem === 'fdi') {
      return fdiMapping[item];
    }

    return null;
  };

  const IPRData = [];

  stages.slice(1).map((stage) => {
    const currentStageTeeth = stage.Teeth;
    const headerTable = headerTableDataGenerate(currentStageTeeth);

    const foundData = {
      stage: stage.Number,
      tooth: [],
    };

    const firstTable = TEETH_HEADER.slice(0, 16).map((item) => ({
      dataKey: item + 1,
    }));
    const secondTable = TEETH_HEADER.slice(16, 32).map((item) => ({
      dataKey: item + 1,
    }));

    for (const pg of firstTable) {
      const { correctItemCount, aggregatedValue } = headerIPR(
        pg.dataKey - 2,
        headerTable,
      );

      if (aggregatedValue) {
        foundData.tooth.push(correctItemCount);
      }
    }

    for (const pg of secondTable) {
      const { correctItemCount, aggregatedValue } = headerIPR(
        pg.dataKey,
        headerTable,
      );

      if (aggregatedValue) {
        foundData.tooth.push(correctItemCount);
      }
    }

    if (foundData.tooth.length > 0) {
      IPRData.push(foundData);
    }

    return null;
  });

  const firstAttachments = useMemo(
    () =>
      stages.reduce((arr, item) => {
        const currentToothData = { elements: [], stage: item.Number };
        item.Teeth.map((tooth) => {
          if (tooth.Attachments) {
            currentToothData.elements.push(tooth.Unn);
          }
          return null;
        });

        if (currentToothData.elements.length > 0) {
          arr.push(currentToothData);
        }

        return arr;
      }, []),
    [stages],
  );

  const generateTableData = (singleStage, doc, total, allStages) => {
    const currentStageTeeth = singleStage.Teeth;
    const allTeethData = mapStageTeethData(currentStageTeeth);

    const headerTable = headerTableDataGenerate(currentStageTeeth, total);
    const bodyTable = bodyTableDataGenerate(allTeethData);

    for (let i = 0; i < 2; i++) {
      const body = formatBodyData(bodyTable, i);
      renderAutoTable(doc, body, headerTable, i, total, singleStage, allStages);
    }
  };

  const mapStageTeethData = (currentStageTeeth) => {
    const allTeethData = [...allTeeth];
    for (const item of currentStageTeeth) {
      if (item) {
        allTeethData[item.Unn - 1] = item;
      }
    }
    return allTeethData;
  };

  const formatBodyData = (bodyTable, index) => {
    return Object.keys(bodyTable).reduce((acc, item) => {
      const bodyTableData = bodyTable[item]
        .slice(index ? 16 : 0, index ? 32 : 16)
        .map((value) =>
          !value || ['0.00', '-0.00'].includes(value.replace(/[DMLVEIB-]/g, ''))
            ? null
            : value,
        );

      if (index) {
        bodyTableData.reverse();
      }
      acc.push([t(MOVEMENT_NAMINGS[item]), ...bodyTableData]);
      return acc;
    }, []);
  };

  const renderAutoTable = (
    doc,
    body,
    headerTable,
    index,
    total,
    singleStage,
    allStages,
  ) => {
    doc.autoTable({
      theme: 'plain',
      margin: { left: 15, right: 15, top: 40 },
      ...(total ? { startY: index ? doc.lastAutoTable.finalY + 50 : 140 } : {}),
      styles: {
        fontSize: 6,
        cellWidth: 29,
        lineWidth: 1,
        halign: 'center',
        valign: 'middle',
      },
      columns: createColumns(index),
      body,
      pageBreak: getPageBreakSetting(singleStage, index, allStages),
      columnStyles: {
        headNaming: { cellWidth: 100, halign: 'left', valign: 'middle' },
      },
      didDrawCell: (data) =>
        handleCellDrawing(data, headerTable, doc, index, allStages),
    });
  };

  const createColumns = (index) => [
    { dataKey: 'headNaming', header: '' },
    ...TEETH_HEADER.slice(index ? 16 : 0, index ? 32 : 16).map((item) => ({
      dataKey: item + 1,
      header: headerBasedOnNotation(item + 1) || '',
    })),
  ];

  const getPageBreakSetting = (singleStage, index, allStages) =>
    !singleStage.Number && index === 0 && !allStages ? 'always' : 'avoid';

  const handleCellDrawing = (data, headerTable, doc, index, allStages) => {
    const columnHeader = headerBasedOnNotation(data.column.dataKey);
    if (data.cell.raw === columnHeader) {
      const { aggregatedValue, correctItemCount } = headerIPR(
        index ? data.column.dataKey : data.column.dataKey - 2,
        headerTable,
      );

      drawCellHighlights(
        doc,
        data,
        aggregatedValue,
        correctItemCount,
        allStages,
      );
    }
    drawAdditionalCellContent(data, doc, headerTable);
  };

  const drawCellHighlights = (
    doc,
    data,
    aggregatedValue,
    correctItemCount,
    allStages,
  ) => {
    if (data.column.raw.dataKey !== 'headNaming') {
      drawErrorHighlight(doc, data.cell);
    }
    if (aggregatedValue && aggregatedValue.toFixed(1) >= 0.1) {
      drawAggregatedValue(
        doc,
        data,
        aggregatedValue,
        correctItemCount,
        allStages,
      );
    }
  };

  const drawErrorHighlight = (doc, cell) => {
    doc.setDrawColor(255, 0, 0);
    doc.line(cell.x + 5, cell.y + 2, cell.x + 24, cell.y + 15);
    doc.line(cell.x + 24, cell.y + 2, cell.x + 5, cell.y + 15);
  };

  const drawAggregatedValue = (
    doc,
    data,
    aggregatedValue,
    correctItemCount,
    allStages,
  ) => {
    doc.setFontSize(7);
    doc.setTextColor('#f00');
    doc.text(`${aggregatedValue.toFixed(1)}`, data.cell.x - 5, data.cell.y - 5);

    const stageNumber =
      IPRData.reduce((num, ipr) => {
        return ipr.tooth.includes(correctItemCount) ? ipr.stage : num;
      }, null) || 1;

    if (!allStages) {
      doc.text(
        `Stage ${stageNumber}`,
        data.cell.x + 1,
        data.cell.y - 15,
        null,
        90,
      );
    }
    doc.setDrawColor(255, 0, 0);
    doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + 17);
  };

  const drawAdditionalCellContent = (data, doc, headerTable) => {
    if (data.row.index === 0 && !Array.isArray(data.row.raw)) {
      if (numberingSystem === 'palmer') {
        const toothInfo = palmerNotationDisplay(data.column.dataKey, true);
        drawPalmer(toothInfo?.[1], doc, data);
      }
      if (headerTable[data.column.dataKey]?.attachments) {
        doc.setDrawColor(0, 0, 255);
        doc.circle(data.cell.x + 14.5, data.cell.y + 8.5, 7);
      }
    }
  };

  const generateTableDataImageTeeth = (singleStage, doc, total) => {
    const currentStageTeeth = singleStage.Teeth;
    const allTeethData = new Array(32).fill(null);
    for (const item of currentStageTeeth) {
      if (item) {
        allTeethData[item.Unn - 1] = item;
      }
    }

    const headerTable = headerTableDataGenerate(currentStageTeeth, total);

    for (let i = 0; i < 2; i++) {
      doc.autoTable({
        theme: 'plain',
        margin: {
          left: i ? 45 : 40,
          right: 15,
        },
        startY: i ? doc.lastAutoTable.finalY : 320,
        styles: {
          fontSize: 6,
          lineWidth: 0,
          halign: 'center',
          valign: 'middle',
          lineColor: '#000',
        },
        columns: [
          ...TEETH_HEADER.slice(i ? 16 : 0, i ? 32 : 16).map((item) => ({
            dataKey: item + 1,
            header: headerBasedOnNotation(item + 1) || '',
          })),
        ],
        body: [[]],
        pageBreak: !singleStage.Number && i === 0 ? 'always' : 'avoid',
        columnStyles: {
          1: { cellWidth: 30 },
          2: { cellWidth: 40 },
          3: { cellWidth: 40 },
          4: { cellWidth: 27 },
          5: { cellWidth: 27 },
          6: { cellWidth: 30 },
          7: { cellWidth: 25 },
          8: { cellWidth: 32 },
          10: { cellWidth: 25 },
          11: { cellWidth: 29 },
          13: { cellWidth: 25 },
          14: { cellWidth: 40 },
          15: { cellWidth: 40 },
          16: { cellWidth: 32 },
          32: { cellWidth: 35 },
          31: { cellWidth: 44 },
          30: { cellWidth: 45 },
          29: { cellWidth: 29 },
          28: { cellWidth: 26 },
          27: { cellWidth: 27 },
          26: { cellWidth: 22 },
          25: { cellWidth: 19 },
          24: { cellWidth: 19 },
          23: { cellWidth: 21 },
          22: { cellWidth: 27 },
          21: { cellWidth: 27 },
          20: { cellWidth: 28 },
          19: { cellWidth: 45 },
          18: { cellWidth: 44 },
          17: { cellWidth: 30 },
        },
        // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
        didDrawCell(data) {
          if (data.cell.raw === headerBasedOnNotation(data.column.dataKey)) {
            const { aggregatedValue, correctItemCount } = headerIPR(
              i ? data.column.dataKey : data.column.dataKey - 2,
              headerTable,
            );
            if (
              !headerTable[data.column.dataKey] &&
              data.column.raw.dataKey !== 'headNaming'
            ) {
              doc.setDrawColor(255, 0, 0);
              doc.setLineWidth(2.0);
              doc.line(
                data.cell.x + 6,
                data.cell.y + 2 - (i ? -23 : 18),
                data.cell.x + data.cell.width - 6,
                data.cell.y + 15 - (i ? -23 : 18),
              );

              doc.line(
                data.cell.x + data.cell.width - 6,
                data.cell.y + 2 - (i ? -23 : 18),
                data.cell.x + 6,
                data.cell.y + 15 - (i ? -23 : 18),
              );
            }

            if (aggregatedValue && aggregatedValue.toFixed(1) >= 0.1) {
              doc.setFontSize(7);
              doc.setTextColor('#f00');
              doc.text(
                `${aggregatedValue.toFixed(1)}`,
                data.cell.x - 5,
                data.cell.y - (i ? -75 : 55),
              );

              const stageNumber = IPRData.reduce((num, ipr) => {
                let newNum = num;
                for (const singleIpr of ipr.tooth) {
                  if (singleIpr === correctItemCount) {
                    newNum = ipr.stage;
                  }
                }

                return newNum === null || newNum === undefined ? 1 : newNum;
              }, null);

              doc.setTextColor('#333');
              doc.text(
                `Stage ${stageNumber}`,
                data.cell.x + 1,
                data.cell.y + (i ? 110 : -70),
                null,
                90,
              );

              doc.setDrawColor(255, 0, 0);
              doc.setLineWidth(2.0);
              if (i) {
                doc.line(
                  data.cell.x,
                  data.cell.y + 45,
                  data.cell.x,
                  data.cell.y + 65,
                );
              } else {
                doc.line(
                  data.cell.x,
                  data.cell.y - 30,
                  data.cell.x,
                  data.cell.y - 50,
                );
              }
            }
          }

          if (data.row.index === 0 && !Array.isArray(data.row.raw)) {
            if (numberingSystem === 'palmer') {
              const toothInfo = palmerNotationDisplay(
                data.column.dataKey,
                true,
              );

              doc.setLineWidth(1.0);
              drawPalmer(toothInfo?.[1], doc, data);
            }
            if (headerTable[data.column.dataKey]?.attachments) {
              doc.setLineWidth(2.0);
              doc.setFillColor(0, 0, 255);
              doc.circle(
                data.cell.x + data.cell.width / 2,
                data.cell.y + (i ? 28 : -10),
                5,
                'F',
              );
            }
          }
        },
      });
    }
  };
  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'portrait';
    const doc = new JsPDF(orientation, unit, size);
    doc.setFont('NotoSans-Regular');

    const { pageSize } = doc.internal;
    const pageWidth = pageSize.width || pageSize.getWidth();

    // Helper functions
    const addTitleAndImage = () => {
      const text = doc.splitTextToSize(
        t('Treatment Summary'),
        pageWidth - 35,
        {},
      );
      doc.text(text, 220, 30);
      doc.addImage(imageTeeth, 'JPEG', 15, 250, 545, 180);
    };

    const addCaseInfoTable = () => {
      doc.autoTable({
        head: [[t('Case Information')]],
        body: [],
        theme: 'plain',
        margin: { left: 15 },
      });
      doc.setLineWidth(2.0);
      doc.line(20, 60, 560, 60);
      doc.autoTable({
        head: [[`${t('Patient name')}:`, patientName]],
        body: [[`${t('Case number')}:`, caseId]],
        styles: { font: 'NotoSans-Regular' },
        theme: 'plain',
        tableWidth: 'wrap',
        margin: { left: 15, top: 0 },
      });
    };

    const calculateAmounts = () => {
      const calcAmountStl = (num, item, side) =>
        side.some((sideItem) => item.originalFileName.includes(sideItem)) &&
        !/OC_|template|attachments/.test(item.originalFileName) &&
        item.originalFileName.includes('Subsetup')
          ? num + 1
          : num;

      const lower = stl.reduce(
        (num, item) => calcAmountStl(num, item, ['Lower', 'Mandibular']),
        0,
      );
      const upper = stl.reduce(
        (num, item) => calcAmountStl(num, item, ['Upper', 'Maxillary']),
        0,
      );

      const finalLower = stl.some((item) =>
        findFinalStl(item, ['Final_Lower', 'Mandibular']),
      );
      const finalUpper = stl.some((item) =>
        findFinalStl(item, ['Final_Upper', 'Maxillary']),
      );
      const ocUpperAmount = stl.reduce(
        (num, item) => calcAmountStl(num, item, ['OC_Upper']),
        0,
      );
      const ocLowerAmount = stl.reduce(
        (num, item) => calcAmountStl(num, item, ['OC_Lower']),
        0,
      );

      return {
        upper,
        lower,
        finalLower,
        finalUpper,
        ocUpperAmount,
        ocLowerAmount,
      };
    };

    const addTraySummaryTable = (
      upper,
      lower,
      finalUpper,
      finalLower,
      ocTraysAmount,
    ) => {
      doc.autoTable({
        body: [
          [`${t('Upper')}:`, upper + !!finalUpper],
          [`${t('Lower')}:`, lower + !!finalLower],
          [`${t('OC Trays')}:`, ocTraysAmount],
        ],
        theme: 'plain',
        tableWidth: 'wrap',
        margin: { left: 15, top: 0 },
      });
    };

    const addAttachmentsTable = () => {
      const transformedData = firstAttachments.reduce((arr, item, idx) => {
        const transformedElements = item.elements.map((toothNum) =>
          numberingSystem === 'palmer'
            ? palmerNotationDisplay(toothNum, true).join(',')
            : headerBasedOnNotation(toothNum),
        );
        const formattedElements =
          numberingSystem !== 'palmer'
            ? transformedElements.join(' ')
            : transformedElements;
        arr.push([
          idx === 0 ? `${t('Attachments')}:` : '',
          `${t('stage')}: ${item.stage}`,
          `${t('teeth')}: ${formattedElements}`,
          ...(numberingSystem === 'palmer' ? transformedElements : []),
        ]);
        return arr;
      }, []);

      doc.autoTable({
        theme: 'plain',
        startY: 500,
        margin: { left: 15 },
        styles: {
          fontSize: 7,
          cellWidth: numberingSystem === 'palmer' ? 29 : 'auto',
          valign: 'middle',
        },
        columnStyles: {
          0: { cellWidth: 60 },
          1: { cellWidth: 50 },
          ...(numberingSystem === 'palmer' ? { 2: { cellWidth: 35 } } : {}),
        },
        body: transformedData,
      });
    };

    const addSymbolsTable = () => {
      doc.autoTable({
        body: [
          ['7', '-', t('The attachment icon...')],
          ['|', '-', t('The IPR icon...')],
          ['x', '-', t('Missing tooth.')],
        ],
        theme: 'plain',
        startY: 500,
        margin: { left: 15 },
      });
    };

    const addNotes = () => {
      doc.setFontSize(12);
      doc.text(t('Designer notes:'), 20, 700);
      doc.setFillColor('eee');
      doc.roundedRect(20, 710, 555, 115, 10, 10, 'F');

      const designerMessagesText = designerMessagesCurrent
        .filter((item) => !item.message.includes('FullContour'))
        .map((item) => item.message)
        .join('. ')
        .replace(/^\s*[\r\n]/gm, '');

      doc.setFontSize(designerMessagesText.length > 500 ? 8 : 10);
      doc.text(doc.splitTextToSize(designerMessagesText, 540), 30, 730);
    };

    // const addTreatmentOverviewPage = () => {
    //   doc.addPage();
    //   doc.autoTable({
    //     head: [[t('Total treatment overview')]],
    //     body: [],
    //     theme: 'plain',
    //     margin: { left: 15 },
    //   });
    //   generateTableData(totalData, doc, true);
    //   doc.autoTable(symbols());
    // };

    const addQualityControlImages = () => {
      doc.addPage();
      doc.autoTable({
        head: [[t('Design overview')]],
        body: [],
        theme: 'plain',
        margin: { left: 15 },
      });
      doc.setLineWidth(1.0);
      doc.line(20, 60, 560, 60);
      doc.text(t('Final position'), 20, 90);

      getBase64ImageUrl(qualityControlImages, (imgs) => {
        const { height, width } = doc.getImageProperties(imgs[0]);
        const updatedWidth = width / (height / 200);
        doc.addImage(imgs[0], 'JPEG', 20, 100, updatedWidth, 200);
        doc.text(t('Initial position'), 20, 330);
        doc.text(t('Final position'), 300, 330);
        [imgs[1], imgs[2], imgs[3], imgs[4]].forEach((img, idx) =>
          doc.addImage(
            img,
            'JPEG',
            idx < 2 ? 20 : 300,
            idx % 2 ? 580 : 340,
            270,
            230,
          ),
        );
        setStartLoading(false);
        doc.save(`treatment-summary-${caseId}`);
      });
    };

    // PDF generation
    addTitleAndImage();
    addCaseInfoTable();

    const {
      upper,
      lower,
      finalUpper,
      finalLower,
      ocUpperAmount,
      ocLowerAmount,
    } = calculateAmounts();
    const ocTraysAmount =
      Math.max(ocUpperAmount, ocLowerAmount) ||
      (ocUpperAmount + ocLowerAmount) / 2;

    addTraySummaryTable(upper, lower, finalUpper, finalLower, ocTraysAmount);
    generateTableDataImageTeeth(totalData, doc, true);
    addAttachmentsTable();
    addSymbolsTable();
    addNotes();

    if (pdfAllMovementPerStage) {
      doc.addPage();
      stages.forEach((stage, index) => {
        if (index && index % 2 === 0) {
          doc.addPage();
        }
        doc.text(
          `${t('Stage')} ${stage.Number}`,
          14,
          index % 2 ? doc.previousAutoTable.finalY + 17 : 35,
        );
        generateTableData(stage, doc, false, true);
      });
    }

    qualityControlImages.length === 5
      ? addQualityControlImages()
      : doc.save(`treatment-summary-${caseId}`);
  };

  return (
    <div>
      <button
        type="button"
        className={`button ${startLoading ? 'is-loading' : ''}`}
        disabled={startLoading}
        onClick={exportPDF}
      >
        <span className="icon">
          <i className="bx bxs-file-pdf is-size-4" />
        </span>
        <span>{t('Download as PDF', { ns: 'common' })}</span>
      </button>
    </div>
  );
}

TeethChartPDF.propTypes = {
  stages: PropTypes.array.isRequired,
  total: PropTypes.object.isRequired,
  caseId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  dentalNotation: PropTypes.string,
  patientName: PropTypes.string,
  stl: PropTypes.array,
  designerMessagesCurrent: PropTypes.array,
  treatmentReviewFiles: PropTypes.array,
};

export default TeethChartPDF;
