import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { SparklinesModule } from '@ag-grid-enterprise/sparklines';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  gridDateComparator,
  timestampFormatter,
} from '../../../../../transforms';
import { AgGridLoader, BoxIcon, ExpireComponent } from '../../../../shared';
import '../../../../shared/AgGrid/ag-grid-css-overrides.css';
import '../../../../shared/AgGrid/ag-theme-balham-overrides.css';
import { FollowUpModalWrapper } from '../../shared/FollowUpModal';
import { ReviewModal } from '../../shared/ReviewModal';
class OrdersListGrid extends Component {
  state = {
    modalOpen: false,
    order: null,
  };

  closeModal = () => {
    this.setState({ modalOpen: false, order: null });
  };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  reviewCase = (data) => {
    this.setState({ modalOpen: true, order: data });
  };

  getContextMenuItems = (params = {}) => {
    if (params?.value) {
      params.defaultItems.push({
        name: 'Open in new tab',
        icon: `<span class="ag-icon ag-icon-small-up" unselectable="on" role="presentation"></span>`,
        action() {
          window
            .open(
              `${window.location.origin}/orders/details/${params.value}`,
              '_blank',
            )
            ?.focus();
        },
      });
    }

    return params.defaultItems;
  };

  translateHeaders = (headers) => {
    const { t } = this.props;
    return headers.map((header) => {
      if (header.children) {
        return {
          ...header,
          headerName: t(header.headerName),
          children: this.translateHeaders(header.children),
        };
      }
      return {
        ...header,
        headerName: t(header.headerName),
      };
    });
  };

  render() {
    const {
      containerHeight,
      gridOptions,
      rowData,
      navigate,
      onGridReady,
      onGridSizeChanged,
      onFirstDataRendered,
      onColumnMoved,
      onDisplayedColumnsChanged,
      onColumnVisible,
      onSelectionChanged,
      location,
      loading,
      t,
    } = this.props;

    const { modalOpen, order } = this.state;

    const { components, columnDefs, ...gridRest } = gridOptions;

    return (
      <>
        <div
          className="ag-theme-balham mainGridContainer ordersGridContainer"
          role={rowData ? 'gridStateCompleted' : 'gridStateLoading'}
          style={{
            height: containerHeight,
            position: 'relative',
          }}
        >
          <AgGridReact
            getContextMenuItems={this.getContextMenuItems}
            gridOptions={{
              ...gridRest,
              getLocaleText: ({ defaultValue }) => t(defaultValue),
            }}
            headerHeight={40}
            rowHeight={40}
            rowData={rowData}
            animateRows
            maintainColumnOrder
            columnDefs={[
              {
                field: '',
                width: 40,
                minWidth: 40,
                filter: false,
                sortable: false,
                suppressHeaderMenuButton: true,
                suppressColumnsToolPanel: true,
                lockPosition: true,
                resizable: false,
                suppressSizeToFit: true,
                suppressMovable: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                pinned: 'left',
                headerClass: 'checkAllInput',
              },
              ...this.translateHeaders(columnDefs),
            ]}
            reactNext
            context={{
              navigate,
              reviewCase: this.reviewCase,
            }}
            modules={[
              ClientSideRowModelModule,
              InfiniteRowModelModule,
              CsvExportModule,
              SparklinesModule,
              ClipboardModule,
              ColumnsToolPanelModule,
              ExcelExportModule,
              FiltersToolPanelModule,
              MasterDetailModule,
              MenuModule,
              RangeSelectionModule,
              RichSelectModule,
              RowGroupingModule,
              SetFilterModule,
              MultiFilterModule,
              AdvancedFilterModule,
              SideBarModule,
              StatusBarModule,
              ViewportRowModelModule,
            ]}
            components={{
              customLoadingOverlay: AgGridLoader,
              ...components,
            }}
            loadingOverlayComponent="customLoadingOverlay"
            floatingFiltersHeight={46}
            onGridReady={onGridReady}
            onGridSizeChanged={onGridSizeChanged}
            onFirstDataRendered={onFirstDataRendered}
            onColumnMoved={onColumnMoved}
            onDisplayedColumnsChanged={onDisplayedColumnsChanged}
            onColumnVisible={onColumnVisible}
            groupHeaderHeight={30}
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                },
              ],
            }}
            defaultColDef={{
              resizable: true,
              editable: false,
              sortable: true,
              filterParams: { newRowsAction: 'keep' },
              floatingFilter: true,
            }}
            columnTypes={{
              dateColumn: {
                width: 185,
                minWidth: 185,
                filter: 'agDateColumnFilter',
                filterParams: {
                  newRowsAction: 'keep',
                  comparator: (filterLocalDateAtMidnight, cellValue) =>
                    gridDateComparator(filterLocalDateAtMidnight, cellValue),
                },
                valueFormatter: (params) => {
                  if (params.value) {
                    return timestampFormatter(params.value);
                  }

                  return params.value;
                },
              },
              orderIdColumn: {
                width: 150,
                minWidth: 150,
                filter: 'agTextColumnFilter',
                filterParams: { newRowsAction: 'keep' },
                cellStyle: {
                  color: 'rgb(27, 119, 200)',
                  cursor: 'pointer',
                  textTransform: 'uppercase',
                },
                onCellClicked: (params) => {
                  params.context.navigate(`/orders/details/${params.value}`, {
                    prevLocation: location.pathname,
                  });
                },
              },
            }}
            sideBar={{
              toolPanels: [
                {
                  id: 'columns',
                  labelDefault: t('Show / Hide Columns'),
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                  toolPanelParams: {
                    suppressPivotMode: true,
                    suppressPivots: true,
                    suppressRowGroups: true,
                    suppressValues: true,
                  },
                },
                {
                  id: 'filters',
                  labelDefault: t('Filters'),
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                },
              ],
            }}
          />
          <ExpireComponent delay={40000}>
            <div
              style={{ position: 'absolute', bottom: '13px', right: '20px' }}
            >
              {loading ? (
                'loading'
              ) : (
                <span
                  style={{
                    fontSize: '10px',
                    marginLeft: '12px',
                  }}
                >
                  {t('Table is up-to-date')}
                  <BoxIcon name="bx-check-circle has-text-success bx-xs" />
                </span>
              )}
            </div>
          </ExpireComponent>
        </div>
        {modalOpen &&
        (import.meta.env.VITE_APP_NAME === 'admin_portal' ||
          import.meta.env.VITE_APP_NAME === 'designer_portal' ||
          import.meta.env.VITE_APP_NAME === 'customer_portal') ? (
          <ReviewModal
            closeModal={this.closeModal}
            modalOpen={modalOpen}
            order={order}
            {...this.props}
          />
        ) : null}
        <FollowUpModalWrapper />
      </>
    );
  }
}
const withParams =
  (WrappedComponent) =>
  ({ ...props }) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    return (
      <WrappedComponent
        match={{ params }}
        location={location}
        navigate={navigate}
        {...props}
      />
    );
  };
OrdersListGrid.propTypes = {
  gridOptions: PropTypes.object.isRequired,
  rowData: PropTypes.array,
  containerHeight: PropTypes.string,
  location: ReactRouterPropTypes.location.isRequired,
  loading: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  onGridReady: PropTypes.func.isRequired,
  onGridSizeChanged: PropTypes.func.isRequired,
  onFirstDataRendered: PropTypes.func.isRequired,
  onColumnMoved: PropTypes.func.isRequired,
  onDisplayedColumnsChanged: PropTypes.func.isRequired,
  onColumnVisible: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

OrdersListGrid.defaultProps = {
  rowData: null,
  containerHeight:
    import.meta.env.VITE_APP_NAME === 'customer_portal' ||
    import.meta.env.VITE_APP_NAME === 'designer_portal'
      ? 'calc(100vh - 190px - 3em)'
      : 'calc(100vh - 129px - 3em)',
};

export default flowRight(withTranslation('aggrid'), withParams)(OrdersListGrid);
