import {
  GET_SHOW_APPROVAL_LINK,
  GET_SHOW_PATIENT_LINK,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReviewModalActionsCheckLink from './ReviewModalActionsCheckLink';
import ReviewModalBodyTerms from './ReviewModalBodyTerms';

import { DropdownSelect } from '../../../../shared';

function ReviewModalActions({
  closeAndReset,
  updateStatus,
  redesign,
  acceptTerms,
  toggleTerms,
  redesignOn,
  docApproval,
  docApprovalOn,
  patientApproval,
  patientApprovalOn,
  order = null,
}) {
  const { t } = useTranslation('common');

  const [hideElement, setHideElement] = useState({});

  return order ? (
    <div className="columns" style={{ width: '100%' }}>
      <div className="column">
        {(order.state === 'sent_for_approval' ||
          order.state === 'needs_approval') &&
        import.meta.env.VITE_APP_NAME !== 'designer_portal' ? (
          <ReviewModalBodyTerms
            closeAndReset={closeAndReset}
            updateStatus={updateStatus}
            redesign={redesign}
            acceptTerms={acceptTerms}
            redesignOn={redesignOn}
            docApproval={docApproval}
            docApprovalOn={docApprovalOn}
            patientApproval={patientApproval}
            patientApprovalOn={patientApprovalOn}
            toggleTerms={toggleTerms}
            order={order}
          />
        ) : null}
      </div>
      <div className="column is-flex is-align-items-center is-justify-content-end">
        <button
          type="button"
          className="button is-dark m-0"
          onClick={closeAndReset}
        >
          {t('Close')}
        </button>
        {order.state === 'sent_for_approval' ||
        order.state === 'needs_approval' ||
        order.state === 'complete' ||
        order.state === 'ready_for_download' ||
        order.state === 'sent_to_manufacturer' ||
        order.state === 'at_manufacturer' ||
        order.state === 'shipped' ? (
          <>
            <div className="mx-2">
              <DropdownSelect
                className="is-up"
                trigger={
                  <button type="button" className="button is-primary">
                    <span className="icon is-small">
                      <i className="bx bxs-magic-wand mr-2" />
                    </span>
                    <span>{t('Actions')}...</span>
                  </button>
                }
              >
                {order.state === 'sent_for_approval' ||
                  (order.state === 'needs_approval' && (
                    <button
                      type="button"
                      onClick={redesignOn}
                      disabled={redesign}
                    >
                      {t('Redesign')}...
                    </button>
                  ))}

                <ReviewModalActionsCheckLink
                  isShowing={patientApproval}
                  query={GET_SHOW_PATIENT_LINK}
                  order={order}
                  hideElement={hideElement.patient}
                  turnOn={patientApprovalOn}
                  setHideElement={setHideElement}
                  text="Patient Link..."
                  linkType="patient"
                />

                {order.state === 'sent_for_approval' ||
                order.state === 'needs_approval' ||
                order.state === 'ready_for_download' ||
                order.state === 'sent_to_manufacturer' ||
                order.state === 'at_manufacturer' ||
                order.state === 'complete' ? (
                  <ReviewModalActionsCheckLink
                    isShowing={docApproval}
                    hideElement={hideElement.doctor}
                    setHideElement={setHideElement}
                    query={GET_SHOW_APPROVAL_LINK}
                    order={order}
                    turnOn={docApprovalOn}
                    text="Send Doctor Link..."
                    linkType="doctor"
                  />
                ) : null}
              </DropdownSelect>
            </div>
            {(order.state === 'sent_for_approval' ||
              order.state === 'needs_approval') &&
            order.source === 'Dental System' &&
            (order.itemsMetadata?.design_types?.includes(
              'Straumann Abutment',
            ) ||
              order.itemsMetadata?.destinations?.includes(
                'In House Manufacturing',
              )) ? (
              <DropdownSelect
                className="is-up"
                disabled={!acceptTerms}
                trigger={
                  <button type="button" className="button is-primary">
                    <span>{t('Approve')}...</span>
                  </button>
                }
              >
                <button
                  type="button"
                  onClick={updateStatus}
                  action="push_to_approved_status"
                  disabled={redesign || !acceptTerms}
                >
                  {t('Approve and manufacture')}
                </button>
                <button
                  type="button"
                  onClick={updateStatus}
                  action="complete_order"
                  disabled={redesign}
                >
                  {t('Approve and edit in Dental System')}
                </button>
              </DropdownSelect>
            ) : (
              <button
                type="button"
                className="button is-primary"
                onClick={updateStatus}
                action="push_to_approved_status"
                disabled={redesign || !acceptTerms}
              >
                {t('Approve and Manufacture')}
              </button>
            )}
          </>
        ) : null}
      </div>
    </div>
  ) : null;
}

ReviewModalActions.propTypes = {
  closeAndReset: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  redesign: PropTypes.bool.isRequired,
  acceptTerms: PropTypes.bool.isRequired,
  redesignOn: PropTypes.func.isRequired,
  docApprovalOn: PropTypes.func.isRequired,
  docApproval: PropTypes.bool.isRequired,
  patientApprovalOn: PropTypes.func.isRequired,
  patientApproval: PropTypes.bool.isRequired,
  toggleTerms: PropTypes.func.isRequired,
  order: PropTypes.object,
};

export default ReviewModalActions;
