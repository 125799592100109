import { useQuery } from '@apollo/client';
import { GET_INVOICE } from '@fullcontour/shared-api';
import { useParams } from 'react-router';
import { LoadingOrError } from '../../../shared';
import { BillingInvoicesHeader } from '../../Billing/BillingInvoices';
import { useInvoice } from '../InvoicesList';
import InvoiceAddressRow from './InvoiceAddressRow';
import InvoiceDetails from './InvoiceDetails';
import InvoiceStatusMessage from './InvoiceStatusMessage';
import InvoiceSummary from './InvoiceSummary';

function InvoiceShow() {
  const { hexInvoiceNumber } = useParams();

  const {
    statusUpdate,
    resendInvoice,
    resendInvoiceTransaction,
    getCsv,
    modalState,
    openModal,
    closeModal,
    setSelectedItem,
    downloadPDF,
  } = useInvoice();

  const { error, loading, data, refetch } = useQuery(GET_INVOICE, {
    fetchPolicy: 'cache-and-network',
    variables: { hexInvoiceNumber },
    onCompleted: (d) => setSelectedItem(d.invoice),
  });

  if (loading || error) {
    return <LoadingOrError error={error} loading={loading} title="Invoice" />;
  }

  const { invoice } = data;

  return (
    <div className="box" id="printableInvoice">
      <div id="printableInvoiceInner">
        <div className="columns">
          <div className="column">
            <h4 className="title is-4">
              Invoice #
              <span style={{ textTransform: 'uppercase' }}>
                {invoice.hexInvoiceNumber}
              </span>
            </h4>
          </div>
          <div className="column has-text-right">
            <BillingInvoicesHeader
              selectedItem={invoice}
              statusUpdate={statusUpdate}
              refetch={refetch}
              getCsv={getCsv}
              resendInvoice={resendInvoice}
              modalOpen={modalState}
              openModal={openModal}
              closeModal={closeModal}
              downloadPDF={downloadPDF}
              resendInvoiceTransaction={resendInvoiceTransaction}
            />
          </div>
        </div>
        <InvoiceStatusMessage invoice={invoice} />
        <InvoiceAddressRow invoice={invoice} />
        <InvoiceSummary invoice={invoice} />
        <InvoiceDetails invoice={invoice} />
      </div>
    </div>
  );
}

export default InvoiceShow;
