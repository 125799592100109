import PropTypes from 'prop-types';
import TreatmentCardBody from './TreatmentCardBody';
import TreatmentCardHeader from './TreatmentCardHeader';

function TreatmentCard({
  idx,
  feedbacks,
  resetHistory = () => ({}),
  handleTitleClickVersion = () => ({}),
  currentRedesign = null,
  light = null,
  last = false,
  item = null,
  ...rest
}) {
  return (
    <div className="box p-0">
      <TreatmentCardHeader
        current={currentRedesign === idx}
        last={last}
        light={light}
        resetHistory={resetHistory}
        v={idx}
        handleTitleClickVersion={handleTitleClickVersion}
        date={
          item?.createdAt
            ? new Date(item.createdAt)
                .toISOString()
                .substring(0, 10)
                .split('-')
                .reverse()
                .join('/')
            : null
        }
        stages={item?.stages ? item.stages.length - 1 : 0}
      />
      <TreatmentCardBody
        feedbacks={feedbacks}
        current={currentRedesign === idx}
        v={idx}
        final
        {...rest}
      />
    </div>
  );
}

TreatmentCard.propTypes = {
  item: PropTypes.object,
  resetHistory: PropTypes.func,
  handleTitleClickVersion: PropTypes.func,
  idx: PropTypes.number.isRequired,
  feedbacks: PropTypes.array.isRequired,
  currentRedesign: PropTypes.number,
  light: PropTypes.bool,
  last: PropTypes.bool,
};

export default TreatmentCard;
