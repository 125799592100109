export default function ArchSvg() {
  return (
    <svg
      height="44"
      viewBox="0 0 54 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-4"
    >
      <title>arch svg</title>
      <path
        d="M6.97683 43.5745C6.655 43.5742 6.33342 43.5575 6.0133 43.5244C5.0634 43.4432 4.14537 43.1423 3.33172 42.6455C2.51807 42.1486 1.83109 41.4695 1.32498 40.6615C0.388475 39.1311 -0.0698975 37.3559 0.00863913 35.5634C0.307893 30.6976 1.2008 25.8865 2.66746 21.2373C5.12524 13.3949 8.69443 7.78615 13.2765 4.57048L13.3204 4.53964C17.1591 1.70785 21.7749 0.123262 26.5435 1.41111e-05H26.5528C26.7538 -0.00121147 26.9471 0.0774406 27.0901 0.218707C27.2331 0.359974 27.3141 0.552211 27.3153 0.753217C27.3165 0.954224 27.2379 1.14755 27.0966 1.29055C26.9553 1.43355 26.763 1.5145 26.562 1.51573C22.0995 1.63837 17.7818 3.1266 14.1915 5.77968L14.1468 5.81052C4.16708 12.8195 1.65152 31.0938 1.5229 35.605C1.40813 39.6941 3.77271 41.7667 6.16655 42.0155C6.98081 42.136 7.81125 42.0859 8.60509 41.8683C9.39892 41.6507 10.1388 41.2704 10.7779 40.7516C11.4096 40.1935 11.9252 39.5164 12.2953 38.759C12.6653 38.0016 12.8825 37.1788 12.9345 36.3375C13.7687 24.1202 16.5954 17.1875 18.9623 14.7713C20.9842 12.8297 23.6897 11.7629 26.4927 11.8021C26.5922 11.8013 26.691 11.8201 26.7832 11.8574C26.8755 11.8947 26.9595 11.9499 27.0304 12.0197C27.1014 12.0895 27.1579 12.1726 27.1967 12.2642C27.2356 12.3559 27.256 12.4543 27.2568 12.5538C27.2576 12.6533 27.2388 12.7521 27.2014 12.8443C27.1641 12.9366 27.109 13.0206 27.0391 13.0915C26.9693 13.1625 26.8862 13.219 26.7946 13.2578C26.703 13.2967 26.6045 13.317 26.505 13.3178C24.105 13.2743 21.7844 14.1775 20.0452 15.8319C18.933 16.9656 17.7607 19.3364 16.828 22.3364C15.5049 26.932 14.706 31.6625 14.4464 36.4376C14.3783 37.4815 14.1052 38.5018 13.6426 39.4401C13.1801 40.3784 12.5372 41.2164 11.7507 41.9062C10.4108 43.0165 8.71664 43.6086 6.97683 43.5745V43.5745Z"
        fill="#4C4D52"
      />
      <path
        d="M7.08408 40.2494C6.49156 40.2488 5.90621 40.1196 5.36848 39.8708C4.83075 39.6219 4.35343 39.2593 3.96954 38.8079C3.58564 38.3566 3.30433 37.8273 3.14497 37.2566C2.98562 36.6859 2.95204 36.0875 3.04658 35.5025L3.40936 33.0108C3.48567 32.4694 3.66898 31.9486 3.94866 31.4787C4.22835 31.0089 4.5988 30.5994 5.03837 30.2742C5.46423 29.9561 5.94913 29.726 6.46486 29.5972C6.98058 29.4684 7.51678 29.4435 8.0422 29.524C9.11703 29.6982 10.0798 30.2893 10.7216 31.1689C11.3634 32.0485 11.6325 33.1457 11.4705 34.2224L11.1062 36.7141C11.03 37.2558 10.8467 37.7768 10.567 38.2469C10.2873 38.717 9.91683 39.1267 9.47715 39.4522C8.78614 39.9687 7.94681 40.2483 7.08408 40.2494ZM7.4353 30.9944C6.89699 30.995 6.37322 31.1693 5.94182 31.4912C5.66323 31.6983 5.42861 31.9586 5.25156 32.2572C5.07451 32.5558 4.95856 32.8866 4.9105 33.2303L4.54617 35.7212C4.44068 36.4023 4.60795 37.0975 5.01167 37.6561C5.41539 38.2147 6.02304 38.5916 6.70283 38.7051C7.03044 38.755 7.36469 38.7392 7.68615 38.6586C8.0076 38.5781 8.30981 38.4345 8.57521 38.236C8.85411 38.0292 9.08899 37.7689 9.26608 37.4703C9.44316 37.1717 9.55888 36.8407 9.60653 36.4968L9.97011 34.006C10.0754 33.325 9.90802 32.63 9.50433 32.0716C9.10063 31.5132 8.49308 31.1364 7.81345 31.0229C7.68856 31.0038 7.5624 30.9943 7.43605 30.9944H7.4353Z"
        fill="#4C4D52"
      />
      <path
        d="M9.22053 28.5466C8.9029 28.5462 8.58669 28.504 8.28008 28.4211L8.22696 28.4071C7.28484 28.1442 6.48385 27.5214 5.99682 26.6731C5.50979 25.8248 5.37575 24.8191 5.62361 23.8729L6.26521 21.3897C6.38524 20.9144 6.59902 20.4679 6.89397 20.0764C7.18892 19.6849 7.55908 19.3563 7.98278 19.1099C8.3945 18.8678 8.85043 18.7103 9.32381 18.6468C9.79718 18.5833 10.2785 18.615 10.7394 18.7401L10.7918 18.754C11.7342 19.0168 12.5354 19.6397 13.0225 20.4882C13.5096 21.3367 13.6435 22.3427 13.3952 23.289L12.7543 25.7715C12.6342 26.2467 12.4204 26.6931 12.1255 27.0846C11.8305 27.4761 11.4604 27.8049 11.0367 28.0514C10.4861 28.3749 9.85917 28.5458 9.22053 28.5466V28.5466ZM9.80205 20.1319C9.43073 20.1325 9.06628 20.2319 8.7461 20.42C8.49607 20.5661 8.2777 20.7607 8.10379 20.9923C7.92988 21.2238 7.8039 21.4877 7.73325 21.7686L7.09165 24.2518C6.94248 24.8112 7.01917 25.407 7.30517 25.9104C7.59118 26.4139 8.06358 26.7847 8.62053 26.943L8.67215 26.9576C8.93975 27.0298 9.21907 27.0479 9.49375 27.0108C9.76843 26.9738 10.033 26.8824 10.2719 26.7419C10.5222 26.5957 10.7408 26.4009 10.9148 26.1691C11.0889 25.9372 11.2149 25.673 11.2855 25.3918L11.9271 22.9086C12.0763 22.3491 11.9996 21.7534 11.7136 21.25C11.4276 20.7465 10.9551 20.3757 10.3982 20.2174L10.3451 20.2035C10.168 20.156 9.9854 20.1319 9.80205 20.1319V20.1319Z"
        fill="#4C4D52"
      />
      <path
        d="M13.0041 18.1764C12.2939 18.1759 11.6023 17.9496 11.0293 17.5301L10.9607 17.4801C10.2264 16.9391 9.73195 16.1331 9.58251 15.2333C9.43308 14.3335 9.64043 13.411 10.1605 12.6616L11.4845 10.7646C11.7402 10.3937 12.0676 10.0778 12.4473 9.83536C12.827 9.59295 13.2514 9.42897 13.6954 9.35303C14.1395 9.2771 14.5942 9.29071 15.0329 9.39318C15.4716 9.49564 15.8853 9.68491 16.2498 9.94969L16.3176 9.99896C17.0519 10.54 17.5463 11.346 17.6958 12.2458C17.8452 13.1456 17.6379 14.0681 17.1178 14.8174L15.7938 16.7145C15.4813 17.1653 15.0644 17.5339 14.5786 17.7887C14.0928 18.0435 13.5526 18.1771 13.0041 18.178V18.1764ZM14.2704 10.8199C14.1671 10.8201 14.064 10.8289 13.9623 10.8462C13.7147 10.8882 13.4781 10.9794 13.2664 11.1145C13.0547 11.2496 12.8723 11.4258 12.7299 11.6326L11.4059 13.5296C11.1111 13.9528 10.993 14.4742 11.0766 14.983C11.1601 15.4919 11.4388 15.948 11.8534 16.2547L11.9211 16.304C12.1203 16.4499 12.3467 16.5543 12.587 16.6111C12.8273 16.6678 13.0765 16.6758 13.3199 16.6344C13.5675 16.5924 13.8041 16.5011 14.0157 16.3661C14.2274 16.231 14.4099 16.0549 14.5522 15.848L15.8762 13.9509C16.171 13.5278 16.2892 13.0064 16.2056 12.4976C16.122 11.9887 15.8433 11.5326 15.4287 11.2259L15.361 11.1766C15.0448 10.9449 14.6631 10.8198 14.2711 10.8192L14.2704 10.8199Z"
        fill="#4C4D52"
      />
      <path
        d="M20.5804 11.1506C19.902 11.15 19.2402 10.9405 18.6849 10.5506C18.3143 10.2933 17.9986 9.96485 17.7564 9.58425C17.5141 9.20366 17.3501 8.77864 17.2739 8.33396L17.2439 8.16218C17.0841 7.27701 17.2795 6.36445 17.7879 5.62239C18.2962 4.88033 19.0765 4.36857 19.9597 4.19788L22.1163 3.80346C22.5482 3.72377 22.9917 3.73088 23.421 3.82424C23.8502 3.91759 24.2565 4.09537 24.6164 4.34728C24.987 4.60481 25.3026 4.93361 25.5448 5.31447C25.7869 5.69533 25.9507 6.12064 26.0267 6.56552L26.0575 6.73645C26.2175 7.62165 26.0221 8.53432 25.5137 9.27643C25.0053 10.0185 24.2249 10.5303 23.3417 10.7008L21.1851 11.0952C20.9856 11.1315 20.7832 11.1501 20.5804 11.1506V11.1506ZM22.7155 5.26464C22.6057 5.265 22.4962 5.275 22.3881 5.29463L20.2315 5.68896C19.7415 5.78648 19.3097 6.0733 19.0297 6.4871C18.7496 6.90091 18.644 7.40834 18.7357 7.89949L18.7658 8.07126C18.8083 8.31829 18.8995 8.55444 19.0339 8.76599C19.1684 8.97753 19.3435 9.16024 19.5491 9.3036C19.7448 9.44117 19.966 9.53837 20.1996 9.58942C20.4333 9.64047 20.6749 9.64443 20.9101 9.60099L23.0667 9.20657C23.5568 9.10905 23.9886 8.82232 24.2686 8.40852C24.5486 7.99471 24.6542 7.48719 24.5625 6.99604L24.5317 6.82511C24.4897 6.57737 24.3989 6.34047 24.2644 6.12822C24.1299 5.91598 23.9545 5.73256 23.7484 5.58882C23.4456 5.37768 23.0854 5.26426 22.7163 5.26379L22.7155 5.26464Z"
        fill="#C20044"
      />
      <path
        d="M46.1602 40.2496C45.2979 40.2491 44.4588 39.9701 43.7679 39.454C43.328 39.1286 42.9572 38.7189 42.6773 38.2488C42.3973 37.7787 42.2138 37.2576 42.1374 36.7158L41.7746 34.2241C41.6125 33.1474 41.8816 32.0502 42.5234 31.1706C43.1652 30.291 44.128 29.7 45.2028 29.5258C45.7283 29.445 46.2645 29.4696 46.7803 29.5984C47.296 29.7272 47.781 29.9576 48.2067 30.276C48.6465 30.601 49.017 31.0103 49.2967 31.4802C49.5764 31.9501 49.7597 32.4711 49.8357 33.0126L50.2 35.5042C50.2944 36.0891 50.2607 36.6876 50.1013 37.2582C49.9419 37.8288 49.6606 38.3581 49.2767 38.8094C48.8929 39.2608 48.4156 39.6234 47.878 39.8723C47.3403 40.1212 46.755 40.2504 46.1625 40.2511L46.1602 40.2496ZM45.8082 30.9946C45.6816 30.9944 45.5552 31.0039 45.43 31.0231C44.7504 31.1366 44.1429 31.5134 43.7392 32.0718C43.3355 32.6302 43.1682 33.3252 43.2734 34.0062L43.6362 36.497C43.684 36.8409 43.7999 37.172 43.9771 37.4706C44.1543 37.7692 44.3893 38.0295 44.6683 38.2362C44.9335 38.4347 45.2356 38.5784 45.557 38.6589C45.8783 38.7395 46.2125 38.7552 46.54 38.7053C47.2197 38.5918 47.8274 38.2149 48.2311 37.6563C48.6348 37.0977 48.8021 36.4025 48.6966 35.7214L48.3338 33.2305C48.2856 32.8867 48.1696 32.5559 47.9924 32.2573C47.8152 31.9587 47.5804 31.6984 47.3017 31.4914C46.8703 31.1693 46.3466 30.9951 45.8082 30.9946V30.9946Z"
        fill="#4C4D52"
      />
      <path
        d="M44.0216 28.5467C43.383 28.5463 42.7561 28.3756 42.2054 28.0522C41.7817 27.8057 41.4116 27.4771 41.1166 27.0856C40.8217 26.6941 40.6079 26.2476 40.4878 25.7723L39.8462 23.29C39.5984 22.3436 39.7325 21.3376 40.2197 20.4892C40.7069 19.6408 41.5081 19.0179 42.4504 18.7549L42.5012 18.7411C42.9623 18.616 43.4437 18.5843 43.9172 18.6478C44.3907 18.7113 44.8467 18.8687 45.2586 19.1107C45.6823 19.3572 46.0525 19.6859 46.3474 20.0774C46.6424 20.4689 46.8562 20.9153 46.9762 21.3906L47.617 23.8738C47.8653 24.82 47.7314 25.8259 47.2443 26.6743C46.7572 27.5226 45.956 28.1454 45.0137 28.408L44.9628 28.4219C44.6559 28.5045 44.3395 28.5465 44.0216 28.5467V28.5467ZM43.4401 20.132C43.2559 20.1322 43.0726 20.1565 42.8948 20.2044L42.8424 20.2183C42.2856 20.3768 41.8133 20.7477 41.5273 21.251C41.2413 21.7544 41.1646 22.35 41.3135 22.9095L41.9551 25.3927C42.0256 25.6738 42.1514 25.9381 42.3253 26.1699C42.4993 26.4018 42.7178 26.5965 42.968 26.7428C43.207 26.8834 43.4717 26.9749 43.7466 27.0116C44.0214 27.0484 44.3009 27.0298 44.5685 26.957L44.6193 26.9438C45.1772 26.7854 45.6504 26.4138 45.9366 25.9094C46.2228 25.405 46.2991 24.8082 46.149 24.2481V24.2481L45.5074 21.7649C45.4367 21.4839 45.3107 21.2199 45.1366 20.9883C44.9626 20.7568 44.744 20.5622 44.4937 20.4162C44.174 20.2297 43.8103 20.1316 43.4401 20.132V20.132Z"
        fill="#4C4D52"
      />
      <path
        d="M40.2381 18.1764C40.0486 18.1764 39.8595 18.1602 39.6727 18.1279C39.2262 18.0532 38.7992 17.8895 38.4171 17.6465C38.035 17.4036 37.7055 17.0865 37.4483 16.7139L36.1259 14.8137C35.6055 14.0645 35.3979 13.1418 35.5473 12.2419C35.6968 11.342 36.1915 10.536 36.9262 9.9952L36.9939 9.94509C37.3585 9.68049 37.7724 9.49152 38.2111 9.38923C38.6498 9.28695 39.1046 9.27348 39.5486 9.34956C39.9926 9.42563 40.4169 9.58967 40.7966 9.83217C41.1762 10.0747 41.5035 10.3907 41.7593 10.7616L43.0825 12.6586C43.6027 13.4079 43.8102 14.3306 43.6607 15.2304C43.5113 16.1302 43.0167 16.9362 42.2823 17.4771L42.2145 17.5264C41.6412 17.9471 40.9491 18.1748 40.2381 18.1764ZM38.9726 10.82C38.581 10.8205 38.1996 10.9454 37.8835 11.1767L37.815 11.2268C37.4005 11.5331 37.1218 11.9891 37.0384 12.4978C36.955 13.0064 37.0733 13.5276 37.3682 13.9503L38.6915 15.8474C38.834 16.0541 39.0164 16.2302 39.2281 16.3652C39.4397 16.5003 39.6763 16.5916 39.9238 16.6337C40.1672 16.6752 40.4164 16.6672 40.6567 16.6104C40.897 16.5537 41.1234 16.4492 41.3225 16.3033L41.3903 16.254C41.8052 15.9476 42.0842 15.4913 42.1678 14.9824C42.2514 14.4734 42.133 13.952 41.8378 13.529L40.5146 11.6319C40.3721 11.4252 40.1896 11.2491 39.978 11.1141C39.7663 10.979 39.5297 10.8877 39.2822 10.8455C39.1802 10.8282 39.0769 10.8194 38.9734 10.8193L38.9726 10.82Z"
        fill="#4C4D52"
      />
      <path
        d="M32.6645 11.1505C32.4617 11.1504 32.2593 11.1318 32.0599 11.095L29.9033 10.7007C29.0199 10.5304 28.2394 10.0186 27.731 9.27647C27.2225 8.53431 27.0272 7.62153 27.1875 6.7363L27.2175 6.56537C27.2936 6.12046 27.4576 5.69518 27.6999 5.31433C27.9421 4.93347 28.2578 4.60466 28.6285 4.34713C28.9881 4.09494 29.3943 3.91699 29.8235 3.82362C30.2526 3.73025 30.6961 3.7233 31.1279 3.80331L33.2845 4.19773C34.1678 4.36825 34.9483 4.87998 35.4568 5.62206C35.9653 6.36414 36.1609 7.27675 36.0011 8.16204L35.9703 8.33381C35.8943 8.77877 35.7304 9.20408 35.4881 9.58495C35.2458 9.96583 34.93 10.2946 34.5592 10.5521C34.0038 10.9408 33.3424 11.1496 32.6645 11.1505V11.1505ZM30.5294 5.26449C30.1594 5.26499 29.7983 5.37894 29.495 5.59103C29.289 5.73489 29.1137 5.9183 28.9793 6.13052C28.8448 6.34275 28.7539 6.57953 28.7117 6.82722L28.6817 6.99824C28.5898 7.48942 28.6953 7.99696 28.9753 8.41082C29.2554 8.82467 29.6873 9.11144 30.1774 9.20877L32.3341 9.6031C32.5693 9.64654 32.8108 9.64258 33.0445 9.59153C33.2782 9.54048 33.4994 9.44338 33.695 9.3058C33.9009 9.16267 34.0761 8.97996 34.2106 8.76838C34.3451 8.5568 34.4361 8.32059 34.4784 8.07347L34.5092 7.90169C34.6007 7.41057 34.495 6.90316 34.215 6.4894C33.9351 6.07565 33.5033 5.78887 33.0134 5.69116L30.8568 5.29684C30.7489 5.27609 30.6393 5.26494 30.5294 5.26365V5.26449Z"
        fill="#C20044"
      />
      <path
        d="M46.1783 43.5745C44.4373 43.6116 42.741 43.0213 41.399 41.9115C40.6125 41.2218 39.9696 40.3838 39.5071 39.4455C39.0445 38.5071 38.7714 37.4869 38.7033 36.443C38.4438 31.6678 37.6449 26.9373 36.3218 22.3418C35.3898 19.3418 34.2167 16.971 33.1045 15.8372C31.3653 14.1828 29.0447 13.2798 26.6447 13.3233C26.4437 13.3217 26.2516 13.2402 26.1106 13.0969C25.9696 12.9536 25.8913 12.7602 25.893 12.5592C25.8946 12.3582 25.976 12.1661 26.1193 12.0251C26.2626 11.8842 26.456 11.8058 26.657 11.8075C29.46 11.7683 32.1655 12.835 34.1875 14.7767C36.5551 17.1905 39.3818 24.1241 40.2152 36.3429C40.2672 37.1842 40.4844 38.0071 40.8545 38.7645C41.2245 39.5219 41.7401 40.1989 42.3718 40.757C43.011 41.2755 43.751 41.6556 44.5448 41.8732C45.3386 42.0908 46.1689 42.1411 46.9831 42.021C49.3778 41.7761 51.7423 39.6988 51.6268 35.6104C51.4989 31.0993 48.9826 12.8218 39.0037 5.81589L38.959 5.78515C35.3689 3.13216 31.0516 1.64394 26.5892 1.52119C26.4897 1.52059 26.3913 1.50033 26.2996 1.46168C26.2078 1.42303 26.1246 1.36673 26.0547 1.29592C25.9847 1.22511 25.9294 1.1412 25.8919 1.04902C25.8544 0.956835 25.8353 0.858211 25.836 0.758682C25.8366 0.659154 25.8568 0.560724 25.8954 0.469003C25.9341 0.377283 25.9904 0.294026 26.0612 0.224078C26.132 0.15413 26.2159 0.098855 26.3081 0.0613277C26.4003 0.0238005 26.4989 0.00477829 26.5985 0.00538517H26.6077C31.3764 0.128633 35.9921 1.71332 39.8309 4.5451L39.8748 4.57585C44.4568 7.79152 48.0261 13.4003 50.4838 21.2427C51.9505 25.8919 52.8434 30.7029 53.1426 35.5688C53.2211 37.3613 52.7628 39.1365 51.8263 40.6669C51.3202 41.4748 50.6332 42.154 49.8195 42.6508C49.0059 43.1477 48.0879 43.4487 47.138 43.5298C46.8191 43.561 46.4987 43.5759 46.1783 43.5745V43.5745Z"
        fill="#4C4D52"
      />
    </svg>
  );
}
