import { withApollo } from '@apollo/client/react/hoc';
import { GET_ORDER_BY_FILENAME } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { BaseDefinition, FileTypeZip } from './DataDefinitionFiles/Designer';
import { fileTypeFinder } from './fileTypeFinder';

class ParsingEngineDesign extends Component {
  state = {
    acceptedTypes: [
      { extension: ['zip'], definitionFile: FileTypeZip, type: null },
      {
        extension: ['jpg', 'jpeg', 'png'],
        definitionFile: BaseDefinition,
        type: 'quality_control_images',
      },
      {
        extension: ['stl'],
        definitionFile: BaseDefinition,
        type: 'stl_files',
      },
      {
        extension: ['pdf', 'mp4', 'mov'],
        definitionFile: BaseDefinition,
        type: 'attachments',
      },
      {
        extension: ['obj', 'mtl'],
        definitionFile: BaseDefinition,
        type: 'obj_files',
      },
    ],
    otherType: {
      definitionFile: BaseDefinition,
    },
    parsedCase: null,
  };

  async componentDidMount() {
    const { client, file } = this.props;
    const { data } = await client.query({
      query: GET_ORDER_BY_FILENAME,
      variables: {
        filename: file.name.replace(/\.[^.]+$/, ''),
        byHex: file.name.startsWith('fcf_'),
      },
      fetchPolicy: 'no-cache',
    });

    const parsedCase = await this.parseCase({ order: data.orderByFilename });

    this.setState({ parsedCase });
  }

  parseCase = ({ order }) => {
    const { file, designerId } = this.props;
    const { acceptedTypes, otherType } = this.state;

    const fileType = fileTypeFinder(acceptedTypes, otherType, file);

    return new fileType.definitionFile(
      file,
      designerId,
      order,
      fileType.type,
    ).buildCase();
  };

  render() {
    const { parsedCase } = this.state;
    const { render: renderProp, ...rest } = this.props;

    return renderProp({
      parsedCase,
      ...rest,
    });
  }
}

ParsingEngineDesign.propTypes = {
  render: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  designerId: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
};

export default withApollo(ParsingEngineDesign);
