import extractObject from './extractObject';

function parseNode(jsonObj, elementName) {
  const nodes = [];
  let nodesObj = [];

  const nodesList = jsonObj.DentalContainer.Object.Object.find(
    (obj) => obj.name === elementName,
  ).List;

  if ('Object' in nodesList) {
    nodesObj = nodesList.Object;
  }

  if ('Property' in nodesObj) {
    const nodeObj = extractObject(nodesObj.Property);
    nodes.push(nodeObj);
    return nodes;
  }
  for (const obj of nodesObj) {
    const nodeObj = extractObject(obj.Property);
    nodes.push(nodeObj);
  }

  return nodes;
}

export default parseNode;
