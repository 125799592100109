import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Spring } from 'react-spring';
import { MovementTableWithViewerContext } from '../../../../shared/Context/MovementTableWithViewerContext';
import { clamp, getTouchId, getTouchPosition, omit, range } from './helpers';

function TouchMoveRecord(e) {
  const { x, y } = getTouchPosition(e);
  this.x = x;
  this.y = y;
  this.time = Date.now();
}

const defaultProps = {
  cardSize: 40,
  defaultCursor: 0,
  autoplay: 0,
  renderCard:()=> ({}),
  moveScale: 1,
  onRest:()=> ({}),
  onDragStart:()=> ({}),
  onDragEnd:()=> ({}),
  onDragCancel:()=> ({}),
  maxOverflow: 0.5,
  clickTolerance: 2,
  ignoreCrossMove: true,
  stages: [],
};

const propsKeys = Object.keys(defaultProps);

class TouchSlider extends PureComponent {
  static contextType = MovementTableWithViewerContext;

  previousContext;

  constructor(props) {
    super(props);

    this.state = {
      cursor: props.defaultCursor,
      active: false,
      dragging: false,
      springing: false,
      moding: false,
    };

    this.usedCursor = 0;
    this.touchCount = 0;
    this.touchMoves = [];
    this.autoplayTimer = null;
    this.grabbing = false;
    this.tracingTouchId = null;
    this.isMovingCross = null;
  }

  componentDidMount() {
    this.autoplayIfEnabled();
    this.previousContext = this.context;
  }

  componentDidUpdate(prevProps, prevState) {
    const { autoplay } = this.props;
    const { cursor } = this.state;
    const { setActiveStage, activeStage } = this.context;

    if (setActiveStage && activeStage && cursor !== prevState.cursor) {
      setActiveStage(Math.round(Math.abs(cursor) + 1));
    }

    if (this.previousContext.activeStage !== activeStage) {
      this.setState({
        cursor: -(activeStage - 1),
      });
    }

    if (prevProps.autoplay !== autoplay) {
      this.stopAutoplay();
      this.autoplayIfEnabled();
    }

    this.previousContext = this.context;
  }

  componentWillUnmount() {
    this.stopAutoplay();
  }

  onTouchStart = (e) => {
    const { cursor } = this.state;
    const oldTouchCount = this.touchCount;
    this.touchCount += e.changedTouches.length;
    this.setState({ active: true });
    this.stopAutoplay();
    this.tracingTouchId = getTouchId(e);
    this.touchMoves = [new TouchMoveRecord(e)];
    this.isMovingCross = null;
    const { cardSize, clickTolerance } = this.props;
    // User click a card before it's in place but near, allow the clicking.
    // Otherwise it's only a grab.
    this.grabbing =
      cardSize * Math.abs(this.usedCursor - cursor) > clickTolerance;
    // When user clicks or grabs the scroll, cancel the spring effect.
    if (!oldTouchCount) {
      this.setCursor(this.usedCursor);
    }
  };

  onTouchMove = (e) => {
    const { active, dragging, cursor } = this.state;
    this.grabbing = false;
    const touchMove = new TouchMoveRecord(e);
    const touchId = getTouchId(e);
    if (touchId !== this.tracingTouchId || this.touchMoves.length === 0) {
      this.touchMoves = [touchMove];
    }
    this.tracingTouchId = touchId;

    let shouldIgnore = e.defaultPrevented;
    if (!shouldIgnore && active) {
      if (this.isMovingCross == null) {
        const { ignoreCrossMove } = this.props;
        let factor = ignoreCrossMove;
        if (typeof factor !== 'number') {
          factor = factor ? 1 : 0;
        }
        const mainAxis = 'x';
        const crossAxis = 'y';
        const deltMain = Math.abs(
          touchMove[mainAxis] - this.touchMoves[0][mainAxis],
        );
        const deltCross = Math.abs(
          touchMove[crossAxis] - this.touchMoves[0][crossAxis],
        );
        this.isMovingCross = deltCross * factor > deltMain;
      }
      shouldIgnore = this.isMovingCross;
    }

    if (shouldIgnore) {
      return;
    }

    // Prevent the default action i.e. page scroll.
    // NOTE: in Chrome 56+ touchmove event listeners are passive by default,
    // please use CSS `touch-action` for it.
    e.preventDefault();

    const { cardSize, moveScale, onDragStart } = this.props;
    const lastMove = this.touchMoves[this.touchMoves.length - 1];
    const distance = touchMove.x - lastMove.x;
    this.setState({ dragging: true }, dragging ? undefined : onDragStart);
    this.setCursor(cursor + (distance / cardSize) * moveScale);

    this.touchMoves.push(touchMove);
    if (this.touchMoves.length > 250) {
      this.touchMoves.splice(0, 40);
    }
  };

  onTouchEndOrCancel = (e) => {
    const { dragging, cursor } = this.state;
    const { type } = e;
    this.touchCount -= e.changedTouches.length;
    if (this.touchCount > 0) {
      this.touchMoves = [];
      return;
    }

    // prevent click event for grab actions
    if (this.grabbing) {
      e.preventDefault();
      e.stopPropagation();
    }

    const wasDragging = dragging;
    let targetCursor = null;
    // Due to multi-touch, records can be empty even if .dragging is true.
    // So check both.
    if (wasDragging && this.touchMoves.length > 0) {
      const { cardSize, moveScale } = this.props;
      const friction = 24 / 1e6;
      const { touchMoves } = this;
      let i = touchMoves.length;
      let duration = 0;

      // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
      while ((i -= 1) >= 0 && duration < 100) {
        duration = Date.now() - touchMoves[i].time;
      }

      i += 1;

      const touchMoveVelocity =
        (getTouchPosition(e).x - touchMoves[i].x) / duration;
      const momentumDistance =
        (touchMoveVelocity * Math.abs(touchMoveVelocity)) / friction / 2;
      const cursorDelta = clamp(
        (momentumDistance / cardSize) * moveScale,
        Math.floor(cursor) - cursor,
        Math.ceil(cursor) - cursor,
      );

      targetCursor = Math.round(cursor + cursorDelta);
      this.touchMoves = [];
    } else {
      // User grabs and then releases without any move in between.
      // Snap the cursor.
      targetCursor = Math.round(cursor);
    }

    this.setState({ active: false, dragging: false }, () => {
      this.setCursor(targetCursor);
      if (wasDragging) {
        this.props[type === 'touchend' ? 'onDragEnd' : 'onDragCancel']();
      }
    });
    this.tracingTouchId = null;
    this.autoplayIfEnabled();
  };

  onSpringRest = () => {
    const { cardCount, onRest } = this.props;
    if (!this.shouldEnableSpring()) {
      return;
    }
    this.setState({ springing: false });
    const cursor = Math.round(this.usedCursor);
    const index = -cursor;
    let modIndex = index % cardCount;
    while (modIndex < 0) {
      modIndex += cardCount;
    }
    onRest(index, modIndex, cursor, this.state);
  };

  setCursor = (cur) => {
    const { cursor } = this.state;
    const springing = this.shouldEnableSpring() && cur !== cursor;
    return new Promise((resolve) => {
      this.setState({ cursor: cur, springing }, resolve);
    });
  };

  getComputedCursor() {
    const { cardCount, maxOverflow } = this.props;
    const { cursor, dragging } = this.state;
    let computedCursor = cursor;

    computedCursor = clamp(computedCursor, 1 - cardCount, 0);
    if (dragging && cursor > 0) {
      computedCursor = maxOverflow - maxOverflow / (cursor + 1);
    } else if (dragging && cursor < 1 - cardCount) {
      computedCursor =
        1 -
        cardCount -
        maxOverflow +
        maxOverflow / (1 - cardCount - cursor + 1);
    }

    return computedCursor;
  }

  next = () => {
    const { stages, setTriggerIncrementOn } = this.props;
    const { cursor } = this.state;
    const lastVal = stages[stages.length - 1];
    if (lastVal.index * -1 === cursor) {
      this.setCursor(0);
      setTriggerIncrementOn({ target: { value: 0 } });
    } else {
      this.setCursor(cursor - 1);
      setTriggerIncrementOn({ target: { value: Math.abs(cursor - 1) } });
    }
  };

  go = (n) => {
    const { triggerIncrement, setTriggerIncrementOff } = this.props;

    if (triggerIncrement) {
      this.setCursor(n);
    }

    setTriggerIncrementOff();
  };

  goToClick = (n) => {
    const { changeSlider } = this.props;

    this.setState({ cursor: n * -1 }, () => {
      changeSlider({ target: { value: `${n}` } });
    });
  };

  autoplayIfEnabled = () => {
    const { autoplay } = this.props;
    if (autoplay) {
      this.autoplayTimer = setInterval(this.next, autoplay);
    }
  };

  stopAutoplay = () => {
    if (this.autoplayTimer) {
      clearInterval(this.autoplayTimer);
      this.autoplayTimer = null;
    }
  };

  shouldEnableSpring = () => {
    const { active, moding } = this.state;
    return !(active || moding);
  };

  render() {
    const {
      component: Component,
      cardSize,
      cardCount,
      renderCard,
      stages,
      sliderVal,
      triggerIncrement,
      setTriggerIncrementOff,
      setTriggerIncrementOn,
      ...rest
    } = this.props;

    const computedCursor = this.getComputedCursor();

    return (
      <Spring
        config={{ precision: 0.001, tension: 150, friction: 25 }}
        from={{ cursor: computedCursor }}
        immediate={!this.shouldEnableSpring()}
        to={{ cursor: computedCursor }}
        onRest={this.onSpringRest}
      >
        {({ cursor }) => {
          this.usedCursor = cursor;

          return (
            <Component
              {...omit(rest, propsKeys)}
              cursor={cursor}
              carouselState={this.state}
              onTouchStart={this.onTouchStart}
              onTouchMove={this.onTouchMove}
              onTouchEnd={this.onTouchEndOrCancel}
              onTouchCancel={this.onTouchEndOrCancel}
              stages={stages}
              go={this.go}
              sliderVal={sliderVal}
              triggerIncrement={triggerIncrement}
              setTriggerIncrementOff={setTriggerIncrementOff}
            >
              {range(0, cardCount - 1).map((index) => {
                let modIndex = index % cardCount;
                while (modIndex < 0) {
                  modIndex += cardCount;
                }
                return renderCard({
                  index,
                  modIndex,
                  stages,
                  go: this.goToClick,
                  carouselState: this.state,
                });
              })}
            </Component>
          );
        }}
      </Spring>
    );
  }
}

TouchSlider.propTypes = {
  autoplay: PropTypes.number,
  cardSize: PropTypes.number,
  cardCount: PropTypes.number.isRequired,
  defaultCursor: PropTypes.number,
  renderCard: PropTypes.func,
  moveScale: PropTypes.number,
  onRest: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragCancel: PropTypes.func,
  maxOverflow: PropTypes.number,
  clickTolerance: PropTypes.number,
  ignoreCrossMove: PropTypes.bool,
  stages: PropTypes.array,
  component: PropTypes.func.isRequired,
  sliderVal: PropTypes.number.isRequired,
  changeSlider: PropTypes.func.isRequired,
  triggerIncrement: PropTypes.bool.isRequired,
  setTriggerIncrementOff: PropTypes.func.isRequired,
  setTriggerIncrementOn: PropTypes.func.isRequired,
};

TouchSlider.defaultProps = defaultProps;

export default TouchSlider;
