import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { GuideBody, GuideHeader } from './components';

function GuideModal({
  labLocationGuideCategoryGroups,
  hideApplyAll = false,
  values,
  orderItemIndex,
  showGuideModal,
  ...rest
}) {
  const props = {
    labLocationGuideCategoryGroups,
    hideApplyAll,
    values,
    orderItemIndex,
    showGuideModal,
    ...rest,
  };
  const [filteValue, setFilterValue] = useState('');
  const [designType, setDesignType] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [initialOpen, setInitialOpen] = useState(true);
  const [activeTabs, setActiveTabs] = useState({});
  const [scrollCategory, setScrollCategory] = useState(null);
  const [displayedComponent, setDisplayedComponent] = useState('categories');

  const guideCategoryGroupsCurrent = useMemo(() => {
    const foundLabGuideCategoryGroups = labLocationGuideCategoryGroups.find(
      (item) =>
        item?.designTypeId === values.orderItems[orderItemIndex]?.designTypeId,
    );

    setDesignType({
      designTypeId: foundLabGuideCategoryGroups?.designTypeId,
      designTypeName: foundLabGuideCategoryGroups?.designTypeName,
    });

    const isFav = foundLabGuideCategoryGroups?.guideCategoryGroups.some(
      (item) => item.guideCategories.some(({ favorite }) => favorite),
    );

    if (isFav && initialOpen) {
      setDisplayedComponent('favorites');
    }

    return foundLabGuideCategoryGroups?.guideCategoryGroups;
  }, [labLocationGuideCategoryGroups, orderItemIndex, values.orderItems]);

  const favoriteData = useMemo(
    () =>
      filteredData?.reduce((array, item) => {
        item.guideCategories.forEach((guideCategory) => {
          if (guideCategory.favorite) {
            array.push({ ...guideCategory, guideCategoryGroupId: item.id });
          }
        });

        return array;
      }, []),
    [filteredData],
  );

  useEffect(() => {
    if (
      showGuideModal &&
      filteredData &&
      filteredData.length > 0 &&
      initialOpen
    ) {
      const selectedGuides = values.orderItems[
        orderItemIndex
      ].guideCodes?.reduce((obj, item) => {
        obj[item.categoryId] = true;
        return obj;
      }, {});
      const activeTabsDisplay = filteredData.reduce((obj, item, idx) => {
        for (const guideCategory of item.guideCategories) {
          if (selectedGuides?.[guideCategory.id]) {
            obj[idx] = true;
            return obj;
          }
        }

        return obj;
      }, {});

      setActiveTabs(activeTabsDisplay);
      setInitialOpen(false);
    }
  }, [showGuideModal, filteredData]);

  const filterSearchHandler = (value) => {
    const ulimateValue = value.toLowerCase().trim();
    setFilterValue(ulimateValue);

    const updatedFilteredData = guideCategoryGroupsCurrent?.reduce(
      (array, item) => {
        if (item.name.toLowerCase().includes(ulimateValue)) {
          array.push(item);
          return array;
        }

        const updatedArrayGuideCategories = item.guideCategories.filter(
          (guideCategory) =>
            guideCategory.name.toLowerCase().includes(ulimateValue) ||
            guideCategory.shortDescription
              ?.toLowerCase()
              .includes(ulimateValue),
        );
        const updatedItem = { ...item };
        updatedItem.guideCategories = updatedArrayGuideCategories;

        if (updatedArrayGuideCategories.length > 0) {
          array.push(updatedItem);
        }

        return array;
      },
      [],
    );
    setFilteredData(updatedFilteredData);
  };

  useEffect(() => {
    if (guideCategoryGroupsCurrent) {
      setFilteredData(guideCategoryGroupsCurrent);
    }

    if (filteValue) {
      filterSearchHandler(filteValue);
    }
  }, [guideCategoryGroupsCurrent]);

  const displayedComponentHandler = (name) => {
    setDisplayedComponent(name);
  };

  const resetData = () => {
    setFilterValue(null);
    setFilteredData(guideCategoryGroupsCurrent);
  };

  const activeTabsHandler = (index) => {
    setActiveTabs((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return showGuideModal ? (
    <div className={`modal ${showGuideModal ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div
        className="modal-card"
        style={{
          width: '95%',
          height: '85vh',
        }}
      >
        <header className="modal-card-head is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
          <GuideHeader
            fitlerSearch={filterSearchHandler}
            filteValue={filteValue}
            designType={designType}
            hideApplyAll={hideApplyAll}
            {...props}
          />
        </header>

        <div className="modal-card-body" style={{ padding: 0 }}>
          {guideCategoryGroupsCurrent ? (
            <GuideBody
              favoriteData={favoriteData}
              data={filteredData || []}
              resetData={resetData}
              setDisplayedComponent={displayedComponentHandler}
              filteValue={filteValue}
              activeTabs={activeTabs}
              scrollCategory={scrollCategory}
              designType={designType}
              scrollToCategory={setScrollCategory}
              displayedComponent={displayedComponent}
              menuData={guideCategoryGroupsCurrent}
              setActiveTabs={activeTabsHandler}
              {...props}
            />
          ) : (
            <p style={{ paddingTop: 100, paddingLeft: 20 }}>
              We could not find any Categories. Try select another Design Type.
            </p>
          )}
        </div>
      </div>
    </div>
  ) : null;
}

GuideModal.propTypes = {
  labLocationGuideCategoryGroups: PropTypes.array.isRequired,
  hideApplyAll: PropTypes.bool,
  values: PropTypes.object.isRequired,
  orderItemIndex: PropTypes.number.isRequired,
  showGuideModal: PropTypes.bool.isRequired,
};

export default GuideModal;
