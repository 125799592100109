import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { NonPassiveTouchTarget, touchWithMouseHOC } from './helpers';

function SliderContainer({
  carouselState: { active, dragging, cursor: cur, springing, moding },
  dimensions,
  go,
  sliderVal,
  triggerIncrement,
  setTriggerIncrementOff,
  cursor = 0,
  stages = [],
  cardSize = 40,
  ...rest
}) {
  let current = -Math.round(cursor) % stages.length;

  while (current < 0) {
    current += stages.length;
  }

  const translateX = cursor * cardSize + (dimensions.width - cardSize) / 2;

  useEffect(() => {
    if (
      Math.abs(sliderVal) !== Math.abs(cur) &&
      Math.abs(sliderVal) !== Math.abs(cursor) &&
      triggerIncrement &&
      Number.isInteger(cursor) &&
      !dragging &&
      !active &&
      !springing &&
      !moding
    ) {
      go(-1 * sliderVal);
      setTriggerIncrementOff();
    }
  }, [
    cur,
    go,
    sliderVal,
    active,
    dragging,
    springing,
    cursor,
    triggerIncrement,
    setTriggerIncrementOff,
    moding,
  ]);

  return (
    <NonPassiveTouchTarget
      className={`carousel-container ${active ? 'is-active' : ''}${
        dragging ? 'is-dragging' : ''
      }`}
    >
      <NonPassiveTouchTarget
        className="carousel-track"
        style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
        {...rest}
      />
    </NonPassiveTouchTarget>
  );
}

SliderContainer.propTypes = {
  cursor: PropTypes.number,
  carouselState: PropTypes.object.isRequired,
  stages: PropTypes.array,
  cardSize: PropTypes.number,
  dimensions: PropTypes.object.isRequired,
  go: PropTypes.func.isRequired,
  sliderVal: PropTypes.number.isRequired,
  triggerIncrement: PropTypes.bool.isRequired,
  setTriggerIncrementOff: PropTypes.func.isRequired,
};

export default touchWithMouseHOC(SliderContainer);
