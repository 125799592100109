import {
  Environment,
  Lightformer,
  OrbitControls,
  PerspectiveCamera,
  Preload,
  useContextBridge,
} from '@react-three/drei';

import { Canvas } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { OrthoContext } from '../../../shared/Context/OrthoContext';
import GridOverlay from './GridOverlay/GridOverlay';
import ThreeLoader from './Loader';
import ObjGroup from './ObjGroup/ObjGroup';

function ThreeViewer({ orbit = null, cameraRef = null }) {
  const ContextBridge = useContextBridge(OrthoContext);

  return (
    <Canvas shadows="soft" frameloop="demand">
      <ContextBridge>
        <Suspense fallback={<ThreeLoader loading />}>
          <ambientLight intensity={1} />
          <Environment files="https://cdn.fullcontour.com/backgrounds/dikhololo_night_1k.hdr">
            <Lightformer
              intensity={25}
              rotation-x={Math.PI / 2}
              position={[0, 300, -500]}
              scale={[200, 100, 10]}
            />
            <Lightformer
              intensity={25}
              rotation-y={Math.PI / 2}
              position={[-500, 100, -100]}
              scale={[200, 100, 10]}
            />
            <Lightformer
              intensity={25}
              rotation-y={-Math.PI / 2}
              position={[500, -100, -100]}
              scale={[200, 100, 10]}
            />
            <Lightformer
              intensity={25}
              rotation-x={-Math.PI / 2}
              position={[0, -200, 500]}
              scale={[200, 200, 10]}
            />
          </Environment>

          <Preload all />
          <PerspectiveCamera
            makeDefault
            fov={26}
            near={1}
            far={800}
            position={[0, 0, 140]}
            ref={cameraRef}
          />

          <ObjGroup />
          <OrbitControls ref={orbit} />
          <GridOverlay />
        </Suspense>
      </ContextBridge>
    </Canvas>
  );
}

ThreeViewer.propTypes = {
  orbit: PropTypes.object,
  cameraRef: PropTypes.object,
};

export default ThreeViewer;
