import { useQuery } from '@apollo/client';
import { GET_CUSTOMER_LOC_BILLING } from '@fullcontour/shared-api';
import { useParams } from 'react-router';
import { BaseLoader } from '../../../shared';
import { Billing } from '../../Billing';

function LocationCustomerBilling(props) {
  const { slug } = useParams();

  const variables = slug ? { slug } : {};

  const { error, loading, data } = useQuery(GET_CUSTOMER_LOC_BILLING, {
    variables,
    fetchPolicy: 'network-only',
  });

  if (error) {
    return <p>Error :(</p>;
  }
  if (loading) {
    return <BaseLoader title="Billing Data" loading />;
  }

  return (
    <Billing customer={data.customerLocation} type="location" {...props} />
  );
}

export default LocationCustomerBilling;
