import { MovementTableWithViewerContext } from '@fullcontour/threed';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  fdiMapping,
  palmerNotationDisplay,
} from '../../../../../../transforms';
import { headerIPR } from '../helpers';

const arrOfKeys = [...new Array(32).keys()];
const TEETH_HEADER = arrOfKeys.splice(0, 16).concat(arrOfKeys.reverse());
const MOVEMENT_NAMINGS = {
  Inclination: 'Inclination(deg)',
  Angulation: 'Angulation(deg)',
  Rotation: 'Rotation(deg)',
  LeftRight: 'Mesial/Distal(mm)',
  ForwardBackward: 'Buccal/Lingual(mm)',
  IntrusionExtrusion: 'Intrusion/Extrusion(mm)',
};

const floatAggregatedValue = (aggregatedValue) =>
  aggregatedValue &&
  aggregatedValue.toFixed(1) >= 0.1 &&
  aggregatedValue.toFixed(1);
function TeethChartTable({ location, dentalNotation = null }) {
  const { t } = useTranslation('orderactions');

  const { headerTableData, bodyTeethData } = useContext(
    MovementTableWithViewerContext,
  );

  function headerBasedOnNotation(item) {
    const { numberingSystem: locationNotation } = location;
    const numberingSystem = dentalNotation || locationNotation;

    if (numberingSystem === 'uns') {
      return item;
    }

    if (numberingSystem === 'palmer') {
      return palmerNotationDisplay(item);
    }

    if (numberingSystem === 'fdi') {
      return fdiMapping[item];
    }

    return null;
  }

  if (!headerTableData) {
    return null;
  }

  const headerData = TEETH_HEADER.map((item) => {
    const { style, aggregatedValue, correctItemCount } = headerIPR(
      item,
      headerTableData,
    );

    const showIPR = aggregatedValue && style && Object.keys(style).length > 0;

    return (
      <th
        key={item}
        className="teeth-chart__header-table"
        style={{
          ...(showIPR ? style : {}),
          ...(item === 15 ? { borderRight: '1px solid black' } : {}),
        }}
      >
        <div
          className={`teeth-chart__header-cell ${
            headerTableData[correctItemCount]?.attachments
              ? 'teeth-chart__attachments'
              : ''
          }${!headerTableData[item + 1] ? 'teeth-chart__missing-tooth' : ''}`}
          style={{
            color: 'rgba(80,80,80,.87)',
          }}
        >
          {headerBasedOnNotation(item + 1)}
        </div>
        {showIPR ? (
          <span
            className="teeth-chart__interproximal-reduction-text"
            style={{ left: '80%' }}
          >
            {floatAggregatedValue(aggregatedValue)}
          </span>
        ) : null}
      </th>
    );
  });

  return (
    <div className="teeth-chart__table-wrapper">
      <table className="teeth-chart__table-namings is-striped is-bordered table">
        <thead>
          <tr>
            <th style={{ height: 53 }} />
          </tr>
        </thead>
        <tbody>
          {Object.keys(bodyTeethData).map((item) => (
            <tr key={item}>
              <td key={item}>{t(MOVEMENT_NAMINGS[item])}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ width: '89%' }} className="table-container">
        <table className="teeth-chart__table-data is-bordered is-striped is-hoverable is-fullwidth table">
          <thead>
            <tr>{headerData}</tr>
          </thead>

          <tbody>
            {Object.keys(bodyTeethData).map((item) => (
              <tr key={item}>
                {bodyTeethData[item]
                  .splice(0, 16)
                  .concat(bodyTeethData[item].reverse())
                  .map((tst, idx) => (
                    <td
                      key={tst + Math.random()}
                      className="teeth-chart__table-cell"
                      style={{
                        ...(idx === 15
                          ? { borderRight: '1px solid black' }
                          : {}),
                      }}
                    >
                      {!tst ||
                      ['0.00', '-0.00'].includes(tst.replace(/[DMLVEIB-]/g, ''))
                        ? null
                        : tst}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

TeethChartTable.propTypes = {
  location: PropTypes.object.isRequired,
  dentalNotation: PropTypes.string,
};

export default TeethChartTable;
