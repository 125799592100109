import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import GuideModal from './GuideModal';
import GuideSelectionPopup from './GuideSelectionPopup';

function GuideSelection(props) {
  const {
    index,
    values: { orderItems, businessUnitName },
    designTypes,
    setShouldShow,
    labLocationGuideCategoryGroups,
    setValue,
  } = props;

  const [showGuideModal, setShowGuideModal] = useState(false);
  if (businessUnitName === '') {
    return 'Please first select a category';
  }
  if (!designTypes[businessUnitName]) {
    return 'No Design Types available';
  }
  const selectedDesignType = designTypes[businessUnitName].find(
    ({ id }) => id === orderItems[index].designTypeId,
  );
  // if (!selectedDesignType?.designGuideCategories) {
  //   return null;
  // }
  function guideModalOpen() {
    setShowGuideModal(true);
  }
  function guideModalClose() {
    setShowGuideModal(false);
  }
  const childrenProps = {
    ...props,
    guideModalClose,
    guideModalOpen,
    currentOrderItem: orderItems[index],
    showGuideModal,
    designGuideCategories: selectedDesignType?.designGuideCategories,
    orderItemIndex: index,
  };
  // if (labLocationGuideCategoryGroups) {
  const isDesignGuideExist = labLocationGuideCategoryGroups.some(
    ({ designTypeId }) => designTypeId === selectedDesignType?.id,
  );

  useEffect(() => {
    if (!isDesignGuideExist) {
      setShouldShow(false);
      // Set defaults
    } else {
      const gcs = determineCodes(selectedDesignType);
      setValue([...gcs]);
    }
  }, [labLocationGuideCategoryGroups]);

  function determineCodes(designType) {
    const guideCategoryGroupsOfDesignType = labLocationGuideCategoryGroups.find(
      (item) => item.designTypeId === designType.id,
    );
    if (!Array.isArray(orderItems[index].guideCodes)) {
      return [];
    }
    const gc = [...orderItems[index].guideCodes];
    guideCategoryGroupsOfDesignType?.guideCategoryGroups.forEach(
      (categoryGroup) => {
        categoryGroup.guideCategories?.forEach((guideCategory) => {
          guideCategory.guideCodes.forEach((guideCode) => {
            if (
              guideCode.default === true &&
              !gc.some(
                (item) =>
                  item.codeId === guideCode.id ||
                  item.categoryId === guideCode.guideCategoryId,
              )
            ) {
              gc.push(parseGuideCode(guideCategory, guideCode));
            }
          });
        });
      },
    );
    return gc;
  }
  function parseGuideCode(guideCategory, guideCode) {
    return {
      categoryId: guideCode.guideCategoryId,
      isDefault: guideCode.default,
      codeId: guideCode.id,
      categoryName: guideCategory.name,
      showDropdown: guideCategory.showDropdown,
      codes: [guideCode.name],
      fileUrl: guideCode.imageUrl || guideCategory.imageUrl,
      categoryAdditionalInfo: {
        guideCategoryImages: guideCategory.guideCategoryImages,
        fullDescription: guideCategory.description,
      },
      description: guideCategory.showDropdown
        ? guideCode.description || guideCategory.short_description
        : guideCategory.short_description,
    };
  }
  return (
    <>
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'top',
          width: '25px',
          height: '25px',
          flexBasis: '25px',
          flexShrink: 0,
        }}
      >
        <GuideSelectionPopup {...childrenProps} />
      </div>
      <GuideModal {...childrenProps} />
    </>
  );
}

GuideSelection.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  designTypes: PropTypes.object.isRequired,
  setShouldShow: PropTypes.func.isRequired,
  labLocationGuideCategoryGroups: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default GuideSelection;
