import { useMutation } from '@apollo/client';
import { CurrentUserContext, Message } from '@fullcontour/common';
import { ACTION_CURRENT_USER_DEACTIVATE_HELP } from '@fullcontour/shared-api';
import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './HelpCenter.css';
import Styles from './HelpCenter.module.css';

function HelpCenter() {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [dismissed, setDismissed] = useState(currentUser?.dismissedHelp);

  const [update] = useMutation(ACTION_CURRENT_USER_DEACTIVATE_HELP, {
    onCompleted: (data) => {
      const { errorMessages } = data.actionCurrentUserDeactivateHelp;

      if (errorMessages.length === 0) {
        setCurrentUser();
        setDismissed(true);
      }
    },
  });

  function dismissHelp() {
    update({
      variables: {
        input: {
          clientMutationId: uuidv4(),
        },
      },
    });
  }

  return (
    <>
      <div className={Styles.sectionContainer}>
        <h3 className="title is-3 has-text-grey-dark">Getting Started</h3>
        {dismissed && (
          <Message
            type="info"
            title="Welcome to 3Shape Design Service"
            dismissable
            onDismiss={dismissHelp}
            headerType="has-background-primary"
          >
            <p className="has-text-dark">
              As a new customer we’ve redirected you to our Getting Started
              page. You can always revisit this page, Help Center or write us in
              the chat.
            </p>
          </Message>
        )}
      </div>
      <div className={Styles.sectionContainer} id="submit-first-order">
        <div className={Styles.subsectionContainerHeader}>
          <h4 className="title is-4">
            <a href="#submit-first-order">Submitting your first case</a>
          </h4>
          <p>
            The following explains how to submit 3Shape cases to the 3Shape
            Design Service platform
          </p>
        </div>

        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 1</h6>
            <p>Export your case from 3Shape: Right Click, Advanced export</p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/1A.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 2</h6>
            <p>Export your complete 3Shape case as &quot;File&quot;</p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/2A.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 3</h6>
            <p>
              Save the newly exported file in a location you can easily access.
            </p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/3A.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={Styles.subsectionContainer}
          style={{ marginBottom: '2em' }}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 4</h6>
            <p>Log into your 3Shape Design Service Account</p>
          </div>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 5</h6>
            <p>
              Pull up your 3Shape file and Drag & Drop it on to the 3Shape
              Design Service platform
            </p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/5A.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 6</h6>
            <p>Fill in the information on the upload page</p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/6A.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 7</h6>
            <p>
              Use the Guide button to choose the Design Guide code(s) you would
              like implemented in the design of your case. Once you click on the
              Guide button, the Indication based Design Guide will appear.
              Choose the code and click away to save the selection
            </p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/8AA.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 8</h6>
            <p>
              Once all the information is complete, Hit the gray &quot;Send New
              Cases&quot; button
            </p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/7A.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={Styles.subsectionContainer}
          style={{ marginBottom: '2em' }}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 9</h6>
            <p>
              Make sure you do not close out of your browser before the case has
              moved out of the window and the progress bar is complete
            </p>
          </div>
        </div>

        <p>
          For all other file types, please contact Support at
          <a href="mailto: designservices@3shape.com">
            designservices@3shape.com
          </a>
          should you need further assistance.
        </p>
      </div>

      <div className={Styles.sectionContainer} id="submit-dental-system">
        <div className={Styles.subsectionContainerHeader}>
          <h4 className="title is-4">
            <a href="#submit-dental-system">
              Submitting your cases using the Design Service Button in 3Shape
            </a>
          </h4>
          <p>Here we will explain how to submit an case via Dental System</p>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6 mb-1">Step 1</h6>
            <p>Open Dental System</p>
            <h6 className="title is-6 mt-4 mb-1">Step 2</h6>
            <p>
              Select the case(s) you would like to send to 3Shape Design Service
            </p>
            <h6 className="title is-6 mt-4 mb-1">Step 3</h6>
            <p>Click the Design Service button</p>
            <h6 className="title is-6 mt-4 mb-1">Step 4</h6>
            <p>
              In the dialogue box that pops up, add your 3Shape Design Service
              username and Password (this is only done the once as you send your
              first case through the 3Shape Design Service Button)
            </p>
            <h6 className="title is-6 mt-4 mb-1">Step 5</h6>
            <p>
              Confirm that you want to send the case(s)
              <br />
              The 3Shape Design Service Platform will launch in the browser
              where you can see your case(s) and modify as necessary (Changing
              the turn around time, choosing &quot;Require design
              approval&quot;, etc)
            </p>
            <h6 className="title is-6 mt-4 mb-1">Step 6</h6>
            <p className="mb-4">
              The case(s) will land in the &quot;Pending Cases&quot; status -
              Click submit on the 3Shape Design Service Platform and you’re
              finished!
              <br />
              Note: The case(s) should join any other pending cases you have on
              the platform in the “sent for design” category.
            </p>
            <small>
              Please watch the short video the 3Shape Design Service Button for
              a complete overview of sending cases using this feature!
            </small>
          </div>
          <div className={Styles.imgCol}>
            <div className="videoContainer">
              <iframe
                title="fc ds button"
                src="https://www.youtube.com/embed/kl5esGz23EA"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="video"
              />
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.sectionContainer} id="update-profile">
        <div className={Styles.subsectionContainerHeader}>
          <h4 className="title is-4">
            <a href="#update-profile">How to update your profile</a>
          </h4>
          <p>
            Here we will explain how to set up notifications, update a password,
            or edit basic information on your User Profile
          </p>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 1</h6>
            <p>
              Click on your Username in the top right corner of the platform,
              then click on &quot;Profile&quot;
            </p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/1B.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 2</h6>
            <p>
              Click on Notifications on the left side of the screen, then check
              off any of the settings you would want to get notified for and
              click &quot;Submit&quot; in the bottom left corner
            </p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/2B.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 3</h6>
            <p>Under your profile, you can also update/change your password</p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/3B.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 4</h6>
            <p>
              You are also able to edit your &quot;Basic Info&quot; which
              includes your First & Last Name, Username for the platform and
              your phone number
            </p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/4B.jpg"
              alt="submit orders"
            />
          </div>
        </div>
      </div>

      <div className={Styles.sectionContainer} id="update-design-preferences">
        <div className={Styles.subsectionContainerHeader}>
          <h4 className="title is-4">
            <a href="#update-design-preferences">
              Updating or setting your design preferences
            </a>
          </h4>
          <p>
            Here we will explain how to update and or set your design
            preferences for you location account
          </p>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 1</h6>
            <p>
              Click on the &quot;Location&quot; tab at the top of the screen,
              then click on Design Preferences. To the right of the page, you
              will see the &quot;Edit preferences&quot; button, click this to
              start editing your preferences!
            </p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/1C.jpg"
              alt="submit orders"
            />
          </div>
        </div>
        <div
          className={`${Styles.subsectionContainer}${Styles.imgAndTextContainer}`}
        >
          <div className={Styles.txtCol}>
            <h6 className="title is-6">Step 2</h6>
            <p>
              Once you have clicked Edit preferences, scroll down to see the
              different options for each category. You will use the Drop Downs
              to see the available options. Click the Save button when you are
              done with your selections!
            </p>
          </div>
          <div className={Styles.imgCol}>
            <img
              src="https://cdn.fullcontour.com/help_images/2C.jpg"
              alt="submit orders"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpCenter;
