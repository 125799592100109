import LocationShowNavMenu from '../../shared/NavMenus/Locations/LocationShowNavMenu';
import LocationsListNavMenu from '../../shared/NavMenus/Locations/LocationsListNavMenu';

const routes = [
  {
    path: '/locations',
    component: LocationsListNavMenu,
    so: true,
    sl: false,
  },
  {
    path: [
      '/locations/:slug',
      '/locations/:slug/price-list',
      '/locations/:slug/api-settings',
      '/locations/:slug/dme-files/:action',
      '/locations/:slug/design-preferences',
      '/locations/:slug/billing',
      '/locations/:slug/design-profile',
      '/locations/:slug/billing/invoices/:hexInvoiceNumber',
      '/locations/:slug/integrations',
      '/locations/:slug/integrations/:action',
      '/locations/:slug/integrations/:id/:action',
    ],
    component: LocationShowNavMenu,
    so: true,
    sl: false,
  },
  {
    path: [
      '/locations',
      '/locations/price-list',
      '/locations/api-settings',
      '/locations/dme-files/:action',
      '/locations/design-preferences',
      '/locations/design-profile',
      '/locations/billing',
      '/locations/billing/invoices/:hexInvoiceNumber',
      '/locations/integrations',
      '/locations/integrations/:action',
      '/locations/integrations/:id/:action',
    ],
    component: LocationShowNavMenu,
    so: false,
    sl: true,
  },
];

export default routes;
