import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Suspense, useCallback, useEffect, useState } from 'react';
import OldThreeLoader from '../../shared/Loader';
import ThreeObj from './ThreeObj/index';

const filterFiles = (files, fileType, category = null) =>
  files.filter(
    (file) =>
      file.originalFileName.endsWith(`.${fileType}`) &&
      file.sortOrder < 600 &&
      (!category || file.category === category),
  );

const updateAssignedStages = (assignedStages, objFile, category) => {
  const foundIndex = assignedStages.findIndex(
    (stage) => stage.sortOrder === objFile.sortOrder,
  );

  if (foundIndex === -1) {
    assignedStages.push({
      sortOrder: objFile.sortOrder,
      [category]: { obj: objFile },
    });
  } else {
    assignedStages[foundIndex][category] = { obj: objFile };
  }

  return assignedStages;
};

function ObjViewer({
  files,
  className = '',
  maxHeight = 'calc(80vh)',
  reverse = false,
  theme = 'dark',
  logoUrl = null,
  orderRedesignCount = null,
}) {
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sliderVal, setSliderVal] = useState(0);
  const [playing, setPlaying] = useState(false);

  const sortAndOrderFiles = useCallback(() => {
    const sortedMtls = filterFiles(files, 'mtl');
    const upperObjs = filterFiles(files, 'obj', 'upper');
    const lowerObjs = filterFiles(files, 'obj', 'lower');

    let assignedStages = [];
    for (const obj of upperObjs) {
      assignedStages = updateAssignedStages(assignedStages, obj, 'upper');
    }

    for (const obj of lowerObjs) {
      assignedStages = updateAssignedStages(assignedStages, obj, 'lower');
    }

    for (const mtl of sortedMtls) {
      const foundIndex = assignedStages.findIndex(
        (stage) => stage.sortOrder === mtl.sortOrder,
      );

      if (foundIndex !== -1) {
        const { obj } = assignedStages[foundIndex][mtl.category] || {};
        if (obj) {
          assignedStages[foundIndex][mtl.category] = { obj, mtl };
        }
      }
    }

    const newAssignedStages = assignedStages
      .sort((a, b) =>
        reverse ? b.sortOrder - a.sortOrder : a.sortOrder - b.sortOrder,
      )
      .map((assigned, index) => ({
        ...assigned,
        index,
        upper: assigned.upper
          ? { ...assigned.upper, obj: { ...assigned.upper.obj, index } }
          : null,
        lower: assigned.lower
          ? { ...assigned.lower, obj: { ...assigned.lower.obj, index } }
          : null,
      }));

    setStages(newAssignedStages);
  }, [files, reverse, orderRedesignCount]);

  useEffect(() => {
    sortAndOrderFiles();
    return () => setStages([]);
  }, [orderRedesignCount, sortAndOrderFiles]);

  return (
    <div
      className={`${theme} ${className} objWrapper`}
      style={{
        width: '100%',
        minHeight: 100,
        height: maxHeight,
        overflow: 'hidden',
      }}
    >
      {stages.length > 0 ? (
        <Suspense fallback={<OldThreeLoader loading />}>
          <ThreeObj
            stages={stages}
            loading={loading}
            setLoading={setLoading}
            sliderVal={sliderVal}
            setSliderVal={setSliderVal}
            setPlaying={setPlaying}
            playing={playing}
            theme={theme}
            logoUrl={logoUrl}
          />
        </Suspense>
      ) : null}
      <OldThreeLoader
        title="Viewer..."
        subTitle="This may take a few moments, please be patient."
        loading={loading}
      />
    </div>
  );
}

ObjViewer.propTypes = exact({
  className: PropTypes.string,
  files: PropTypes.array.isRequired,
  maxHeight: PropTypes.string,
  orderRedesignCount: PropTypes.number,
  reverse: PropTypes.bool,
  theme: PropTypes.string,
  logoUrl: PropTypes.string,
});

export default ObjViewer;
