import { useMutation } from '@apollo/client';
import { CREATE_ORDER_FILE, REQUEST_S3_SIGN } from '@fullcontour/shared-api';
import axios from 'axios';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Log from '../../../../../config/log';
import BoxIcon from '../../../Icons/BoxIcon';
import {
  activeStyle,
  baseStyle,
  rejectedStyle,
} from './FileDropzoneOrderFileStyle';

function FileDropzoneOrderFile({
  updateUploadProgress,
  setUploading,
  setUploadingOff,
  uploading,
  orderFileType,
  order,
  refetch,
  accept = {
    'image/*': ['.jpeg', '.png', '.jpg'],
    'application/*': ['.pdf'],
  },
  multiple = false,
}) {
  const [t] = useTranslation('formfields');
  const [signS3] = useMutation(REQUEST_S3_SIGN);
  const [create] = useMutation(CREATE_ORDER_FILE, {
    onCompleted: (data) => {
      const { createOrderFile } = data;
      if (createOrderFile.errorMessages.length > 0) {
        Log.error('error creating order file');
      } else {
        refetch();
      }
    },
  });

  const formatFilename = (filename) => {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${
      splitFilename[splitFilename.length - 1]
    }`;
  };

  const onDrop = async (accepted) => {
    updateUploadProgress(0);

    if (accepted && accepted.length > 0) {
      setUploading();
      Promise.all(accepted.map(async (file) => signAndSubmit(file))).then(() =>
        setUploadingOff(),
      );
    }
  };

  const uploadToS3 = async (signedRequest, file) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (percent >= 100) {
          updateUploadProgress(100);
        } else {
          updateUploadProgress(percent);
        }
      },
    };

    await axios.put(signedRequest, file, options);
  };

  const signAndSubmit = async (file) => {
    const s3Key = `orders/${order.location.id}/${
      order.intOrderId
    }/${orderFileType}/${formatFilename(file.name)}`;

    const response = await signS3({
      variables: {
        input: {
          input: {
            originalFilename: file.name,
            filename: s3Key,
            acl: 'private',
            filetype: file.type,
          },
          clientMutationId: uuidv4(),
        },
      },
    });

    const { signedRequest, url } = response.data.signS3.s3Response;

    await uploadToS3(signedRequest, file);

    await create({
      variables: {
        input: {
          input: {
            fileUrl: url,
            fileType: orderFileType,
            orderId: order.id,
            originalFileName: file.name,
            s3Key,
          },
          clientMutationId: uuidv4(),
        },
      },
    });
  };

  const setText = () => {
    let text = 'Drop ';

    const typeText = orderFileType.replace(/_/g, ' ');

    if (!multiple) {
      text += `a${orderFileType === 'initial_scans' ? 'n' : ''} ${
        orderFileType === 'designer_finished_package' ||
        orderFileType === 'visualization_output'
          ? typeText
          : typeText.slice(0, -1)
      } `;
    }

    if (multiple) {
      text += `additional ${typeText} `;
      text += `here, or click/tap to select ${typeText} to
        upload`;
    }

    return text;
  };

  return (
    <Dropzone
      accept={accept}
      multiple
      disabled={uploading}
      onDrop={(accepted) => onDrop(accepted)}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
        let styles = { ...baseStyle };
        styles = isDragActive ? { ...styles, ...activeStyle } : styles;
        styles = isDragReject ? { ...styles, ...rejectedStyle } : styles;

        return (
          <div style={styles} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="is-flex is-justify-content-center is-align-items-center">
              <BoxIcon
                name="bx-cloud-upload"
                className="has-text-primary is-size-1 mr-2"
              />
              <span className="is-size-5 ml-3">{t(setText())}</span>
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
}

FileDropzoneOrderFile.propTypes = {
  labelText: PropTypes.string,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  order: PropTypes.object.isRequired,
  updateUploadProgress: PropTypes.func.isRequired,
  setUploading: PropTypes.func.isRequired,
  setUploadingOff: PropTypes.func.isRequired,
  orderFileType: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  uploading: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default FileDropzoneOrderFile;
