import { useQuery } from '@apollo/client';
import { BaseLoader, LoadingOrError, Message } from '@fullcontour/common';
import { GET_CUSTOMER_LOC_PRICE_LIST } from '@fullcontour/shared-api';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import PriceListItemsTable from '../../PriceListItems/PriceListItemForm/TableElements/PriceListItemsTable';

function LocationPriceList() {
  const { t } = useTranslation('pricelist');
  const { slug } = useParams();

  const { error, loading, data } = useQuery(GET_CUSTOMER_LOC_PRICE_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: { slug },
  });

  if (loading || error) {
    return (
      <LoadingOrError error={error} loading={loading} title="Price List" />
    );
  }

  const {
    customerLocation: { priceList = null } = {},
  } = data;

  return priceList ? (
    <>
      <div className="block">
        <h3 className="title is-3">{t('Price List')}</h3>
      </div>
      <div className="block">
        <Suspense fallback={<BaseLoader title="Price List Items " loading />}>
          <PriceListItemsTable
            priceListItems={priceList.priceListItems}
            listDesignTypes={priceList.designTypes}
            listTurnaroundTimes={priceList.turnaroundTimes}
          />
        </Suspense>
      </div>
    </>
  ) : (
    <Message type="info">
      <p>
        {t(
          'A Price List has not yet been assigned. A 3Shape Design Service Customer Service Representative can assist in completing your profile.',
        )}
      </p>
    </Message>
  );
}

export default LocationPriceList;
