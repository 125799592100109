function TermsAndConditions() {
  return (
    <>
      <p>Last Modified: March 17th, 2023</p>
      <h3>3Shape Design Services LLC Terms & Conditions</h3>
      <br />
      <p>
        If you would like to see past versions of the 3Shape Design Services LLC
        Terms & Conditions, please contact us at
        <a href="mailto:designservices@3shape.com">designservices@3shape.com</a>
        and we would be happy to provide you with a copy of any prior version.
      </p>
      <br />
      <h5 className="title is-5">
        ARTICLE A &ndash; GENERALLY APPLICABLE TERMS
      </h5>
      <h6 className="title is-6">
        1. Applicability of these Terms and Conditions
      </h6>
      <p>
        3Shape Design Services, LLC, f/k/a FullContour, LLC, an Arizona limited
        liability company, (The &quot;Company&quot;) provides professional
        design services for dental prosthetics in various CAD systems, including
        but not limited to crowns, abutments, surgical guides, clear aligners,
        orthodontic appliances, dentures, partial frames, copings, frameworks,
        and other related dental prosthetic devices (collectively, the
        &quot;Professional Services&quot;) and a website
        <a
          href="https://3shape.com/designservices"
          target="_blank"
          rel="noopener noreferrer"
        >
          3shape.com/designservices
        </a>
        and web-based platform
        <a
          href="https://design.3shape.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          design.3shape.com
        </a>
        for use in transmitting and communicating about such dental prosthetic
        designs (collectively, the website and web-based platform are referred
        to herein as the &quot;3Shape Design Service Platform&quot;). The
        Professional Services and use rights for the 3Shape Design Service
        Platform are purchased from 3Shape Design Service by a customer (the
        &quot;Customer&quot;) for use by that Customer&apos;s authorized users
        (each a &quot;User&quot; and collectively, the &quot;Users&quot;). Users
        may or may not be employees of the Customer. For example, Users may
        include employees of the Customer, as well as non-employee dental labs,
        manufacturers and clinicians who have a relationship with the Customer.
        <br />
        <br />
        THESE 3SHAPE DESIGN SERVICEs TERMS AND CONDITIONS (THE
        &quot;AGREEMENT&quot;) ARE A LEGALLY BINDING CONTRACT BETWEEN THE
        COMPANY AND YOU, THE PROSPECTIVE USER OR CUSTOMER. IF YOU ARE A
        CUSTOMER, ARTICLE A, B AND C OF THIS AGREEMENT APPLY TO YOU. IF YOU ARE
        NOT A CUSTOMER, BUT A USER, THEN ONLY ARTICLES A AND B OF THIS AGREEMENT
        APPLY TO YOU.
        <br />
        <br />
        For more information or if you have any questions about this Agreement,
        including what legal terms apply to you, please contact us at
        <a href="mailto:designservices@3shape.com">designservices@3shape.com</a>
        .
      </p>
      <br />
      <h6 className="title is-6">2. Acceptance of Terms of Use</h6>
      <p>
        THIS AGREEMENT IS BETWEEN YOU AND THE COMPANY. BY ACCESSING OR USING THE
        PROFESSIONAL SERVICES OR 3HAPE DESIGN SERVICE PLATFORM, OR BY CLICKING
        TO ACCEPT OR AGREE TO THE AGREEMENT WHEN THIS OPTION IS MADE AVAILABLE,
        WHICH INCLUDE OUR PRIVACY POLICY, INCORPORATED HEREIN BY REFERENCE. IF
        YOU DO NOT WISH TO AGREE TO THIS AGREEMENT OR THE PRIVACY POLICY, YOU
        MUST NOT ACCESS OR USE THE PROFESSIONAL SERVICES OR 3SHAPE DESIGN
        SERVICE PLATFORM.
        <br />
        <br />
        The Professional Services and the 3Shape Design Service Platform are
        offered and available to users who are eighteen (18) years of age or
        older. By using the Professional Services or the 3Shape Design Service
        Platform you represent and warrant that you are of legal age to form a
        binding contract with the Company.
        <br />
        <br />
        Also, please note that we may revise and update this Agreement from time
        to time in our sole discretion. All changes are effective immediately
        when posted and apply to all access to and use of the Professional
        Services or the 3Shape Design Service Platform thereafter. Your
        continued use of the Professional Services or the 3Shape Design Service
        Platform following the posting of changes means that you accept and
        agree to the changes. It is your responsibility to check this Agreement
        periodically for changes, as these changes are binding on you.
        <br />
        <br />
        The material provided through our Professional Services and the 3Shape
        Design Service Platform is protected by law, including, but not limited
        to, United States copyright law and international treaties. The
        Professional Services and the 3Shape Design Service Platform are
        controlled and operated by the Company from its offices within the
        United States. The Company makes no representation that materials in the
        Professional Services and the 3Shape Design Service Platform are
        appropriate or available for use in other locations, and access to them
        from territories where their contents are illegal or prohibited. Those
        who choose to access the Professional Services and the 3Shape Design
        Service Platform from other locations do so on their own initiative and
        are responsible for compliance with all applicable local laws.
        <br />
        <br />
        The 3Shape Design Service Platform offers you an efficient means to
        bridge production gaps between laboratories, dental manufacturers and
        clinicians. Your use of the 3Shape Design Service Platform and/or
        purchase of the Professional Services may include providing PHI, as such
        term is defined in the BAA. By using the 3Shape Design Service Platform,
        you understand and acknowledge (i) the Company is not providing medical
        care, dental care, or treatment-related advice to you, we are merely a
        professional design service; and (ii) that the Professional Services and
        use of the 3Shape Design Service Platform are also governed by the
        Company&apos;s Business Associate Agreement (&quot;BAA&quot;), as it may
        be modified over time.
      </p>
      <br />
      <h6 className="title is-6">3. Governing Law and Dispute Resolution</h6>
      <p>
        This Agreement and performance hereunder shall be governed by, and
        construed in accordance with, the laws of the state of Arizona (without
        giving effect to its conflict of laws principles). The parties agree to
        submit any claim, dispute, or disagreement to mediation before a
        mutually-agreeable mediator prior any other form of dispute resolution.
        All mediation or any other form of dispute resolution shall take place
        in Phoenix, Arizona and the parties irrevocably waive any objection to
        such venue.
      </p>
      <br />
      <h6 className="title is-6">4. Limitation of Liability</h6>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU EXPRESSLY UNDERSTAND AND
        AGREE THAT THE COMPANY SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
        SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED
        TO, DAMAGES FOR LOSS OF PROFITS, REVENUES, GOODWILL, USE, DATA, OR OTHER
        INTANGIBLE LOSSES (EVEN IF THE COMPANY HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE
        INABILITY TO USE THE PROFESSIONAL SERVICES OR 3SHAPE DESIGN SERVICE
        PLATFORM; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICE
        RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICE PURCHASED OR
        OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE 3SHAPE DESIGN
        SERVICE PLATFORM; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
        TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON
        THE THE 3SHAPE DESIGN SERVICE PLATFORM; OR (v) ANY OTHER MATTER RELATING
        TO THE PROFESSIONAL SERVICES OR 3SHAPE DESIGN SERVICE PLATFORM. TO THE
        MAXIMUM EXTENT PERMITTED BY LAW, YOU ALSO AGREE THAT THE COMPANY WILL
        NOT BE LIABLE FOR ANY INTERRUPTION OF BUSINESS, ACCESS DELAYS, OR ACCESS
        INTERRUPTIONS TO THE THE DESIGN SERVICE PLATFORM OR THE WEB SITE(S) YOU
        ACCESS THROUGH THE 3SHAPE DESIGN SERVICE PLATFORM, DATA NON-DELIVERY,
        MIS-DELIVERY, CORRUPTION, DESTRUCTION OR OTHER MODIFICATION, AND ANY
        EVENTS BEYOND THE COMPANY&apos;S REASONABLE CONTROL.
        <br />
        <br />
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU EXPRESSLY UNDERSTAND AND
        AGREE THAT THE COMPANY&apos;S MAXIMUM AGGREGATE LIABILITY (WHETHER IN
        CONTRACT OR IN TORT, INCLUDING NEGLIGENCE, OR UNDER ANY OTHER FORM OF
        LIABILITY) FOR DAMAGES OR LOSS, HOWSOEVER ARISING OR CAUSED, SHALL IN NO
        EVENT EXCEED THE AMOUNT OF FEES PAID TO THE COMPANY BY YOU IN THE TWELVE
        MONTH PERIOD IMMEDIATELY PRECEDING EVENTS FIRST GIVING RISE TO THE
        CLAIM.
      </p>
      <br />
      <h6 className="title is-6">5. Severability.</h6>
      <p>
        In the event that any provision of this Agreement is unenforceable or
        invalid under any applicable law, such unenforceability or invalidity
        shall not affect the other provisions of this Agreement and this
        Agreement shall be construed as if such unenforceable or invalid
        provision had never been contained herein.
      </p>
      <br />
      <h6 className="title is-6">6. Notices.</h6>
      <p>
        Notices to 3Shape Design Service shall be sent to
        <br />
        17505 N. 79th Ave #412
        <br />
        Glendale, AZ 85308
        <br />
        <br />
        The Company shall provide all notices to Users under this Agreement by
        email, although we may instead choose to provide notice via the 3Shape
        Design Service Platform or your then-current postal address on file with
        the Company. For purposes of messages and notices about the 3Shape
        Design Service Platform applicable to all Users, the Company may place a
        banner notice across the 3Shape Design Service Platform to alert you.
        You also agree that the Company may communicate with you through other
        means including email, mobile number, telephone, or delivery services
        including the postal service, about your account or services. You
        acknowledge and agree that we shall have no liability associated with or
        arising from your failure to maintain accurate contact or other
        information, including, but not limited to, your failure to receive
        critical information about the 3Shape Design Service Platform.
      </p>
      <br />
      <h6 className="title is-6">7. Entire Agreement.</h6>
      <p>
        You agree that this Agreement, including the documents incorporated
        herein by reference, constitutes the entire, complete and exclusive
        agreement between you and us regarding the Professional Services and
        3Shape Design Service Platform and supersedes all prior agreements and
        understandings, whether written or oral, or whether established by
        custom, practice, policy or precedent, with respect to the subject
        matter of this Agreement. Except as set forth otherwise in this
        Agreement, this Agreement cannot be modified or amended except in a
        writing executed by both parties that specifically references this
        Agreement and states that it is to amend the Agreement.
      </p>
      <br />
      <h6 className="title is-6">
        8. No informal waivers, agreements or representations.
      </h6>
      <p>
        The Company&apos;s to act with respect to a breach of this Agreement by
        you or others does not waive our right to act with respect to that
        breach or subsequent similar or other breaches. No representations,
        statements, consents, waivers or other acts or omissions by the Company
        shall be deemed legally binding on the Company, unless documented in a
        physical writing hand signed by a duly appointed officer of the Company.
      </p>
      <br />
      <h5 className="title is-5">
        ARTICLE B &ndash; TERMS FOR USE OF THE 3SHAPE DESIGN SERVICE PLATFORM
      </h5>
      <h6 className="title is-6">9. Customer Users and Non-Customer Users.</h6>
      <p>
        The terms set forth in this Article B apply to all Users of the 3Shape
        Design Service Platform, whether such User is a Customer of the Company
        or not. Each User agrees to the terms in this Article B. In addition,
        each Customer is responsible for ensuring that all of such
        Customer&apos;s authorized Users comply with the terms of this Article B
        when using the 3Shape Design Service Platform.
        <br />
        <br />
        If you are a User that has been invited to use the 3Shape Design Service
        Platform by one or more Customers, your user of the 3Shape Design
        Service Platform is controlled by each Customer as to that
        Customer&apos;s accounts. The Customer who invited you to use the 3Shape
        Design Service Platform has separately agreed to this Agreement, which
        permits that Customer to create and configure User accounts so that you
        and others may join as authorized Users. When a User, such as you,
        submits any content or information to the 3Shape Design Service Platform
        (&quot;Customer Data&quot;), you acknowledge and agree that the Customer
        Data is owned by the Customer and the Customer has choices and control
        over that Customer Data. These choices and controls may result in the
        access, use, disclosure, modification or deletion of Customer Data
        submitted by you.
        <br />
        <br />
        AS BETWEEN THE COMPANY AND CUSTOMER, YOU AGREE THAT CUSTOMER IS SOLELY
        RESPONSIBLE TO (A) INFORM USERS OF ANY RELEVANT CUSTOMER POLICIES AND
        PRACTICES AND ANY 3SHAPE DESIGN SERVICE PLATFORM SETTINGS THAT MAY
        IMPACT ON THE HANDLING OF CUSTOMER DATA; (B) OBTAIN ANY RIGHTS,
        PERMISSIONS, OR OTHERWISE PROVIDE ANY APPLICABLE NOTICES, OR CONSENTS
        FROM USERS THAT ARE NECESSARY FOR THE LAWFUL USE OF CUSTOMER DATA AS
        CONTEMPLATED BY THIS AGREEMENT AND THE OPERATION OF THE 3SHAPE DESIGN
        SERVICE PLATFORM, AND (C) RESPOND TO AN RESOLVE ANY DISPUTE WITH A USER
        RELATED TO OR BASED ON CUSTOMER DATA, THE 3SHAPE DESIGN SERVICE PLATFORM
        OR CUSTOMER&apos;S FAILURE TO FULFILL THESE OBLIGATIONS.
      </p>
      <br />
      <h6 className="title is-6">10. Ownership and Proprietary Information</h6>
      <p>
        Other than Customer Data, the content and information on the 3Shape
        Design Service Platform (&quot;Company Content&quot;) is proprietary to
        the Company or its licensors, suppliers, publishers, rights holders, or
        other content providers (collectively, and individually, &quot;Company
        Content Providers&quot;). You agree not to modify, copy, distribute,
        transmit, share, display, perform, reproduce, publish, license, create
        derivative works from, transfer, or sell or re-sell any Company Content
        obtained from or through the Company.
        <br />
        <br />
        Subject to your compliance with this Agreement, as applicable, the
        Company Content Providers grant you a limited, non-exclusive,
        non-transferable, non-sublicensable license to access and make personal
        and non-commercial use of the Company Content. This license does not
        include any resale or commercial use of any Company Content; any
        collection and use of any product listings, descriptions, or prices; any
        derivative use of any Company Content; any downloading or copying of
        account information for the benefit of another party; or any use of data
        mining, robots, or similar data gathering and extraction tools. All
        rights not expressly granted to you in this Agreement are reserved and
        retained by the Company Content Providers.
      </p>
      <br />
      <h6 className="title is-6">11. Registration, Accounts, and Passwords</h6>
      <p>
        If you establish a personal account with the Company, you agree to
        provide true and accurate data about yourself on the account
        registration form, and to update and keep such data current. You will
        receive a password and account upon completing the registration process.
        You are solely responsible for maintaining the confidentiality of your
        password and account, and you are solely responsible for all use of your
        password or account, whether authorized by you or not. The Company
        cannot and will not be liable for any loss or damage arising from your
        failure to comply with these terms. You agree to (a) immediately notify
        the Company of any unauthorized use of your password or account or any
        other breach of security; and (b) ensure that you exit from your account
        each time you use the 3Shape Design Service Platform. Access and use of
        password-protected and/or secure areas of the 3Shape Design Service
        Platform is restricted to users who have been given a valid password by
        the Company. The Company may terminate your membership and access to the
        3Shape Design Service Platform and to the services if false or
        misleading registration data is discovered by the Company.
      </p>
      <br />
      <h6 className="title is-6">12. Mobile Terms</h6>
      <p>
        If you are receiving special offers from the Company via email, SMS text
        message, or otherwise, you can unsubscribe via the links provided in the
        email at any time or by contacting us at
        <a href="mailto:designservices@3shape.com">designservices@3shape.com</a>
        or text instructions regarding opting out.
        <br />
        <br />
        By opting to receive special offers from the Company, you consent to
        receiving, from time to time, text messages or emails which may include
        alerts, promotions, offers, polls, and giveaways. Message frequency
        varies based on user and other factors. Message and data rates may apply
        and, if so, are billed by and payable to your mobile service provider.
        The Company will not be liable for any delays in the receipt of any SMS
        messages as delivery is subject to effective transmission from your
        network operator. Data obtained from you in connection with this SMS
        service may include your mobile phone number, your carrier&apos;s name
        and the date, time and content of your messages. The Company may use
        this information to contact you and to provide the services you request
        from the Company and for other business purposes.
      </p>
      <br />
      <h6 className="title is-6">13. Payment</h6>
      <p>
        The 3Shape Design Service Platform is provided to Users free of charge
        for as long as the Customer authorizing such User access is in
        good-standing with the Company, including but not limited to that such
        Customer is current on all amounts owed the Company for Professional
        Services purchased by the Customer.
      </p>
      <br />
      <h6 className="title is-6">
        14. Use Restrictions and Intellectual Property
      </h6>
      <p>
        The 3Shape Design Service Platform may contain proprietary and copyright
        information, the terms of which must be observed and followed. Any
        unauthorized use of any Company Content contained on the 3Shape Design
        Service Platform may violate copyright laws, trademark laws, the laws of
        privacy and publicity, and communications regulations and statutes. You
        may not modify, publish, transmit, participate in the transfer or sale,
        create derivative works, or in any way exploit any of the content in
        whole or in part.
        <br />
        <br />
        You will not upload, post or otherwise make available on the 3Shape
        Design Service Platform any material protected by copyright, trademark
        or other proprietary right without the express permission of the owner
        of the copyright, trademark or other proprietary right. You will
        indemnify the Company for any claims by third parties of infringement of
        copyrights, trademarks, proprietary rights or any other harm, expenses
        and damages, including reasonable attorney&apos;s fees, resulting from
        such a submission of material by you or (in the case of a Customer) any
        of your Users.
        <br />
        <br />
        The Company Content may not be reproduced, duplicated, copied, sold,
        resold, or otherwise exploited for any commercial purpose without
        express written consent of the Company. You may not frame or utilize
        framing techniques to enclose any trademark, logo, or other proprietary
        information (including images, text, page layout, or form) of the
        Company without express written consent. You may not use any meta tags
        or any other &quot;hidden text&quot; utilizing The Company&apos;s name
        or trademarks without the express written consent of the Company. You
        may not misuse the Company Content and only use such content as
        permitted by law and this Agreement. The licenses granted herein
        terminate if you do not comply with this Agreement.
      </p>
      <br />
      <h6 className="title is-6">15. U.S. Government Restricted Rights</h6>
      <p>
        The Company Content is provided with &quot;RESTRICTED RIGHTS.&quot; Use,
        duplication, or disclosure by the U.S. Government, or any other
        government authority, is subject to restrictions as set forth in
        applicable laws and regulations. Use of the materials by the U.S.
        Government, or any other government authority, constitutes
        acknowledgment of The Company&apos;s proprietary rights in them.
      </p>
      <br />
      <h6 className="title is-6">16. Changes</h6>
      <p>
        Information on the 3Shape Design Service Platform may be changed or
        updated without notice. The Company may also make improvements and/or
        changes in the products, services and/or the programs described in this
        information at any time without notice.
      </p>
      <br />
      <h6 className="title is-6">17. Availability</h6>
      <p>
        Information that the Company publishes on the 3Shape Design Service
        Platform may contain references or cross references to products,
        programs and services that are not announced or available in your
        country. Such references do not imply that the Company intends to
        announce such products, programs or services in your country. Consult
        the Company for information regarding the products, programs and
        services which may be available to you.
      </p>
      <br />
      <h6 className="title is-6">
        18. Disclaimer of warranties as to 3Shape Design Service Platform
      </h6>
      <p>
        THE 3SHAPE DESIGN SERVICE PLATFORM (INCLUDING ALL THE COMPANY CONTENT)
        MAY CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. ALL
        INFORMATION IS PROVIDED BY THE COMPANY ON AN &quot;AS IS&quot; AND
        &quot;AS AVAILABLE&quot; BASIS ONLY. TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, THE COMPANY PROVIDES NO REPRESENTATIONS AND WARRANTIES,
        EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF FITNESS FOR A
        PARTICULAR PURPOSE, MERCHANTABILITY AND NONINFRINGEMENT. USE OF THE
        3SHAPE DESIGN SERVICE PLATFORM (INCLUDING ALL THE COMPANY CONTENT) IS
        SOLELY AT YOUR RISK.. THE COMPANY MAKES NO WARRANTY THAT (i) THE 3SHAPE
        DESIGN SERVICE PLATFORM WILL MEET YOUR REQUIREMENTS; (ii) THE 3SHAPE
        DESIGN SERVICE PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR
        FREE; (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE 3SHAPE
        DESIGN SERVICE PLATFORM WILL BE ACCURATE OR RELIABLE; AND (iv) THE
        QUALITY OF ANY PRODUCTS, WEBSITE, INFORMATION, OR OTHER MATERIAL
        PURCHASED OR OBTAINED BY YOU THROUGH THE 3SHAPE DESIGN SERVICE PLATFORM
        WILL MEET YOUR EXPECTATIONS. THE COMPANY EXPRESSLY DISCLAIMS ANY
        REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE 3SHAPE DESIGN SERVICE
        PLATFORM WILL SATISFY ANY STATUTORY OR REGULATORY OBLIGATIONS, OR WILL
        ASSIST WITH, GUARANTEE, OR OTHERWISE ENSURE COMPLIANCE WITH ANY
        APPLICABLE LAWS OR REGULATIONS, INCLUDING BUT NOT LIMITED TO THE HEALTH
        INSURANCE PORTABILITY AND ACCOUNTABILITY ACT OF 1996 (&quot;HIPAA&quot;)
        AND AS MODIFIED, SUPPLEMENTED AND AMENDED, THE GRAMM-LEACH-BLILEY ACT OF
        1999 AND AS MODIFIED, SUPPLEMENTED AND AMENDED, THE SARBANES-OXLEY ACT
        OF 2002 AND AS MODIFIED, SUPPLEMENTED AND AMENDED, OR ANY OTHER FEDERAL
        OR STATE STATUTES OR REGULATIONS.
        <br />
        <br />
        YOU ARE SOLELY RESPONSIBLE FOR ENSURING THAT YOUR USE OF THE THE 3SHAPE
        DESIGN SERVICE PLATFORM, RELATED SERVICES, OR CONTENT IS IN ACCORDANCE
        WITH APPLICABLE LAW. THE COMPANY DOES NOT GUARANTEE, IMPLICITLY OR
        EXPLICITLY, ANY RESULTS. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED
        THROUGH THE USE OF THE 3SHAPE DESIGN SERVICE PLATFORM IS DONE AT YOUR
        OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
        DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
        DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR
        WRITTEN, OBTAINED BY YOU FROM THE COMPANY OR THROUGH OR FROM THE 3SHAPE
        DESIGN SERVICE PLATFORM SHALL CREATE ANY WARRANTY.
      </p>
      <br />
      <h6 className="title is-6">19. Transmissions</h6>
      <p>
        Any material, information, or idea you transmit to or post on the 3Shape
        Design Service Platform by any means will be treated as non-confidential
        and non-proprietary, and may be disseminated or used by the Company or
        its affiliates for any purpose whatsoever, including, but not limited
        to, developing, manufacturing and marketing its products.
        Notwithstanding the foregoing, all personal data provided to the Company
        will be handled in accordance with the Company&apos;s Privacy Policy,
        available online or by contacting the Company at the email address
        provided at the top of this Agreement. You are prohibited from posting
        or transmitting to or from the 3Shape Design Service Platform any
        unlawful, threatening, libelous, defamatory, obscene, scandalous,
        inflammatory, pornographic, or profane material, or any other material
        that could give rise to any civil or criminal liability under the law.
      </p>
      <br />
      <h6 className="title is-6">
        20. User Communications and Acceptable Uses
      </h6>
      <p>
        The Company, at any time now or in the future, may allow you and other
        Users to use the 3Shape Design Service Platform to communicate with each
        other. Such communication rights, if included by the Company in the
        3Shape Design Service Platform, will be set and controlled by the
        Customer that authorized the Users&apos; access.
        <br />
        <br />
        To help ensure a safe and productive environment, all Users must comply
        with the following acceptable use standards when using the 3Shape Design
        Service Platform, as well as promptly report any inappropriate behavior
        or content to the appropriate Customer and the Copmany.
        <br />
        <br />
        i. Acceptable Uses. You agree to undertake the following:
        <br />
        <br />
        a. Comply with all applicable laws and government regulations,
        including, without limitation, privacy laws, intellectual property laws,
        export control laws, labor and employment laws, tax laws, and regulatory
        requirements.
        <br />
        b. Upload and distribute only Customer Data to which Customer owns all
        required rights under law and under contractual and fiduciary
        relationships and do so only in compliance with all applicable laws and
        governmental regulations.
        <br />
        c. Provide accurate information and update it as necessary.
        <br />
        d. Use commercially reasonable efforts to prevent unauthorized access to
        our use of the 3Shape Design Service Platform.
        <br />
        e. Protect passwords and access to sensitive personal information and
        other information that is confidential in nature.
        <br />
        f. Monitor and control activities conducted through your account.
        <br />
        g. Promptly notify Customer and the Company if of you are aware of or
        suspect any illegal or unauthorized activity or security breach,
        including any loss, theft or unauthorized disclosure or use of a user
        name or password.
        <br />
        h. Regularly review and comply with this Agreement and the documents
        incorporated herein by reference.
        <br />
        i. Review and comply with notices sent by the Company concerning the
        3Shape Design Service Platform.
        <br />
        j. Use the 3Shape Design Service Platform in a professional manner.
        <br />
        <br />
        ii. Unacceptable Uses. You agree not to do the following:
        <br />
        <br />
        a. Publish or input inaccurate information.
        <br />
        b. Use or attempt to use another&apos;s account without authorization
        from the Company, or create a false identity.
        <br />
        c. Upload, post, transmit or otherwise make available any content that:
        (W) is unlawful, libelous, abusive, obscene, discriminatory or otherwise
        objectionable; (X) includes information that you do not have the right
        to disclose or make available under any law or under contractual or
        fiduciary relationships; (Y) infringes upon patents, trademarks, trade
        secrets, copyrights or other proprietary rights; or (Z) contains
        software viruses, worms, or any other computer code, files or programs
        that interrupt, destroy or limit the functionality of any computer
        software or hardware or telecommunications equipment of the Company or
        any User.
        <br />
        d. Reverse engineer, decompile, disassemble, decipher or otherwise
        attempt to derive the source code for any underlying intellectual
        property used to provide the 3Shape Design Service Platform, or any part
        thereof.
        <br />
        e. Utilize or copy information, content or any data you view on and/or
        obtain from the Company to provide any service that is competitive with
        the Company, as determined in The Company&apos;s reasonable and sole
        discretion.
        <br />
        f. Adapt, modify or create derivative works based on Company
        intellectual property or technology underlying the 3Shape Design Service
        Platform.
        <br />
        g. Rent, lease, loan, trade, sublicense, offer as a service bureau, or
        sell/re-sell access to the 3Shape Design Service Platform or any
        information therein, or the equivalent, in whole or part.
        <br />
        h. Remove any copyright, trademark or other proprietary rights notices
        contained in or on the 3Shape Design Service Platform.
        <br />
        i. Infringe or use The Company&apos;s brand, logos and/or trademarks,
        including, without limitation, using the words &quot;3Shape&quot; or
        &quot;FullContour&quot; in any business name, email, or URL, except as
        expressly permitted by the Company.
        <br />
        j. Access the 3Shape Design Service Platform for purposes of monitoring
        The Company&apos;s availability, performance or functionality for any
        competitive purpose, including for purposes of building a similar or
        competitive product or service or to copy any ideas, features, functions
        or components of the 3Shape Design Service Platform.
        <br />
        k. Engage in &quot;framing,&quot; &quot;mirroring,&quot; or otherwise
        simulating the appearance or function of the 3Shape Design Service
        Platform.
        <br />
        l. Attempt to or access the 3Shape Design Service Platform by any means
        other than through the interfaces provided by the Company.
        <br />
        m. Attempt to or actually override any security component included in or
        underlying the 3Shape Design Service Platform.
        <br />
        n. Use the 3Shape Design Service Platform in violation of any law,
        including without limitation, HIPAA, 45 C.F.R. Part 2 (the &quot;Part 2
        Regulations&quot;).
        <br />
        o. Use the 3Shape Design Service Platform in a manner that may harm
        minors or that interacts with or targets people who are under the age of
        eighteen.
        <br />
        p. Engage in any action that directly or indirectly interferes with the
        proper working of or places an unreasonable load on The Company&apos;s
        infrastructure, including, but not limited to, attempting to gain
        unauthorized access to the 3Shape Design Service Platform, or
        transmitting or activating computer viruses through or on the 3Shape
        Design Service Platform.
        <br />
        q. Interfere with or disrupt the Company or the 3Shape Design Service
        Platform, including, but not limited to, any servers or networks
        connected to the Company.
        <br />
        <br />
        By posting any materials or other information on or through the 3Shape
        Design Service Platform, except to the extent prohibit by law, including
        HIPAA, you grant the Company and its affiliates a royalty-free,
        perpetual, irrevocable, non-exclusive license to use, reproduce, modify,
        publish, edit, translate, distribute, perform, and display such
        materials or other information alone or as part of other works in any
        form, media, or technology whether now known or hereafter developed
        without territorial or time limitations, and to sublicense such rights.
        The Company shall act in good faith to maintain all information provided
        by you through the Design Service Platform in accordance with the
        Company&apos;s then-current data retention practices, but reserves the
        right to delete any information that the Company believes in good faith
        to be creating an immediate risk of injury or harm to the 3Shape Design
        Service Platform, the Company, its Customers or other Users, or any
        other third party.
        <br />
        <br />
        The 3Shape Design Service Platform may contain links maintained by other
        third parties. These links are provided solely as a convenience to you
        and not because we endorse or have an opinion about the contents. The
        Company expressly disclaims any representations regarding the content or
        accuracy of materials or the privacy practices related to such third
        parties.
      </p>
      <br />
      <h6 className="title is-6">21. Termination and Suspension</h6>
      <p>
        In its sole and absolute discretion, with or without notice to you, the
        Company may suspend or terminate your account or your use of and access
        to the 3Shape Design Service Platform, terminate your account without
        prior notice and for any reason, including, but not limited to: (i)
        concurrent access of the 3Shape Design Service Platform with identical
        user identification, (ii) permitting another person or entity to use
        your user identification to access the 3Shape Design Service, (iii) any
        unauthorized access or use of the 3Shape Design Service, (iv) any
        suspected violation of this Agreement, (v) tampering with or alteration
        of any of the software and/or data files contained in, or accessed
        through, the 3Shape Design Service Platform, or (vi) failure to use the
        3Shape Design Service Platform on a regular basis. You may terminate
        your account for any reason by emailing the Company at
        <a href="mailto:designservices@3shape.com">designservices@3shape.com</a>
        . The Company shall not be liable to you or any third party for any
        claims or damages arising out of any termination or suspension of your
        account or your use of and access to the Design Service Platform.
        Termination, suspension, or cancellation of your account or your use of
        and access to the Design Services Platform shall not affect any right or
        relief to which the Company may be entitled, at law or in equity, and
        all rights granted to you will automatically terminate and immediately
        revert to the Company.
      </p>
      <br />
      <h6 className="title is-6">
        22. Digital Millennium Copyright Act (&quot;DMCA&quot;) Notice.
      </h6>
      <p>
        In the event materials are made available to the 3Shape Design Service
        Platform by third parties not within our control, the Company is under
        no obligation to, and does not, scan such content for the inclusion of
        illegal or impermissible content. However, the Company respects the
        copyright interests of others. It is the Company&apos;s policy not to
        permit materials known by it to infringe another party&apos;s copyright
        to remain on the 3Shape Design Service Platform. If you believe any
        materials on the 3Shape Design Service Platform infringe a copyright,
        you should provide the Company with written notice that at a minimum
        contains the following:
        <br />
        <br />
        i. A physical or electronic signature of a person authorized to act on
        behalf of the owner of an exclusive right that is allegedly infringed.
        <br />
        ii. Identification of the copyrighted work claimed to have been
        infringed, or, if multiple copyrighted works at a single online site are
        covered by a single notification, a representative list of such works at
        that site.
        <br />
        iii. Identification of the material that is claimed to be infringing or
        to be the subject of infringing activity and that is to be removed or
        access to which is to be disabled, and information reasonably sufficient
        to permit us to locate the material.
        <br />
        iv. Information reasonably sufficient to permit the Company to contact
        the complaining party, such as an address, telephone number, and, if
        available, an electronic mail address at which the complaining party may
        be contacted.
        <br />
        v. A statement that the complaining party has a good faith belief that
        use of the material in the manner complained of is not authorized by the
        copyright owner, its agent, or the law.
        <br />
        vi. A statement that the information in the notification is accurate,
        and under penalty of perjury, that the complaining party is authorized
        to act on behalf of the owner of an exclusive right that is allegedly
        infringed.
        <br />
        <br />
        All DMCA notices should be sent to our designated agent as follows:
        <br />
        Attn: Chief Technical Officer, 3Shape Design Service, LLC, 17505 N. 79th
        Ave #412 Glendale, AZ 85308
        <br />
        <br />
        It is our policy to terminate relationships regarding content with third
        parties who repeatedly infringe the copyrights of others.
      </p>
      <br />
      <h5 className="title is-5">
        ARTICLE C &ndash; TERMS OF SERVICE FOR PROFESSIONAL SERVICES
      </h5>
      <h6 className="title is-6">23. Information.</h6>
      <p>
        Customer is responsible for providing the Company with all information,
        including digital scans & design instructions, as is necessary for the
        Company to complete all Professional Services, including the design of
        such dental prosthetics. The Company is not responsible for the accuracy
        or inaccuracy of any information received from Customer or its Users and
        transmitted to the laboratory, manufacturer or clinician (including if
        transmitted via the 3Shape Design Service Platform). All dental
        prosthetics and any other Professional Services shall be completed from
        the information that Customer and its Users provide and Customer shall
        be solely responsible for the final quality control of both the design
        and manufactured dental prosthetics upon completion.
      </p>
      <br />
      <h6 className="title is-6">24. Payment.</h6>
      <p>
        Payment of the Professional Services requested by Customer and provided
        by the Company shall be paid via an authorized credit card provided by
        Customer and maintained on file by the Company. Accrued charges for
        Professional Services shall be charged and paid weekly.
      </p>
      <br />
      <h6 className="title is-6">25. Pricing.</h6>
      <p>
        The pricing for Professional Services is established in the Customer
        account wand with the platform and is subject to change from
        time-to-time in the Company&apos;s discretion. All pricing for
        Professional Services provided by the Company is exclusive of applicable
        federal, state or local taxes and Customer is responsible for payment of
        any such applicable taxes.
      </p>
      <br />
      <h6 className="title is-6">
        26. Customer Responsibility for Finished Designs, Images and Products;
        Disclaimer of Warranties as to Professional Services
      </h6>
      <p>
        Customer is fully responsible for all Customer Data provided to the
        Company, as well as for all finished designs, images or other
        information provided which the Company will provide to the Customer
        and/or laboratory(ies), manufacturers or clinicians of the dental
        products. For the avoidance of any doubt, (a) if Customer is a
        prescribing clinician, Customer is responsible for viewing and approving
        the resulting designs from the Company&apos;s Professional Services and
        (b) if Customer is not the prescribing clinician, Customer is
        responsible for ensuring that the resulting designs from the
        Company&apos;s Professional Services meets the requirements of its
        clinician customer.
      </p>
      <br />
      <p>
        THE COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS TO CUSTOMER, ITS
        USERS OR ANY THIRD PARTY(IES) REGARDING THE ACCURACY OF ANY INFORMATION
        PROVIDED TO THE COMPANY AND THE RESULTING DESIGNS PRODUCED BY THE
        COMPANY AS PART OF ITS PROFESSIONAL SERVICES, NOR DOES THE COMPANY MAKE
        ANY WARRANTIES OR REPRESENTATION AS TO THE DENTAL PRODUCTS PRODUCED
        THEREFROM.
      </p>
      <br />
      <p>
        The Company uses the 3Shape Design Service Platform to transmit and
        deliver the finished designs to the integrated manufacturers,
        laboratories, and clinicians as a file transfer system. All files that
        are submitted to the Company without selecting “Requires Design
        Approval” will be sent to the manufacture directly and the Customer is
        solely and fully responsible and liable for the final product(s)
        produced therefrom. The Customer has the option to select “Requires
        Design Approval” in order to approve the final designs prior to the case
        getting sent to the manufacture to avoid any mistakes. “Requires Design
        Approval” is highly recommending by the Company. On certain dental
        prosthetic designs, the Company may automatically suggest that Customer
        engage in the Design Approval process. In the event that Customer
        bypasses the Design Approval Option, then Customer accept and assume
        full responsibility for all dental products produced and/or any and all
        liability to any third party(ies) arising therefrom. TO THE MAXIMUM
        EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS OTHERWISE EXPRESSLY SET
        FORTH IN THIS AGREEMENT, THE COMPANY MAKES NO WARRANTY OF ANY KIND AS TO
        THE PROFESSIONAL SERVICES OR ANY DESIGNS RESULTING THEREFROM, WHETHER
        EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND SPECIFICALLY DISCLAIMS ALL
        IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
      </p>
      <br />
      <h6 className="title is-6">27. Indemnification.</h6>
      <p>
        Customer agrees to fully indemnify, defend and hold the Company and its
        affiliates, and their directors, officers, employee and agents, harmless
        from and against, and shall pay and reimburse each of them for any and
        all claims, causes of actions, expenses, liabilities or damages arising
        out of or otherwise attributable to any defect in any crowns, abutments,
        surgical guides, clear aligners, orthodontic appliances, dentures,
        partial frames, copings, frameworks, and other related dental prosthetic
        devices produced or manufactured using the Company&apos;s Professional
        Services and/or the 3Shape Design Service Platform, including but not
        limited to any claims of bodily injury, property damage or other form of
        injury resulting from said defect whether made by Customer&apos;s
        patients or otherwise. The Company does NOT agree to indemnify, defend
        or to hold Customer harmless against or from any claims, causes of
        action and/or damages to any third party(ies) resulting from or arising
        out of the use of the Company&apos;s Professional Services, the 3Shape
        Design Service Platform or from any defect in any dental product
        manufactured or produced by any laboratory, manufacturer or clinician.
      </p>
    </>
  );
}

export default TermsAndConditions;
