function initialValues(props) {
  const {
    data: { customerLocation = {} } = {},
  } = props;
  return {
    id: customerLocation.id || null,
    name: customerLocation.name || '',
    inactiveBilling: customerLocation.inactiveBilling,
    language: customerLocation.language || 'en',
    organizationId: customerLocation.organizationId || '',
    priceListId: customerLocation.priceListId || '',
    paymentTermId: customerLocation.paymentTermId || '',
    turnaroundTimeId: customerLocation.turnaroundTimeId || '',
    guideBookId: customerLocation.guideBookId || '',
    numberingSystem: customerLocation.numberingSystem || 'uns',
    website: customerLocation.website || '',
    address: customerLocation.address || {
      address: '',
      address2: '',
      city: '',
      region: '',
      postalCode: '',
      country: 'US',
    },
    subType: customerLocation.subType || '',
    logoUrl:
      customerLocation.logoUrl ||
      `https://s3-us-west-2.amazonaws.com/${
        import.meta.env.VITE_ASSET_BUCKET
      }/assets/images/l/default.svg`,
    phone: customerLocation.phone || '',
    locationBilling:
      customerLocation.locationBilling !== null &&
      customerLocation.locationBilling !== undefined
        ? customerLocation.locationBilling
        : true,
    fullViewer:
      customerLocation.fullViewer !== null &&
      customerLocation.fullViewer !== undefined
        ? customerLocation.fullViewer
        : true,
    assignedManufacturers: !(
      Array.isArray(customerLocation.assignedManufacturers) &&
      customerLocation.assignedManufacturers.length > 0
    )
      ? []
      : customerLocation.assignedManufacturers.map((item) => {
          const { __typename, manufacturerOrganization, ...rest } = item;

          return { ...rest, _destroy: false };
        }),
  };
}

export default initialValues;
