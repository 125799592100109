import { ShowHeaderGeoData } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ShowHeaderAddress from './ShowHeaderAddress';
import Styles from './ShowHeaderLeftContent.module.css';

function ShowHeaderLeftContent({
  address,
  phone = null,
  website = null,
  geoData = null,
}) {
  const { t } = useTranslation('showelements');

  return (
    <>
      <ShowHeaderAddress address={address} />
      <ShowHeaderGeoData geoData={geoData} />
      {(phone !== null || website !== null) && (
        <p>
          {phone && (
            <>
              <span>{t('phone')}: </span>
              <a href={`tel:${phone}`} className={Styles.showHeaderLink}>
                {phone}
              </a>
            </>
          )}
          {phone && <span className={Styles.showHeaderSeparator} />}
          {website && (
            <>
              <span>{t('website')}: </span>
              <a href={website} className={Styles.showHeaderLink}>
                {
                  website.match(
                    
                    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/im,
                  )[1]
                }
              </a>
            </>
          )}
        </p>
      )}
    </>
  );
}

ShowHeaderLeftContent.propTypes = {
  address: PropTypes.object.isRequired,
  phone: PropTypes.string,
  website: PropTypes.string,
  geoData: PropTypes.object,
};

export default memo(ShowHeaderLeftContent);
