import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { useContext } from 'react';
import { OrthoContext } from '../../../../shared/Context/OrthoContext';
import ArchControls from '../ArchControls/ArchControls';
import './PerspectiveControl.scss';

function PerspectiveControl({ disabled = false }) {
  const { visibility, setVisibility, changePerspective, currentPerspective } =
    useContext(OrthoContext);

  return (
    <div className="perspectiveWrap">
      <div className="perspectiveInner">
        <div className="perspectiveInnerTop">
          <button
            type="button"
            disabled={disabled}
            className={`perspectiveButton perspectiveButtonTop ${
              currentPerspective === 'top' ? 'perspectiveActive' : ''
            }`}
            onClick={() => changePerspective('top')}
            style={{ margin: 0 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 107 107"
              style={{ marginTop: '-1px' }}
            >
      <title>svg</title>
      <g id="Layer_2">
                <g id="Layer_1-2">
                  <circle className="cls-1" cx="53.5" cy="53.5" r="53.5" />
                  <path
                    className="cls-2"
                    d="M81.25,82.05a7,7,0,0,1-7-7C74.25,55.14,64.94,39,53.5,39S32.75,55.14,32.75,75.05a7,7,0,0,1-14,0C18.75,46.49,33.69,25,53.5,25s34.75,21.54,34.75,50.1A7,7,0,0,1,81.25,82.05Z"
                  />
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div className="perspectiveInnerMiddle">
          <button
            type="button"
            disabled={disabled}
            onClick={() => changePerspective('left')}
            className={`perspectiveButton perspectiveButtonLeft ${
              currentPerspective === 'left' ? 'perspectiveActive' : ''
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107 107">
      <title>svg</title>
      <g id="Layer_2">
                <g id="Layer_1-2">
                  <circle className="cls-1" cx="53.5" cy="53.5" r="53.5" />
                  <path
                    className="cls-2"
                    d="M36.17,42.52l18.91,2.24.54-4.53L28.42,37,27.19,64.37l4.55.21.77-17.15C40.31,55.86,54.79,66.8,79,70L79.81,64C56.52,61,43.08,50.32,36.17,42.52Z"
                  />
                </g>
              </g>
            </svg>
          </button>

          <button
            type="button"
            disabled={disabled}
            onClick={() => changePerspective('reset')}
            className={`perspectiveButton perspectiveButtonCenter ${
              currentPerspective === null || currentPerspective === 'reset'
                ? 'perspectiveActive'
                : ''
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107 107">
      <title>svg</title>
      <g id="Layer_2">
                <g id="Layer_1-2">
                  <circle className="cls-1" cx="53.5" cy="53.5" r="53.5" />
                  <path
                    className="cls-2"
                    d="M24.33,51.75a6.11,6.11,0,0,1-2.55-11.66c8.6-4,20.3-6.22,32.1-6.22,11.29,0,22.67,2.12,31.22,5.83a6.1,6.1,0,0,1-4.86,11.2c-7.07-3.06-16.68-4.82-26.36-4.82-10,0-20.05,1.91-27,5.11A6.09,6.09,0,0,1,24.33,51.75Z"
                  />
                  <path
                    className="cls-2"
                    d="M82.67,55.25a6.11,6.11,0,0,1,2.55,11.66c-8.6,4-20.3,6.22-32.1,6.22C41.83,73.13,30.45,71,21.9,67.3a6.1,6.1,0,1,1,4.86-11.2c7.07,3.06,16.68,4.82,26.36,4.82,10,0,20.05-1.91,27-5.11A6.09,6.09,0,0,1,82.67,55.25Z"
                  />
                </g>
              </g>
            </svg>
          </button>
          <button
            type="button"
            disabled={disabled}
            onClick={() => changePerspective('right')}
            className={`perspectiveButton perspectiveButtonRight ${
              currentPerspective === 'right' ? 'perspectiveActive' : ''
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107 107">
      <title>svg</title>
      <g id="Layer_2">
                <g id="Layer_1-2">
                  <circle className="cls-1" cx="53.5" cy="53.5" r="53.5" />
                  <path
                    className="cls-2"
                    d="M70.83,42.52,51.92,44.76l-.54-4.53L78.58,37l1.23,27.36-4.55.21-.77-17.15C66.69,55.86,52.21,66.8,28,70L27.19,64C50.48,61,63.92,50.32,70.83,42.52Z"
                  />
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div className="perspectiveInnerBottom">
          <button
            type="button"
            disabled={disabled}
            onClick={() => changePerspective('down')}
            className={`perspectiveButton perspectiveButtonBottom ${
              currentPerspective === 'down' ? 'perspectiveActive' : ''
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107 107">
      <title>svg</title>
      <g id="Layer_2">
                <g id="Layer_1-2">
                  <circle className="cls-1" cx="53.5" cy="53.5" r="53.5" />
                  <path
                    className="cls-2"
                    d="M25.75,25a7,7,0,0,1,7,7c0,19.91,9.31,36.1,20.75,36.1S74.25,51.86,74.25,32a7,7,0,0,1,14,0c0,28.56-14.94,50.1-34.75,50.1S18.75,60.51,18.75,32A7,7,0,0,1,25.75,25Z"
                  />
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
      <ArchControls visibility={visibility} setVisibility={setVisibility} />
    </div>
  );
}

PerspectiveControl.propTypes = exact({
  disabled: PropTypes.bool,
});

export default PerspectiveControl;
