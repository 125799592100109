import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';

const OPTIONS = { passive: false };

function NonPassiveTouchTarget({
  component = 'div',
  onTouchMove = () => ({}),
  ...rest
}) {
  const props = { component, onTouchMove, ...rest };
  let node = {};
  useEffect(() => {
    node.addEventListener('touchmove', onTouchMove, OPTIONS);
    return () => {
      node.removeEventListener('touchmove', onTouchMove, OPTIONS);
    };
  }, []);
  useEffect(() => {
    node.removeEventListener('touchmove', onTouchMove, OPTIONS);
    node.addEventListener('touchmove', onTouchMove, OPTIONS);
  }, [onTouchMove]);

  function ref(_node) {
    node = _node;
  }
  const { Component } = component;
  return <Component {...props} ref={ref} />;
}

NonPassiveTouchTarget.propTypes = {
  onTouchMove: PropTypes.func,
  component: PropTypes.node,
};

export default memo(NonPassiveTouchTarget);
