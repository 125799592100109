import { CREATE_CUSTOMER_USER } from '@fullcontour/shared-api';

async function create({
  variables: { variables },
  client,
  setErrorMessages,
  clearErrorMessages,
  navigate,
}) {
  const result = await client.mutate({
    mutation: CREATE_CUSTOMER_USER,
    variables,
  });
  const { createCustomerUser } = result.data;
  if (createCustomerUser.errorMessages.length > 0) {
    setErrorMessages(createCustomerUser.errorMessages);
  } else {
    clearErrorMessages();
    navigate('/users');
  }
}
export default create;
