const calculateMovementsPerStage = (stages) => {
  const updatedStages =
    stages.length > 0
      ? stages.map((item, idx) => ({
        ...item,

        Teeth: (() =>
          item.Teeth.map((tooth, toothIdx) => ({
            ...tooth,
            Attachments: (() => {
              if (!idx) {
                return tooth.Attachments;
              }
              return (
                stages[Number(idx - 1)].Teeth[toothIdx].Attachments !==
                tooth.Attachments
              );
            })(),
            InterproximalReduction: (() => {
              if (!tooth.InterproximalReduction) {
                return null;
              }

              const keysToCheck = {
                DistalReductionValue: true,
                MesialReductionValue: true,
              };
              const reverseKeys = {
                DistalReductionValue: 'MesialReductionValue',
                MesialReductionValue: 'DistalReductionValue',
              };

              if (tooth.InterproximalReduction.Value) {
                const calculateNewKeyValue = (currentValue, previousValue) => currentValue - previousValue;

                const initializeValueObject = (toothValue, key, newValueForAKey, reverseKeys) => ({
                  Value: {
                    ...toothValue,
                    [key]: newValueForAKey,
                    isVisible: true,
                    [`${key}Visible`]: true,
                    [`${reverseKeys[key]}Visible`]: false,
                  },
                });

                const handleExistingValueObject = (obj, key, newValueForAKey, reverseKeys) => {
                  obj.Value[key] = newValueForAKey;
                  obj.Value.isVisible = true;
                  obj.Value[`${key}Visible`] = true;
                  obj.Value[`${reverseKeys[key]}Visible`] = false;
                  return obj;
                };

                const handleNoDifferenceValueObject = (obj, toothValue) => ({
                  Value: {
                    ...(Object.keys(obj).length > 0 ? obj.Value : { ...toothValue, isVisible: false }),
                  },
                });

                // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
                const processKey = (obj, key, tooth, stages, idx, toothIdx, keysToCheck, reverseKeys) => {
                  if (!keysToCheck[key]) { return obj; }
                
                  const currentValue = tooth.InterproximalReduction.Value[key];
                  const previousStageValue = stages[idx - 1]?.Teeth[toothIdx]?.InterproximalReduction?.Value?.[key];
                  if (previousStageValue === undefined) {
                    return { Value: { ...tooth.InterproximalReduction.Value, isVisible: true } };
                  }
                  const difference = calculateNewKeyValue(currentValue, previousStageValue);
                  if (difference) {
                    return obj.Value
                      ? handleExistingValueObject(obj, key, difference, reverseKeys)
                      : initializeValueObject(tooth.InterproximalReduction.Value, key, difference, reverseKeys);
                  }
                  return handleNoDifferenceValueObject(obj, tooth.InterproximalReduction.Value);
                };
                const result = Object.keys(tooth.InterproximalReduction.Value).reduce(
                  (obj, key) => processKey(obj, key, tooth, stages, idx, toothIdx, keysToCheck, reverseKeys),
                  {}
                );

                return result
              }

              return {};
            })(),
            Movement: {
              ...tooth.Movement,
              Value: {
                ...tooth?.Movement?.Value,
                MovementValues: {
                  Value: (() => {
                    const valuesObj =
                      tooth.Movement?.Value.MovementValues?.Value;
                    if (valuesObj && typeof valuesObj === 'object') {
                      return Object.keys(valuesObj).reduce((obj, key) => {
                        if (stages[Number(idx)] && idx) {
                          obj[key] =
                            valuesObj[key] -
                            stages[Number(idx) - 1].Teeth[Number(toothIdx)]
                              .Movement?.Value.MovementValues?.Value[key];
                        }

                        return obj;
                      }, valuesObj);
                    }
                    return {};
                  })(),
                },
              },
            },
          })))(),
      }))
      : null;

  return updatedStages?.length > 0
    ? updatedStages.map((stage) => {
      stage.Teeth = stage.Teeth.filter(
        (tooth) =>
          Object.keys(tooth.Movement.Value.MovementValues.Value).length,
      );
      return stage;
    })
    : null;
};

export default calculateMovementsPerStage;
