import PropTypes from 'prop-types';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import BoxIcon from '../../Icons/BoxIcon';
import Highlighted from '../Highlighted/Highlighted';
import ElementResizeListener from './ElementResizeListener';

const AccordionCard = forwardRef(({ card, searchValue }, ref) => {
  const [clicked, setClicked] = useState(false);
  const [height, setHeight] = useState(null);
  const contentEl = useRef();
  const bodyRef = useRef();

  useEffect(() => {
    if (card.isActive !== undefined) {
      setClicked(card?.isActive);
    }
  }, [card.isActive]);

  const deleteHandler = () => {
    card.callback(card.cardId);
  };

  const handleToggle = () => {
    if (card.callback) {
      card.callback(card.cardIndex);
    }
    setClicked((prev) => !prev);
  };

  const adaptResize = useCallback(() => {
    if (bodyRef.current) {
      const elmRect = bodyRef.current.getBoundingClientRect();
      setHeight(elmRect.height);
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    adaptResize();
  }, []);

  const isControllable =
    card?.isActive === undefined ? clicked : card?.isActive;

  return (
    <li
      ref={ref}
      className={`accordion_item is-flex ${isControllable ? 'active' : ''}`}
    >
      {card.editMode && (
        <button
          type="button"
          className="button is-small is-danger is-outlined card__delete-btn mr-2"
          onClick={deleteHandler}
        >
          <BoxIcon name="bx-trash" />
        </button>
      )}
      <div className="card card__accordion">
        {/* biome-ignore lint/nursery/noStaticElementInteractions: <explanation> */}
<header className="card-header is-clickable" onClick={handleToggle}>
          <p className="card-header-title is-flex is-align-items-baseline">
            <Highlighted text={card.cardHeader} highlight={searchValue} />
            <span className="is-size-7 has-text-weight-light has-text-grey-light ml-3">
              {card.cardHeaderText || ''}
            </span>
          </p>
          <div className="icon is-clickable is-flex is-justify-content-flex-end">
            <i className="bx bx-chevron-down is-size-3 chevron-down" />
          </div>
        </header>
        <div
          ref={contentEl}
          className="answer_wrapper"
          style={{
            height: isControllable
              ? height || contentEl.current?.scrollHeight
              : 0,
          }}
        >
          <div className="card-content" ref={bodyRef}>
            <ElementResizeListener onResize={adaptResize} />
            {card.cardBody}
          </div>
        </div>
      </div>
    </li>
  );
});

AccordionCard.propTypes = {
  card: PropTypes.shape({
    cardBody: PropTypes.object,
    cardHeader: PropTypes.elementType,
    callback: PropTypes.func,
    cardId: PropTypes.string,
    cardHeaderText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    editMode: PropTypes.bool,
    isActive: PropTypes.bool,
    cardIndex: PropTypes.number,
  }).isRequired,
  searchValue: PropTypes.string,
};

AccordionCard.defaultProps = {
  searchValue: '',
};

export default memo(AccordionCard);
