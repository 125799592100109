import PropTypes from 'prop-types';

function Dropdown({
  name,
  setFieldTouched,
  value = null,
  id = null,
  placeholder = null,
  onChange = () => null,
  options = [],
  error = null,
  errors = false,
  loading = false,
  disabled = false,
  dropdownStyle = {},
  dropdownClasses = null,
  size = '',
}) {
  function handleBlur() {
    setFieldTouched(name, true);
  }

  return (
    <>
      {error && <p>Error...</p>}
      {!error && (
        <div
          className={`select ${loading ? 'is-loading' : ''}${size}`}
          style={{ width: '100%' }}
        >
          <select
            style={{ width: '100%', dropdownStyle }}
            onChange={onChange}
            onClick={(e) => e.stopPropagation()}
            onBlur={handleBlur}
            value={!value && placeholder ? placeholder : value}
            id={id || name}
            name={name}
            className={`${errors ? 'is-danger' : ''}${dropdownClasses}${size}`}
            disabled={disabled}
          >
            <option value="">Please Select</option>

            {options?.map((d) => (
              <option
                className={size}
                key={d.id || d.value}
                value={d.value}
                style={{ width: '100%' }}
              >
                {d.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  error: PropTypes.object,
  errors: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  dropdownStyle: PropTypes.object,
  dropdownClasses: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.string,
  setFieldTouched: PropTypes.func.isRequired,
};

export default Dropdown;
