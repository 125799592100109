export default function NewspaperSvg() {
  return (
    <svg height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>svg</title>
      <path
        d="M37.8305 0H3.52007C2.57439 0.0137169 1.67277 0.401933 1.01292 1.0795C0.353076 1.75707 -0.0110918 2.6687 0.000257535 3.61441V27.6783C-0.0110907 28.624 0.353133 29.5355 1.01299 30.2129C1.67286 30.8904 2.57445 31.2784 3.52007 31.292H37.8305C38.7762 31.2784 39.6778 30.8904 40.3378 30.2129C40.9977 29.5355 41.3621 28.624 41.3509 27.6783V3.61441C41.3621 2.66865 40.9978 1.75705 40.3378 1.0795C39.6779 0.401951 38.7762 0.0137173 37.8305 0ZM3.52007 1.17768H37.8305C38.3758 1.18659 38.9014 1.3834 39.3186 1.73474C39.7358 2.08609 40.0191 2.57055 40.1206 3.10644H1.24434C1.23955 3.10644 1.23481 3.10644 1.23002 3.10644C1.33146 2.5705 1.61466 2.08596 2.03187 1.7346C2.44907 1.38324 2.97469 1.18648 3.52007 1.17768ZM37.8305 30.1145H3.52007C2.88667 30.1009 2.28448 29.8368 1.84545 29.38C1.40642 28.9233 1.16639 28.3112 1.17794 27.6777V4.27097C1.19984 4.27647 1.22199 4.28085 1.24434 4.28412H40.1343C40.1474 4.28223 40.1605 4.27953 40.1733 4.27623V27.6783C40.1847 28.3117 39.9444 28.9238 39.5052 29.3805C39.0661 29.8371 38.4639 30.1011 37.8305 30.1145Z"
        fill="#4C4D52"
      />
      <path
        d="M35.8539 22.3107H5.50019C5.42132 22.3132 5.34274 22.2998 5.26914 22.2714C5.19554 22.2429 5.12844 22.2001 5.07176 22.1452C5.01509 22.0903 4.96996 22.0245 4.93918 21.9519C4.90841 21.8792 4.89258 21.8011 4.89258 21.7222C4.89258 21.6433 4.90841 21.5652 4.93918 21.4926C4.96996 21.4199 5.01509 21.3542 5.07176 21.2993C5.12844 21.2444 5.19554 21.2013 5.26914 21.1729C5.34274 21.1445 5.42132 21.1311 5.50019 21.1336H35.8539C36.0067 21.1385 36.1517 21.2026 36.2581 21.3124C36.3644 21.4223 36.4239 21.5693 36.4239 21.7222C36.4239 21.8751 36.3644 22.022 36.2581 22.1319C36.1517 22.2417 36.0067 22.3058 35.8539 22.3107Z"
        fill="#4C4D52"
      />
      <path
        d="M35.783 26.6435H5.42861C5.27244 26.6435 5.12274 26.5814 5.01231 26.471C4.90188 26.3605 4.83984 26.2108 4.83984 26.0546C4.83984 25.8984 4.90188 25.7486 5.01231 25.6382C5.12274 25.5278 5.27244 25.4658 5.42861 25.4658H35.783C35.9391 25.4658 36.0889 25.5278 36.1993 25.6382C36.3098 25.7486 36.3718 25.8984 36.3718 26.0546C36.3718 26.2108 36.3098 26.3605 36.1993 26.471C36.0889 26.5814 35.9391 26.6435 35.783 26.6435Z"
        fill="#4C4D52"
      />
      <path
        d="M35.8524 9.3123H18.6009C18.4446 9.3123 18.2947 9.25024 18.1843 9.13976C18.0738 9.02927 18.0117 8.87949 18.0117 8.72324C18.0117 8.56699 18.0738 8.41706 18.1843 8.30657C18.2947 8.19609 18.4446 8.13403 18.6009 8.13403H35.8524C36.0086 8.13403 36.1585 8.19609 36.269 8.30657C36.3794 8.41706 36.4415 8.56699 36.4415 8.72324C36.4415 8.87949 36.3794 9.02927 36.269 9.13976C36.1585 9.25024 36.0086 9.3123 35.8524 9.3123Z"
        fill="#4C4D52"
      />
      <path
        d="M35.8521 13.6484H18.6006C18.4444 13.6484 18.2946 13.5864 18.1842 13.476C18.0738 13.3656 18.0117 13.2158 18.0117 13.0596C18.0117 12.9034 18.0738 12.7537 18.1842 12.6432C18.2946 12.5328 18.4444 12.4707 18.6006 12.4707H35.8521C36.0082 12.4707 36.158 12.5328 36.2684 12.6432C36.3789 12.7537 36.4409 12.9034 36.4409 13.0596C36.4409 13.2158 36.3789 13.3656 36.2684 13.476C36.158 13.5864 36.0082 13.6484 35.8521 13.6484Z"
        fill="#4C4D52"
      />
      <path
        d="M35.8524 17.9771H18.6009C18.4446 17.9771 18.2947 17.915 18.1843 17.8046C18.0738 17.6941 18.0117 17.5443 18.0117 17.388C18.0117 17.2318 18.0738 17.0819 18.1843 16.9714C18.2947 16.8609 18.4446 16.7988 18.6009 16.7988H35.8524C36.0086 16.7988 36.1585 16.8609 36.269 16.9714C36.3794 17.0819 36.4415 17.2318 36.4415 17.388C36.4415 17.5443 36.3794 17.6941 36.269 17.8046C36.1585 17.915 36.0086 17.9771 35.8524 17.9771Z"
        fill="#4C4D52"
      />
      <path
        d="M9.55705 13.0513C8.94883 13.0512 8.35433 12.8707 7.84868 12.5327C7.34302 12.1947 6.94893 11.7143 6.71629 11.1524C6.48364 10.5904 6.42285 9.97207 6.54163 9.37556C6.6604 8.77904 6.95342 8.23107 7.38358 7.80108C7.81374 7.37109 8.36172 7.0784 8.95827 6.95986C9.55483 6.84132 10.1731 6.90231 10.735 7.13518C11.2969 7.36804 11.7772 7.76222 12.115 8.268C12.4528 8.77379 12.633 9.36837 12.6329 9.9766C12.6318 10.7919 12.3073 11.5735 11.7307 12.1499C11.154 12.7263 10.3724 13.0505 9.55705 13.0513ZM9.55705 8.0791C9.18189 8.07922 8.81521 8.19054 8.50333 8.39905C8.19146 8.60756 7.9484 8.90386 7.80492 9.2505C7.66144 9.59713 7.62398 9.97858 7.69725 10.3465C7.77051 10.7144 7.95123 11.0524 8.21654 11.3176C8.48186 11.5828 8.81983 11.7634 9.18779 11.8365C9.55575 11.9097 9.93714 11.8722 10.2837 11.7286C10.6303 11.585 10.9265 11.3417 11.135 11.0298C11.3434 10.7179 11.4546 10.3512 11.4546 9.97601C11.4538 9.47305 11.2536 8.99088 10.8979 8.63529C10.5422 8.27969 10.06 8.07973 9.55705 8.0791Z"
        fill="#C2003E"
      />
      <path
        d="M13.6986 18.9237C13.6213 18.9238 13.5447 18.9086 13.4732 18.879C13.4017 18.8494 13.3367 18.8062 13.282 18.7515C13.2273 18.6968 13.184 18.6319 13.1544 18.5604C13.1248 18.4889 13.1097 18.4123 13.1098 18.3349C13.0386 17.449 12.6363 16.6224 11.9831 16.0196C11.33 15.4169 10.4739 15.0823 9.58513 15.0823C8.69637 15.0823 7.84018 15.4169 7.18704 16.0196C6.5339 16.6224 6.13171 17.449 6.06049 18.3349C6.06049 18.4911 5.99845 18.6409 5.88803 18.7513C5.7776 18.8618 5.62782 18.9237 5.47165 18.9237C5.31548 18.9237 5.16571 18.8618 5.05528 18.7513C4.94485 18.6409 4.88281 18.4911 4.88281 18.3349C4.95439 17.1363 5.48096 16.0102 6.35495 15.1869C7.22894 14.3635 8.38439 13.905 9.58513 13.905C10.7859 13.905 11.9412 14.3635 12.8152 15.1869C13.6892 16.0102 14.2159 17.1363 14.2874 18.3349C14.2875 18.4123 14.2723 18.4889 14.2427 18.5604C14.2132 18.6319 14.1698 18.6968 14.1151 18.7515C14.0604 18.8062 13.9955 18.8494 13.924 18.879C13.8526 18.9086 13.776 18.9238 13.6986 18.9237Z"
        fill="#C2003E"
      />
    </svg>
  );
}
