import { useApolloClient } from '@apollo/client';
import { StlViewer } from '@fullcontour/threed';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import latestPtsFile from '../../../transforms/latestPtsFile';

function OrthoStlViewer({
  hexOrderCode,
  file = null,
  allFiles = null,
  containerStyle = {},
  orderName = '',
}) {
  const client = useApolloClient();

  const [ptsData, setPtsData] = useState();

  const ptsMemo = useMemo(() => ptsData, [ptsData]);

  useEffect(() => {
    if (ptsMemo !== undefined) {
      return;
    }
    let active = true;
    loadPts();

    return () => {
      active = false;
    };

    async function loadPts() {
      setPtsData(undefined); // this is optional
      const ptsRes = await Promise.resolve(
        latestPtsFile(allFiles, file, client, orderName, hexOrderCode),
      );

      if (!active) {
        return;
      }
      setPtsData(ptsRes);
    }
  }, []);

  return file ? (
    <StlViewer file={file} containerStyle={containerStyle} ptsData={ptsData} />
  ) : null;
}

OrthoStlViewer.propTypes = {
  file: PropTypes.object,
  allFiles: PropTypes.array,
  containerStyle: PropTypes.object,
  orderName: PropTypes.string,
  hexOrderCode: PropTypes.string.isRequired,
};

export default OrthoStlViewer;
