import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { NUMERIC_SYSTEM } from '../../../../shared';
import NonFormDropdown from '../../../../shared/FormFields/Dropdowns/NonFormDropdown';
import { approvalLogCreate } from '../../OrderActions/helpers';

function ReviewModalBodyDrApproval(props) {
  const {
    docApproval,
    docApprovalOff,
    patientApproval,
    redesign,
    order,
    closeAndReset,
  } = props;

  const client = useApolloClient();

  const showDN = order?.orderItems?.some(
    (item) => item.designType.clearAlignerDesignType,
  );

  const [copied, setCopied] = useState(false);
  const [copiedText, setCopiedText] = useState(null);
  const [logId, setLogId] = useState(null);
  const [email, setEmail] = useState(null);
  const [copiedToken, setCopiedToken] = useState(null);
  const [numSystem, setNumSystem] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (order?.location) {
      setLocation(order.location);
      setNumSystem(order.location.numberingSystem);
    }
  }, [order]);

  const { t } = useTranslation('formfields');

  const createLog = async ({ doctor, withemail }) => {
    const doctorEmail = withemail === true ? email : null;
    const doctorApproval = doctor === true;

    const log = await approvalLogCreate(
      client,
      order.id,
      doctorApproval,
      doctorEmail,
      numSystem,
    );

    const {
      createOrderApprovalLog: { orderApprovalLog = {} } = {},
    } = log;

    if (!doctorEmail) {
      setLogId(orderApprovalLog.id);
      setCopiedText(
        `${
          location.doctorApprovalLight
            ? import.meta.env.VITE_APPROVAL_LITE_URL
            : import.meta.env.VITE_APPROVAL_URL
        }/${orderApprovalLog.id}`,
      );
      setCopiedToken(`${orderApprovalLog.accessToken}`);
    }

    if (doctorEmail) {
      closeAndReset();
    }
  };

  const handleUniversalNotation = (e) => {
    setNumSystem(e.target.value);
  };

  return docApproval && !redesign && !patientApproval ? (
    <>
      <p>
        To have 3Shape Design Service send a notification, enter the email
        address of the approving Doctor for this case and click &quot;Send&quot;
        below. Or click &quot;Generate Link&quot; for a URL that you can forward
        as needed.
      </p>
      <div
        className="columns is-mobile"
        style={{ marginTop: 20, width: '100%' }}
      >
        <div className="column is-11 is-10-tablet is-9-mobile">
          <input
            type="text"
            className="input"
            label="Doctor Email"
            placeholder="drJohnDoe@email.com"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div
          className="column has-text-right is-1 is-2-tablet is-3-mobile"
          style={{ verticalAlign: 'top' }}
        >
          <button
            type="button"
            onClick={() => createLog({ doctor: true, withemail: true })}
            className="button is-warning is-pulled-right mt-1"
          >
            Send
          </button>
        </div>
      </div>
      {showDN && (
        <div style={{ marginTop: 20 }}>
          <div className="column is-9">
            <div className="is-flex is-align-items-center">
              <span>Dental notation shown to doctor:</span>
              <NonFormDropdown
                id="univeralDentalNotation"
                name="univeralDentalNotation"
                options={NUMERIC_SYSTEM}
                placeholder={`${t('Select a Notation System')}...`}
                onChange={handleUniversalNotation}
                value={numSystem}
              />
            </div>
          </div>
        </div>
      )}
      <div style={{ minHeight: 65, marginTop: 20 }}>
        {logId && (
          <p id="doc-link">
            <span style={{ fontWeight: 700, fontSize: '.92857143em' }}>
              Doctor Link:
            </span>
            <br /> {copiedText}
          </p>
        )}
        {logId && !location.doctorApprovalLight && (
          <p id="access-code">
            <span style={{ fontWeight: 700, fontSize: '.92857143em' }}>
              Access Token:
            </span>
            <br /> {copiedToken}
          </p>
        )}
        {copied && <h6 className="title is-6 has-text-danger">copied!</h6>}
      </div>
      <div className="is-flex mt-4">
        <button
          onClick={docApprovalOff}
          type="button"
          className="button mr-auto"
        >
          Go Back
        </button>
        <div className="buttons">
          {logId && (
            <CopyToClipboard text={copiedText} onCopy={() => setCopied(true)}>
              <button type="button" className="button is-primary">
                Copy Link
              </button>
            </CopyToClipboard>
          )}
          {logId && !location.doctorApprovalLight && (
            <CopyToClipboard text={copiedToken} onCopy={() => setCopied(true)}>
              <button type="button" className="button is-primary">
                Copy Access Token
              </button>
            </CopyToClipboard>
          )}
          <button
            type="button"
            className="button is-primary"
            onClick={() => createLog({ doctor: true, withemail: false })}
          >
            Generate Link...
          </button>
        </div>
      </div>
    </>
  ) : null;
}

ReviewModalBodyDrApproval.propTypes = {
  docApproval: PropTypes.bool.isRequired,
  redesign: PropTypes.bool.isRequired,
  docApprovalOff: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  closeAndReset: PropTypes.func.isRequired,
  patientApproval: PropTypes.bool.isRequired,
};

export default ReviewModalBodyDrApproval;
