import { useMutation } from '@apollo/client';
import { BoxIcon, CurrentUserContext } from '@fullcontour/common';
import { REQUEST_S3_SIGN } from '@fullcontour/shared-api';
import axios from 'axios';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { memo, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  activeStyle,
  baseStyle,
  fileErrorStyle,
  rejectedStyle,
  successStyle,
} from './CaseFolderStyle';

function FolderField({
  push = () => ({}),
  replace = () => ({}),
  values = {},
  name = null,
  accept = {},
  allowMultiple = false,
  description = '',
  folderName = null,
  caseFolderId = null,
  error = null,
  uniqueCaseId = null,
  touched = false,
}) {
  const user = useContext(CurrentUserContext);
  const { t } = useTranslation('formfields');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [componentFiles, setComponentFiles] = useState([]);
  const [signS3] = useMutation(REQUEST_S3_SIGN);

  const onDrop = async (accepted) => {
    await Promise.all(accepted.map((file) => signAndSubmit(file)));
  };

  const {
    acceptedFiles,
    fileRejections,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept,
    multiple: allowMultiple,
    onDrop,
  });

  const formatFilename = (filename) => {
    const date = format(new Date(), 'yyyyMMdd');
    const randomString = Math.random().toString(36).substring(2, 7);
    const splitFilename = filename.split('.');
    return `${splitFilename[0]
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .substring(0, 43)}-${randomString}-${date}.${splitFilename.pop()}`;
  };

  const uploadToS3 = async (signedRequest, file) => {
    setUploading(true);
    await axios.put(signedRequest, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: ({ loaded, total }) => {
        const percent = Math.round((loaded * 100) / total);
        setUploadProgress(percent >= 100 ? 100 : percent);
        if (percent >= 100) { setUploading(false); }
      },
    });
  };

  const signAndSubmit = async (file) => {
    const s3Key = `orders/${user.currentUser.locationId}/${uniqueCaseId}/initial_scans/${formatFilename(file.name)}`;
    const { data } = await signS3({
      variables: {
        input: {
          input: {
            originalFilename: file.name,
            filename: s3Key,
            acl: 'private',
            filetype: file.type,
          },
          clientMutationId: uuidv4(),
        },
      },
    });

    const { signedRequest, url } = data.signS3.s3Response;
    await uploadToS3(signedRequest, file);

    const fileEntry = {
      fileUrl: url,
      s3Key,
      originalFileName: file.name,
      fileType: 'initial_scans',
      caseFolderId,
    };

    if (allowMultiple || componentFiles.length === 0) {
      push(fileEntry);
      setComponentFiles((prev) => [...prev, s3Key]);
    } else {
      const index = values.files.findIndex((f) => f.s3Key === componentFiles[0]);
      replace(index, fileEntry);
      setComponentFiles([s3Key]);
    }
  };

  const acceptedFileItems = acceptedFiles.map((file) => (
    <p key={file.path} style={{ color: 'rgba(83, 201, 87)' }}>
      <BoxIcon name="bx-check" className="is-size-small has-text-centered mt-2" />
      &quot;{file.path.slice(-20)}&quot;
    </p>
  ));

  const fileError = fileRejections.length > 0;
  const fileErrorMessage = fileRejections[0]?.errors[0]?.message || '';
  const acceptedFileExtension = Object.values(accept).flat().join(', ');

  const styles = {
    ...baseStyle,
    ...(isDragActive || uploading ? activeStyle : {}),
    ...(error && touched ? rejectedStyle : {}),
    ...(values.files.some((v) => componentFiles.includes(v.s3Key)) ? successStyle : {}),
    ...(fileError ? fileErrorStyle : {}),
  };

  return (
    <div className="is-flex is-flex-direction-column column">
      <div
        className="column is-flex is-3 is-justify-content-center has-text-centered m-1 align-items-center"
        style={styles}
        {...getRootProps()}
      >
        <figure className="is-justify-content-center has-text-centered align-items-center">
          <BoxIcon
            name={
              values.files.some((v) => componentFiles.includes(v.s3Key))
                ? 'bx-check'
                : uploading
                ? 'bx-loader bx-spin'
                : 'bx-cloud-upload'
            }
            className="is-size-1 mt-2"
          />
          <input {...getInputProps()} name={name} />
          <figcaption>
            <p style={{ fontSize: '0.9rem' }}>
              {t(error ? `${folderName} is required*` : folderName)}
            </p>
            {fileErrorMessage.includes('File type must be') && (
              <small style={{ fontSize: '0.7rem', color: 'rgba(228, 0, 0, 0.9)' }}>
                Only the following file types can be uploaded: {acceptedFileExtension}
              </small>
            )}
            <p style={{ fontSize: '0.7rem', color: fileError ? 'rgba(228, 0, 0, 0.9)' : '' }}>
              ({acceptedFileExtension})<br />
              {description}
            </p>
            {fileErrorMessage.includes('Too many files') && (
              <small style={{ fontSize: '0.7rem', color: 'rgba(228, 0, 0, 0.9)' }}>
                Only one file can be uploaded here
              </small>
            )}
            {uploading && (
              <progress
                value={uploadProgress}
                max="100"
                className="m2 progress is-small is-primary is-justify-content-center"
              />
            )}
          </figcaption>
        </figure>
      </div>
      {acceptedFiles.length > 0 && <div className="is-flex is-flex-direction-column"><small>{acceptedFileItems}</small></div>}
    </div>
  );
}

FolderField.propTypes = {
  touched: PropTypes.bool,
  values: PropTypes.object,
  push: PropTypes.func,
  replace: PropTypes.func,
  name: PropTypes.string,
  accept: PropTypes.object,
  allowMultiple: PropTypes.bool,
  description: PropTypes.string,
  folderName: PropTypes.string,
  caseFolderId: PropTypes.string,
  error: PropTypes.string,
  uniqueCaseId: PropTypes.string,
};

export default memo(FolderField);
