import { useReactiveVar } from '@apollo/client';
import { ModifierOrderConsumer, getHelpers } from '@fullcontour/common';
import { FieldArray } from 'formik';
import ModifierModalDropdown from './ModifierModalDropdown';

function ModifierModal() {
  const helpFuncsData = useReactiveVar(getHelpers);
  return (
    <ModifierOrderConsumer>
      {({
        showModifierModal,
        modifierModalClose,
        modifierGroups,
        currentOrderItem,
        orderItemIndex,
      }) =>
        modifierGroups?.length > 0 ? (
          <div className={`modal ${showModifierModal ? 'is-active' : ''}`}>
            <div className="modal-background" />
            <div className="modal-card">
              <header className="modal-card-head">
                <h4 className="title is-4 modal-card-title">
                  Modifiers Selection
                </h4>
              </header>
              <section className="modal-card-body">
                <FieldArray
                  name={`orderItems[${orderItemIndex}].orderItemModifiersAttributes`}
                  render={(arrayHelpers) =>
                    modifierGroups.map((group) =>
                      group.modifiers?.length > 0 ? (
                        <div key={group.id} className="mb-1">
                          <label htmlFor={group.id}>{group.label}</label>
                          <ModifierModalDropdown
                            modifiers={group.modifiers}
                            group={group}
                            orderItemIndex={orderItemIndex}
                            currentOrderItem={currentOrderItem}
                            arrayHelpers={arrayHelpers}
                          />
                        </div>
                      ) : null,
                    )
                  }
                />
              </section>
              <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
                <button
                  type="button"
                  data-cy="close-modifiers"
                  onClick={() => {
                    helpFuncsData?.helpFuncs?.next();
                    modifierModalClose();
                  }}
                  className="button is-dark"
                >
                  Close
                </button>
              </footer>
            </div>
          </div>
        ) : null
      }
    </ModifierOrderConsumer>
  );
}

export default ModifierModal;
