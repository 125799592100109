import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  CurrentUserContext,
  CustomerLocationContext,
  Message,
} from '../../shared';

function PriorityMessage() {
  const { customerLocation } = useContext(CustomerLocationContext);
  const { currentUser } = useContext(CurrentUserContext);
  const [visible, setVisible] = useState(true);

  function dismiss() {
    setVisible(false);
  }

  const shouldDisplayMessage =
    visible &&
    currentUser?.scopeToLocation &&
    (customerLocation.suspended || customerLocation.inactiveBilling);

  let title = '';

  if (currentUser?.scopeToLocation) {
    if (customerLocation.suspended) {
      title = 'Your account is currently suspended';
    } else if (customerLocation.inactiveBilling) {
      title = 'There is a problem with your billing information';
    }
  }

  return shouldDisplayMessage ? (
    <Message
      type="warning"
      title={title}
      onDismiss={dismiss}
      dismissable={!customerLocation.suspended}
      style={{
        position: 'fixed',
        marginLeft: '10%',
        marginRight: '10%',
        width: '80%',
        zIndex: '9999',
        top: '45px',
      }}
    >
      {customerLocation.suspended && (
        <p>
          Your account is currently suspended. Please contact support for more
          information.
        </p>
      )}
      {customerLocation.inactiveBilling && !customerLocation.suspended && (
        <p>
          Please add a new payment method
          <NavLink to="/locations/billing">here</NavLink> to go to your billing
          settings.
        </p>
      )}
    </Message>
  ) : null;
}

PriorityMessage.propTypes = {};

export default PriorityMessage;
