import { MovementTableWithViewerContext } from '@fullcontour/threed';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import {
  TeethChartActions,
  TeethChartLegend,
  TeethChartPDF,
  TeethChartTable,
} from './components';
import './components/TeethChart.css';

function TeethChart({
  order,
  dentalNotation = null,
  redesignCountObj = null,
  designerMessagesCurrent = null,
  treatmentReviewFiles = null,
}) {
  const {
    orderStages,
    isTotal,
    setIsTotal,
    activeStage,
    setActiveStage,
    total,
    recalculatedStage,
  } = useContext(MovementTableWithViewerContext);

  if (!(order?.otpFiles && recalculatedStage)) {
    return null;
  }

  return (
    <div style={{ marginTop: 30 }}>
      <TeethChartActions
        stages={orderStages.length}
        isTotal={isTotal}
        setIsTotal={setIsTotal}
        selectedStages={activeStage}
        setSelectedStages={setActiveStage}
      />
      <TeethChartTable
        location={order.location}
        dentalNotation={dentalNotation}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 30,
          flexWrap: 'wrap',
        }}
      >
        <TeethChartLegend />
        <TeethChartPDF
          location={order.location}
          stages={recalculatedStage}
          designerMessagesCurrent={designerMessagesCurrent}
          total={total}
          stl={[...(order.stlFiles || treatmentReviewFiles || [])].filter(
            (item) =>
              item.ver === item.latestVer &&
              item.originalFileName.endsWith('.stl') &&
              item.redesignCount === redesignCountObj,
          )}
          caseId={order.hexOrderCode}
          patientName={order.name}
          dentalNotation={dentalNotation}
          treatmentReviewFiles={
            order.qualityControlImages || treatmentReviewFiles
          }
        />
      </div>
    </div>
  );
}

TeethChart.propTypes = {
  order: PropTypes.object.isRequired,
  dentalNotation: PropTypes.string,
  redesignCountObj: PropTypes.number,
  designerMessagesCurrent: PropTypes.array,
  treatmentReviewFiles: PropTypes.array,
};

export default TeethChart;
