import { NonFormDropdown } from '@fullcontour/common';
import AccordionCard from '@fullcontour/common/src/components/shared/Components/Accordion/AccordionCard';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArchSvg, SmileDesignSvg } from './assets';
import { designTypeInfo, designTypes, hideFCButtonInfo } from './helpers';

function OverpanelInformation({
  isVisible,
  drawer = false,
  designTypeName = null,
}) {
  const [selectedDesignType, setSelectedDesignType] = useState(null);
  const [designTypeDescription, setDesignTypeDescription] = useState(null);
  const { t } = useTranslation('designTypeDescription');
  const { content } = designTypeInfo(selectedDesignType, t);

  useEffect(() => {
    if (designTypeName) {
      setSelectedDesignType(
        designTypes.find((item) => item.name === designTypeName)?.value,
      );
    }
  }, [designTypeName]);

  const designTypeHandler = (e) => {
    setSelectedDesignType(e.target.value);
    setDesignTypeDescription(
      designTypes.find((designType) => designType.value === e.target.value)
        .description,
    );
  };

  const generateDesignTypeDescription = () => {
    if (selectedDesignType === 'clearAlignersAnterior') {
      return (
        <div className="is-flex">
          <ArchSvg />
          {t(`Clear Aligner treatment planning is an alternative to traditional
          braces and is designed to help guide the Anterior teeth only, into
          their proper position using aligner trays. This design indication
          provides customers with a series of models that can be printed and
          used to produce aligner trays.`)}
        </div>
      );
    }
    if (selectedDesignType === 'clearAlignersFullArch') {
      return (
        <div className="is-flex">
          <ArchSvg />
          {t(`Clear Aligner treatment planning is an alternative to traditional
          braces and is designed to help guide the Anterior AND Posterior teeth,
          into their proper position using aligner trays. This design indication
          provides customers with a series of models that can be printed and
          used to produce aligner trays.`)}
        </div>
      );
    }
    if (selectedDesignType === 'smileDesignVideo') {
      return (
        <div className="is-flex">
          <SmileDesignSvg />
          {t(
            'The Smile Design Video is a 2D video demonstrating before/after example of Smile Design.',
          )}
        </div>
      );
    }

    return t(designTypeDescription);
  };

  return isVisible ? (
    <div className={`column ${drawer ? '' : 'is-6'}`}>
      <div
        style={{
          width: drawer ? '100%' : '80%',
          margin: '0 auto',
          paddingTop: drawer ? 20 : '50px',
          overflowY: 'auto',
          maxHeight: drawer ? '90vh' : '75vh',
        }}
        className="px-3"
      >
        <div>
          <p className="mb-5">
            {t(
              'Click the dropdown to review the requirements for specific design types prior to sending your order.',
            )}
          </p>
        </div>
        <NonFormDropdown
          name="selectedDesignType"
          placeholder="Select the design type"
          options={designTypes}
          onChange={designTypeHandler}
          value={selectedDesignType}
          containerStyle={{ width: drawer ? '100%' : '50%' }}
        />
        <div style={{ width: '100%' }}>
          <p className="mt-5">{generateDesignTypeDescription()}</p>

          {selectedDesignType && !hideFCButtonInfo(selectedDesignType) && (
            <>
              <div className="column">
                <p className="mb-5">
                  {t(
                    'If the case has been setup in 3Shape Dental System, it can be sent via the 3Shape Design Service button.',
                  )}
                </p>
              </div>
              <div
                className="columns"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                }}
              >
                <div className="column is-one-fifth mt-0 pt-0">
                  <img
                    src="https://cdn.fullcontour.com/icons/Design_Service_icon_96x96px.png"
                    alt="3shape action icon"
                    className="mx-1"
                  />
                </div>
                <AccordionCard
                  card={{
                    cardBody: (
                      <ol className="is-size-7 instruction-list mb-2 ml-2">
                        <li>{t('Open Dental System.')}</li>
                        <div className="mb-3" />
                        <li>
                          {t(
                            'Select the case(s) you would like to send to 3Shape Design Service.',
                          )}
                        </li>
                        <div className="mb-3" />
                        <li>
                          {t('Click the 3Shape Design Service button.')}
                          <img
                            src="https://cdn.fullcontour.com/icons/Design_Service_icon_24x24px.png"
                            alt="3shape action icon"
                            className="mx-1"
                          />
                        </li>
                        <div className="mb-3" />
                        <li>
                          {t(`In the dialogue box that pops up, add your 3Shape Design Service
                      Username and Password. This is only required the first time you click the
                      3Shape 3Shape Design Service Button.`)}
                        </li>
                        <div className="mb-3" />
                        <li>
                          {t('Confirm that you want to send the case(s).')}
                          <br />
                          {t(`The 3Shape Design Service Platform will launch in the browser where
                      you can see your case(s) and modify as necessary
                      (Changing the turn around time, choosing Require
                      Design Approval, etc).`)}
                        </li>
                        <div className="mb-3" />
                        <li>
                          {t(`Click submit on the 3Shape Design Service Platform and you are
                      finished!`)}
                        </li>
                      </ol>
                    ),
                    cardHeader: (
                      <p>
                        {t(
                          'How to submit a case via Dental System with 3Shape Design Service button?',
                        )}
                      </p>
                    ),
                  }}
                />
              </div>
            </>
          )}
          <div className="mt-6">{content}</div>
        </div>
      </div>
    </div>
  ) : null;
}

OverpanelInformation.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  drawer: PropTypes.bool,
  designTypeName: PropTypes.string,
};

export default OverpanelInformation;
