import PropTypes from 'prop-types';

function ProgressInfo({
  submittingOrders,
  submittingTempOrders,
  progressClosePanelStylesBox,
  overpanelOpen = null,
}) {
  return !(
    submittingOrders.totalCases || submittingTempOrders.totalCases
  ) ? null : (
    <div
      style={{
        ...progressClosePanelStylesBox,
        top:
          submittingOrders.totalCases && submittingTempOrders.totalCases
            ? '8px'
            : '18px',
      }}
    >
      {submittingOrders.totalCases ? (
        <progress
          value={submittingOrders.casesSent}
          max={submittingOrders.totalCases}
          style={{ marginBottom: '7px' }}
          size={overpanelOpen ? 'large' : 'small'}
          color="blue"
          className={`progress is-primary ${!overpanelOpen && 'is-small'}`}
        >
          {overpanelOpen && 'Sending cases...'}
        </progress>
      ) : null}
      {submittingTempOrders.totalCases ? (
        <progress
          value={submittingTempOrders.casesSent}
          max={submittingTempOrders.totalCases}
          style={{ marginBottom: '5px' }}
          className={`progress is-primary ${!overpanelOpen && 'is-small'}`}
        >
          {overpanelOpen && 'Sending Pending cases...'}
        </progress>
      ) : null}
    </div>
  );
}

ProgressInfo.propTypes = {
  submittingOrders: PropTypes.object.isRequired,
  submittingTempOrders: PropTypes.object.isRequired,
  progressClosePanelStylesBox: PropTypes.object.isRequired,
  overpanelOpen: PropTypes.bool,
};

export default ProgressInfo;
