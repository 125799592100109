import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link } from 'react-router-dom';

function OrganizationLink(props) {
  const { type, location } = props;

  return type !== 'admin' ? (
    <Link to={`/organizations/${type}/${location.organization.slug}`}>
      {location.organization.name}
      {type === 'design' ? 'Center' : 'Organization'}
    </Link>
  ) : (
    `${location.organization.name} Organization`
  );
}

OrganizationLink.propTypes = {
  type: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default memo(OrganizationLink);
