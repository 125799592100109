import { useTranslation } from 'react-i18next';

function OverpanelCasesHeader() {
  const { t } = useTranslation('orders');

  return (
    <div
      className="columns has-background-dark has-text-white"
      style={{ paddingRight: 10 }}
    >
      <div className="column is-2">{t('Case Attributes')}</div>
      <div className="column">{t('Case Instructions')}</div>
      <div className="column" style={{ display: 'flex', flex: 'none' }}>
        {t('Actions')}
      </div>
    </div>
  );
}

export default OverpanelCasesHeader;
