import { useMutation, useQuery } from '@apollo/client';
import {
  BoxIcon,
  CurrentUserContext,
  DropdownSelect,
  handleTryCatch,
} from '@fullcontour/common';
import {
  CREATE_GUIDE_CODE_LAB_LOCATION,
  DESTROY_GUIDE_CODE_LAB_LOCATION,
  GET_CUSTOMER_LOC,
  GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS,
} from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { useGuideCodeCardContext } from '../context/GuideCodeCardContext';
import { actionButtonsPermissions, useCreateNewCode } from '../helpers';

function ButtonDefaults({ helpers = null }) {
  const { data } = useGuideCodeCardContext();

  const {
    id,
    default: isDefault,
    designType: { designTypeName },
    name,
  } = data;

  const { slug } = useParams();
  const { createNewCode } = useCreateNewCode(name);

  const variablesSlug = slug ? { slug } : {};
  const { data: dataC } = useQuery(GET_CUSTOMER_LOC, {
    variables: variablesSlug,
  });
  const { currentUser } = useContext(CurrentUserContext);

  const notificationResponse = useMemo(
    () => ({
      updateDefaults: !isDefault ? 'set as default' : 'removed from defaults',
    }),
    [isDefault],
  );

  const [configureDefault, { loading }] = useMutation(
    !isDefault
      ? CREATE_GUIDE_CODE_LAB_LOCATION
      : DESTROY_GUIDE_CODE_LAB_LOCATION,
    {
      fetchPolicy: 'network-only',
      refetchQueries: [{ query: GET_LAB_LOCATION_GUIDE_CATEGORY_GROUPS }],
    },
  );

  const addOrRemoveDefault = handleTryCatch(
    async () => {
      const variables = {
        input: {
          input: {
            guideCodeId: id,
            id: dataC?.customerLocation?.id,
          },
        },
      };

      if (id.startsWith('other-')) {
        const createdCodeId = await createNewCode();
        if (!createdCodeId) {
          throw { hide: true };
        }
        variables.input.input.guideCodeId = createdCodeId;

        const updatedGuideCodeData = {
          ...data,
          id: createdCodeId,
        };

        helpers.handleDropdown({
          data: { ...updatedGuideCodeData },
          value: createdCodeId,
        });
      }
      const gcs = [...helpers.guideCodes.filter(({ codeId }) => codeId !== id)];
      helpers.setFieldValue(
        `orderItems[${helpers.orderItemIndex}].guideCodes`,
        gcs,
      );
      await configureDefault({ variables });
    },
    {
      successText: `Guide Code was successfully ${notificationResponse.updateDefaults}`,
      errorText: `Guide code was not ${notificationResponse.updateDefaults}`,
    },
  );

  const defaultButtonProps = {
    className: 'button is-white p-2',
    disabled: isDefault || !id || !name,
    onClick: addOrRemoveDefault,
  };

  const setDefaultButton = (
    <button
      type="button"
      {...defaultButtonProps}
      data-cy="overpanel__set-as-default-code"
    >
      <BoxIcon name="bxs-check-circle" />
      <span>Set as default for {designTypeName}</span>
    </button>
  );

  const removeDefaultButton = (
    <button
      type="button"
      {...defaultButtonProps}
      data-cy="overpanel__remove-as-default-code"
      disabled={!isDefault}
    >
      <BoxIcon name="bxs-x-circle" />
      <span>Remove as default for {designTypeName}</span>
    </button>
  );

  return actionButtonsPermissions(currentUser.roleLevel, {
    admin_portal: '*',
    customer_portal: [10, 0, 40, 50],
  }) ? (
    <DropdownSelect
      item
      noPadding
      style={{ padding: 0 }}
      trigger={
        <button
          type="button"
          className={`button is-rounded has-background-white p-0 ${
            loading ? 'is-loading' : ''
          }`}
          style={{ height: 28, width: 28 }}
        >
          <BoxIcon
            name={!isDefault ? 'bx-plus' : 'bx-check has-text-primary'}
          />
        </button>
      }
    >
      {setDefaultButton}
      {removeDefaultButton}
    </DropdownSelect>
  ) : null;
}

ButtonDefaults.propTypes = {
  helpers: PropTypes.object,
};

export default ButtonDefaults;
