import ICON_ClearAligners from '@fullcontour/common/src/assets/icons/clearaligners.png';
import ICON_Dentures from '@fullcontour/common/src/assets/icons/dentures.png';
import ICON_SurgicalGuide from '@fullcontour/common/src/assets/icons/surgicalguide.png';
import ICON_Tooth from '@fullcontour/common/src/assets/icons/tooth.png';
import PropTypes from 'prop-types';

function BusinessUnitsField({
  value,
  form: { resetForm, setFieldValue, setCurrentStepIndex, canShowErrors },
  field: { name },
  error = null,
  data: { designTypes },
}) {
  const changeValueHandler = (newValue) => {
    if (value !== newValue) {
      resetForm();
      setFieldValue(name, newValue);
      setCurrentStepIndex({
        _sectionIndex: 0,
        _fieldIndex: 1,
      });
    }
  };

  const renderUnit = (unitType, icon, label) => (
    <button
      type="button"
      className={`box column is-3 is-flex is-flex-direction-column is-align-items-center m-2 p-4 ${
        value === unitType ? 'has-background-grey-lighter' : ''
      }`}
      style={{ border: '1px solid black' }}
      onClick={() => changeValueHandler(unitType)}
      aria-pressed={value === unitType}
    >
      <figure className="image is-96x96">
        <img src={icon} alt={label} />
      </figure>
      <p className="has-text-centered">{label}</p>
    </button>
  );

  return (
    <div
      className="is-justify-content-center columns p-5"
      style={
        canShowErrors && error
          ? { border: '1px solid #DE0019', backgroundColor: '#FFE3E6' }
          : null
      }
    >
      {designTypes['core-lab'] && designTypes['core-lab'].length > 0 &&
        renderUnit('core-lab', ICON_Tooth, 'Crown')}
      {designTypes['guided-surgery'] && designTypes['guided-surgery'].length > 0 &&
        renderUnit('guided-surgery', ICON_SurgicalGuide, 'Guided Surgery')}
      {designTypes.ortho && designTypes.ortho.length > 0 &&
        renderUnit('ortho', ICON_ClearAligners, 'Orthodontics')}
      {designTypes.removables && designTypes.removables.length > 0 &&
        renderUnit('removables', ICON_Dentures, 'Dentures')}
    </div>
  );
}

BusinessUnitsField.propTypes = {
  value: PropTypes.any.isRequired,
  form: PropTypes.shape({
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setCurrentStepIndex: PropTypes.func.isRequired,
    canShowErrors: PropTypes.bool.isRequired,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.string,
  data: PropTypes.shape({
    designTypes: PropTypes.objectOf(PropTypes.array),
  }).isRequired,
};

export default BusinessUnitsField;
