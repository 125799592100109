import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPortal from '../../../ReactPortal/ReactPortal';
import { DentalChart } from '../DentalChart';
import ChartSelectionPopupTrigger from './PopupTrigger/PopupTrigger';

function ChartSelectionPopup({
  toggleArch,
  toggleTooth,
  orderItem,
  numberingSystem,
  designType,
  nested = false,
  nestedTeeth = {},
  nestedArches = {},
  guideCodeId = null,
  applyNested = null,
  isDisabled = false,
  isEmpty = false,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation('designProfile');

  const renderTootlip = () => {
    if (isDisabled) {
      if (guideCodeId?.includes('other-')) {
        return 'Please provide Other value';
      }

      if (isEmpty) {
        return 'Please select teeth numbers or arches first';
      }
      return 'Please select an option in a dropdown first';
    }
    return null;
  };

  const applyHandler = () => {
    setModalOpen(false);

    if (nested && applyNested) {
      applyNested();
    }
  };

  const renderButton = (buttonProps) => (
    <button
      type="button"
      onClick={() => {
        if (!isDisabled) {
          setModalOpen(true);
        }
      }}
      {...buttonProps}
    >
      <ChartSelectionPopupTrigger
        toothChart={designType.toothChart}
        guideCodeId={guideCodeId}
        nestedData={designType.toothChart ? nestedTeeth : nestedArches}
        nested={nested}
      />
    </button>
  );

  const buttonProps = nested
    ? {
        className:
          'button is-rounded has-background-white p-0 mr-1 has-tooltip-bottom has-tooltip-narrow has-tooltip-multiline has-tooltip-arrow',
        style: { height: 28, width: 28 },
        ...(renderTootlip() ? { 'data-tooltip': t(renderTootlip()) } : {}),
      }
    : {
        className: 'button is-outlined is-primary mr-2 py-4',
        id: 'teetharch',
      };

  const modalCardTitle = nested
    ? 'Design Guide Codes for teeth/arches'
    : 'Teeth / Arch Selection';

  return orderItem ? (
    <>
      {renderButton(buttonProps)}

      <ReactPortal
        wrapperId={nested ? 'modal-nested-selection' : 'tooth-arch-modal'}
        show={modalOpen}
      >
        <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{t(modalCardTitle)}</p>
              <button
                type="button"
                className="button is-primary"
                onClick={applyHandler}
              >
                {t('Apply')}
              </button>
            </header>
            <div className="modal-card-body is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
              <DentalChart
                toggleArch={toggleArch}
                toggleTooth={toggleTooth}
                upperArch={orderItem.arches.some((arch) => arch === 'upper')}
                lowerArch={orderItem.arches.some((arch) => arch === 'lower')}
                teeth={orderItem.teeth}
                numberingSystem={numberingSystem}
                designType={designType}
                nestedTeeth={nestedTeeth}
                nestedArches={nestedArches}
                guideCodeId={guideCodeId}
                nested={nested}
              />
            </div>
          </div>
        </div>
      </ReactPortal>
    </>
  ) : null;
}

ChartSelectionPopup.propTypes = {
  numberingSystem: PropTypes.string.isRequired,
  guideCodeId: PropTypes.string,
  isDisabled: PropTypes.bool,
  toggleArch: PropTypes.func.isRequired,
  applyNested: PropTypes.func,
  toggleTooth: PropTypes.func.isRequired,
  orderItem: PropTypes.object.isRequired,
  designType: PropTypes.object.isRequired,
  nestedTeeth: PropTypes.object,
  nestedArches: PropTypes.object,
  nested: PropTypes.bool,
  isEmpty: PropTypes.bool,
};

export default ChartSelectionPopup;
