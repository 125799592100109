import { useQuery, useReactiveVar } from '@apollo/client';
import { OverpanelFilesContext, pendingCasesAmount } from '@fullcontour/common';
import { GET_TEMP_ORDERS_COUNT } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

function OverpanelHeaderUploadCount({ overpanelOpen }) {
  const { files } = useContext(OverpanelFilesContext);
  const { t } = useTranslation('orders');

  const { data } = useQuery(GET_TEMP_ORDERS_COUNT);
  const pedningCasesAmount =
    useReactiveVar(pendingCasesAmount) || data?.tempOrdersCount;

  return !overpanelOpen ? (
    <>
      <h6 className="title is-6 is-flex m-0 mr-3 ml-6">
        {files.length + pedningCasesAmount || 0}
        {t('Unsent Case', { count: files.length + pedningCasesAmount })}
        {/* {files.length + pedningCasesAmount > 1 && 's'} */}
      </h6>
      {files.length > 0 && (
        <div
          className="loader is-loading is-flex is-active"
          style={{
            border: '2px solid #1b77c8',
            borderRightColor: 'transparent',
            borderTopColor: 'transparent',
            marginRight: '1em',
          }}
        />
      )}
    </>
  ) : null;
}

OverpanelHeaderUploadCount.propTypes = {
  overpanelOpen: PropTypes.bool.isRequired,
};

export default OverpanelHeaderUploadCount;
