import Proptypes from 'prop-types';
import { memo, useEffect } from 'react';

function ManufacturerSelection(props) {
  const {
    index,
    field,
    values,
    value,
    form: { setFieldValue },
    dataSources: {
      customerLocation: {
        assignedManufacturers,
        priceList: { designTypes },
      },
    },
  } = props;
  const { orderItems } = values;
  const manufacturersFromDesignType = designTypes.find(
    ({ id }) => id === orderItems[index].designTypeId,
  ).manufacturers;
  const filteredManufs = [];
  assignedManufacturers.forEach(({ isDefault, manufacturerOrganization }) => {
    if (
      manufacturersFromDesignType.find(
        ({ id }) => id === manufacturerOrganization.id,
      )
    ) {
      filteredManufs.push({ manufacturerOrganization, isDefault });
    }
  });
  useEffect(() => {
    if (!value && filteredManufs.length === 1) {
      setTimeout(() => {
        setFieldValue(
          field.name,
          filteredManufs[0].manufacturerOrganization.id,
        );
      }, 100);
    }
    if (value === '' && filteredManufs.length > 1) {
      const hasDefault = filteredManufs.some(({ isDefault }) => isDefault);
      if (hasDefault) {
        setFieldValue(
          field.name,
          filteredManufs.find(({ isDefault }) => isDefault)
            .manufacturerOrganization.id,
        );
      } else {
        setFieldValue(field.name, '');
      }
    }
  }, [values]);

  return filteredManufs.length === 1 ? (
    <div>
      <p>
        There are no 3rd party manufacturers available for this design type on
        your account. So the manufactucturing destination will be:
      </p>
      <p style={{ fontWeight: 'bold', color: 'rgba(61, 172, 209, 0.9)' }}>
        {filteredManufs[0].manufacturerOrganization.name}
      </p>
    </div>
  ) : (
    <div className="select">
      <select {...field}>
        <option value="">Please Select</option>
        {filteredManufs.map(({ manufacturerOrganization: { name, id } }) => (
          <option key={name} value={id}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
}
ManufacturerSelection.propTypes = {
  index: Proptypes.number.isRequired,
  field: Proptypes.object.isRequired,
  values: Proptypes.object.isRequired,
  value: Proptypes.any.isRequired,
  form: Proptypes.object.isRequired,
  dataSources: Proptypes.object.isRequired,
};
export default memo(ManufacturerSelection);
