import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { timestampFormatter } from '../../../../../transforms';
import { urlify } from './helpers';

function OrderMessageInner(props) {
  const { message, imagesSection, style } = props;
  const { t } = useTranslation('caseActivity');

  const sharedProps = {
    style: {
      borderBottom: 'none',
      fontWeight: message.systemMessage || message.activity ? '700' : 'inherit',
      borderRadius:
        message.systemMessage || message.orderMessageableType !== 'User'
          ? '0 15px 15px 15px'
          : '15px 0 15px 15px',
    },
    className: `has-tooltip-arrow ${
      !(message.systemMessage || message.activity) ? 'messages_chat-bubble' : ''
    }`,
    'data-tooltip': timestampFormatter(message.createdAt),
  };

  return (
    <div className="mt-2" style={style}>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
        {imagesSection(message)}
      </div>

      {message.orderMessageableType !== 'User' ? (
        <div
          {...sharedProps}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t(message.message), {
              ADD_ATTR: ['target', 'href', 'style'],
              ADD_TAG: ['a'],
            }),
          }}
        />
      ) : (
        <span {...sharedProps}>{urlify(message.message)}</span>
      )}
    </div>
  );
}

OrderMessageInner.propTypes = {
  message: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  imagesSection: PropTypes.func.isRequired,
};

export default OrderMessageInner;
